/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Stack } from "@fluentui/react";

import {
  CommandBarItemProps,
  CommandBarItemType,
} from "../../common/CommandBar";
import { notification } from "../../common/Notification";
import Table, { Column, useTableFilters } from "../../common/Table";

import { CfgToMachinePinningItem } from "../models";
import { useEffect, useState } from "react";
import { CfgToMachinePinningApi } from "../api";
import { WithLoadingPanelHOC } from "../../Generic/HOCs";
import {
  CfgPinningAddDialog,
  CfgPinningEditDialog,
} from "./CfgPinningAddEditDialogs";
import { format } from "../../../schema/Utils";
import CfgPinningDeleteDialog from "./CfgPinningDeleteDialog";
import ImagesPivotContainer from "../Generic/ImagesPivotContainer";

import { useTranslation } from "react-i18next";

enum CrudOperationType {
  None,
  Add,
  Edit,
  Delete,
}

type SelectedItem = {
  item: CfgToMachinePinningItem | undefined;
  action: CrudOperationType;
};

type ActionDialogProps = {
  confignames: Set<string>;
  selectedItem: SelectedItem;
  onClose: (listChanged: boolean) => void;
};

type CfgPinningListProps = {
  hasWritePermissions: boolean;
};

/**
 * Gets the table columns
 * @param hasWritePermissions Value determining whether the user has permissions to write data.
 * @param onEdit Method called when the Edit icon button is clicked
 * @param onDelete Method called when the Delete icon button is clicked.
 * @returns The column array.
 */
const getTableColumns = (
  t,
  hasWritePermissions: boolean,
  onEdit: (tableItem: CfgToMachinePinningItem) => void,
  onDelete: (tableItem: CfgToMachinePinningItem) => void
): Column[] => {
  let result: Column[] = [
    {
      key: "corporationName",
      name: t("Corporation"),
      fieldName: "corporationName",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "companyName",
      name: t("Company"),
      fieldName: "companyName",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "projectName",
      name: t("Project"),
      fieldName: "projectName",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "machineName",
      name: t("Machine"),
      fieldName: "machineName",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "cfgName",
      name: t("Config Name"),
      fieldName: "cfgName",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "createdByDisplayName",
      name: t("Created By"),
      fieldName: "createdByDisplayName",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "createdAt",
      name: t("Created At"),
      fieldName: "createdAt",
      minWidth: 100,
      isSortable: true,
      onRender: (item: CfgToMachinePinningItem) =>
        format(new Date(item.createdAt)),
    },
    {
      key: "updatedByDisplayName",
      name: t("Updated By"),
      fieldName: "updatedByDisplayName",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "updatedAt",
      name: t("Updated At"),
      fieldName: "updatedAt",
      minWidth: 100,
      isSortable: true,
      onRender: (item: CfgToMachinePinningItem) =>
        item.updatedAt && format(new Date(item.updatedAt)),
    },
  ];

  let actionsColumn: Column = {
    key: "actions",
    name: t("Actions"),
    fieldName: "actions",
    minWidth: 150,
    onRender: (item: CfgToMachinePinningItem) => (
      <Stack horizontal>
        <IconButton
          key={item.id + "edit"}
          className="table-icon-button"
          title={t("Edit CFG")}
          ariaLabel={t("Edit CFG")}
          iconProps={{
            iconName: "Edit",
          }}
          onClick={() => onEdit(item)}
        />
        <IconButton
          key={item.id + "delete"}
          className="table-icon-button"
          title={t("Delete CFG")}
          ariaLabel={t("Delete VPN connection")}
          iconProps={{
            iconName: "Delete",
          }}
          onClick={() => onDelete(item)}
        />
      </Stack>
    ),
  };

  if (hasWritePermissions) {
    result.push(actionsColumn);
  }

  return result;
};

/**
 * Gets the command bar items props.
 * @param hasAdminPermissions value determining whether there is administrator permissions.
 * @param onVpnPcDetails Method called when the VPN PCs Details button is clicked.
 * @returns The Command bar item props list.
 */
const getBarItems = (t, onAdd: () => void): CommandBarItemProps[] => {
  let actionProps: CommandBarItemProps[] = [
    {
      key: "addVpnConnection",
      text: t("Add"),
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Add" },
      onClick: onAdd,
    },
  ];

  return actionProps;
};

/**
 * Gets the action dialog component.
 * @param selectedItem The selected item object.
 * @param confignames The confignames reference list used for validation
 * @param onClose Method called to close the selected action dialog
 * @returns The action dialog component.
 */
const ActionDialog = ({
  selectedItem,
  confignames,
  onClose,
}: ActionDialogProps) => {
  let result: JSX.Element = null;

  switch (selectedItem.action) {
    case CrudOperationType.Add:
      result = (
        <CfgPinningAddDialog confignames={confignames} onClose={onClose} />
      );
      break;
    case CrudOperationType.Edit:
      result = (
        <CfgPinningEditDialog
          item={selectedItem.item}
          confignames={confignames}
          onClose={onClose}
        />
      );
      break;
    case CrudOperationType.Delete:
      result = (
        <CfgPinningDeleteDialog item={selectedItem.item} onClose={onClose} />
      );
      break;
  }

  return result;
};

/**
 * Gets the CFG to Machine pinning pivot section component.
 * @param hasWritePermissions Value determining whether the user has permissions to write this data
 * @returns The CFG to Machine pinning pivot section component.
 */
const CfgPinningPivotSection = ({
  hasWritePermissions,
}: CfgPinningListProps) => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<SelectedItem>({
    item: undefined,
    action: CrudOperationType.None,
  });
  const [getItemsList, setGetItemsList] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableItems, setTableItems] = useState<CfgToMachinePinningItem[]>([]);
  const { filters, handleSearch } = useTableFilters<CfgToMachinePinningItem>({
    keys: [
      "corporationName",
      "companyName",
      "projectName",
      "machineName",
      "cfgName",
    ],
  });

  // Gets the list items.
  useEffect(() => {
    if (!getItemsList) {
      return;
    }

    setIsLoading(true);
    CfgToMachinePinningApi.list().then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          t(
            `Failure getting the CFG to Machine pinning list: {{statusText}}.`,
            { statusText: response.statusText }
          )
        );
        return;
      }

      setTableItems(response.data);
    });

    setGetItemsList(false);
  }, [getItemsList]);

  // Handlers
  const onAdd = () => {
    setSelectedItem({ item: undefined, action: CrudOperationType.Add });
  };

  const onEdit = (tableItem: CfgToMachinePinningItem) => {
    setSelectedItem({ item: tableItem, action: CrudOperationType.Edit });
  };

  const onDelete = (tableItem: CfgToMachinePinningItem) => {
    setSelectedItem({ item: tableItem, action: CrudOperationType.Delete });
  };

  const onClose = (listChanged: boolean) => {
    setSelectedItem({ item: undefined, action: CrudOperationType.None });
    listChanged && setGetItemsList(true);
  };

  return (
    <ImagesPivotContainer
      title={t("CFG to Machine Pinning")}
      hasPermissions={hasWritePermissions}
      commandBarItemProps={getBarItems(t, onAdd)}
      handleSearch={handleSearch}
    >
      {WithLoadingPanelHOC(
        !isLoading,
        <Table
          items={tableItems}
          columns={getTableColumns(t, hasWritePermissions, onEdit, onDelete)}
          header={{ title: "CFGs" }}
          hasSelection={false}
          persistOpts={{
            key: "config-to-machine-pinning-table",
            version: 2,
          }}
          filters={filters}
        />
      )}
      <ActionDialog
        selectedItem={selectedItem}
        confignames={
          new Set(tableItems.map((i) => i.cfgName?.toLocaleLowerCase()))
        }
        onClose={onClose}
      />
    </ImagesPivotContainer>
  );
};

export default CfgPinningPivotSection;
