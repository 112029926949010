import { Dropdown, PrimaryButton } from "@fluentui/react";
import { useState } from "react";

import { useSensorManualTriggering } from "../../../hooks/useSensorTriggering";
import { notification } from "../../../../common/Notification";
import { useTranslation } from "react-i18next";

export const measurementSettingOptions: any = [
  {
    key: "MeasSet0",
    text: "MeasSet0",
  },
  {
    key: "MeasSet1",
    text: "MeasSet1",
  },
];

const ManualTriggerSettings = ({ sensor }: any) => {
  const { t } = useTranslation();
  const { updateSensorManualTriggering } = useSensorManualTriggering({
    sensorId: sensor.id,
  });
  const [selectedKeys, setSelectedKeys]: any = useState([]);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    updateSensorManualTriggering(selectedKeys || []).then(
      () => {
        notification.success(t("Sensor has passed without any events"));
        setLoading(false);
      },
      () => {
        notification.error(t("Manual trigger error"));
        setLoading(false);
      }
    );
  };

  const onDropdownChange = (e: any, item: any) => {
    const { key, selected } = item || {};
    setSelectedKeys(
      selected
        ? [...selectedKeys, key]
        : selectedKeys.filter((keyName: any) => keyName !== key)
    );
  };

  return (
    <>
      <div className="tab-subtitle">{t("On-demand Trigger")}</div>
      <div className="manual-dropdown">
        <Dropdown
          multiSelect
          disabled={loading}
          selectedKeys={selectedKeys}
          options={measurementSettingOptions}
          style={{ width: 247 }}
          onChange={onDropdownChange}
        />
        <PrimaryButton
          disabled={loading}
          styles={{ root: { marginLeft: 15 } }}
          text={t("Trigger sensor")}
          onClick={onSubmit}
        />
      </div>
    </>
  );
};

export default ManualTriggerSettings;
