import {
  Text,
  ScrollablePane,
  ScrollbarVisibility,
  Pivot,
  PivotItem,
  Stack,
  Spinner,
  SpinnerSize,
  IStyle,
} from "@fluentui/react";

import BaseCommandBar, {
  computeCommandBarItems,
  CommandBarItemType,
  CommandBarItemProps,
} from "../common/CommandBar";
import Table, { Column, useTableFilters } from "../common/Table";
import { notification } from "../common/Notification";

import { CSSProperties, useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { Status } from "../../schema/status";
import {
  UserDetails,
  UserSimple,
  UserSimpleWithIssuer,
  WithOutPermissionsUserExtendedProperties,
} from "./models";
import { detailsUsersRBAC } from "./api";
import {
  listAsyncUsersRBAC,
  selectUsersRBACError,
  selectUsersRBACPlain,
  selectUsersRBACStatus,
  selectUsersRBACStringFilter,
  setStringFilter,
} from "./reducer";
import {
  listAsyncUsersPendingRBAC,
  selectUsersPendingRBAC,
  selectUsersPendingRBACError,
  selectUsersPendingRBACStatus,
} from "../UsersPendingRBAC/reducer";
import { linkStyle, titleStyle } from "../../schema/Constants";
import { DetailsDialog } from "./DetailsDialog";
import { DetailsDialogPending } from "./DetailsDialogPending";
import { formatWitHrs } from "../../schema/Utils";
import { Filter } from "../common/Table/Table";
import { WithLoadingPanelHOC } from "../Generic/HOCs";

import { useTranslation } from "react-i18next";

type ToLoadItem = {
  load: boolean;
  memberId: string;
};

type GetColumnsOpts = {
  t: any;
  isLoading: ToLoadItem;
  setIsLoading: React.Dispatch<React.SetStateAction<ToLoadItem>>;
  onSuccess: (hasError: boolean, displayName: string) => void;
  hasActions: boolean;
  onDetails?: (userDet: UserDetails, usrSim: UserSimple) => void;
  onEdit?: (userDet: UserDetails, usrSim: UserSimple) => void;
  onDetailsPending?: (user: WithOutPermissionsUserExtendedProperties) => void;
};

type UserTabsProps = React.HTMLAttributes<HTMLElement> & {
  issuers: UserSimpleWithIssuer[];
  tableColumns: Column[];
  tableFilters: Filter[];
  isLoading: boolean;
  isError: boolean;
};

// --- Styles ---
const pivotStyle: IStyle = {
  display: "flex",
  justifyContent: "center",
  columnGap: "2em",
};

const pivotItemStyle: IStyle = {
  fontSize: 18,
  fontWeight: 400,
};

const pivotItemSelectedStyle: IStyle = {
  ...pivotItemStyle,
  fontWeight: 600,
  borderBottom: "3px solid var(--dalog-blue)",
};

// const copyIcon: IIconProps = { iconName: "Copy" };

const pageStyle: CSSProperties = {
  padding: 8,
  marginBottom: 50,
};

const getIssuerName = (name: string) => {
  if (name.includes("ddp-dev")) return "Email";
  if (name.includes("ddp-uat")) return "Email";
  if (name.includes("ddp-prd")) return "Email";

  if (name.includes("Username-Password-Authentication")) return "{Email}";

  if (name.includes("dalog-dev")) return "OAuth2 DALOG";
  if (name.includes("dalog-uat")) return "OAuth2 DALOG";
  if (name.includes("dalog-prd")) return "OAuth2 DALOG";

  if (name.includes("google-oauth2")) return "OAuth2 Google";

  if (name.includes("ExternalAzureAD")) return "{ExternalAzureAD}";

  if (name.includes("DalogAzureAd")) return "{DalogAzureAd}";

  if (
    name.includes(
      "https://login.microsoftonline.com/feb1e02d-7cfb-42ff-9911-58dc6756c00e/v2.0"
    )
  )
    return "{DALOG}";

  if (
    name.includes(
      "https://login.microsoftonline.com/6037dfc3-a75b-49de-b32c-620396c0239e/v2.0"
    )
  )
    return "{DALOG}";

  if (name.includes("google.com")) return "{Google}";

  if (name.includes("kingsblue")) return "{Kingsblue}";

  if (name.includes("auth.dev-dexp.net/auth/realms/tk-mincloud"))
    return "{TK V2}";

  if (name.includes("https://auth.dev-dexp.net/auth/realms/tk-mincloud"))
    return "{TK V2}";

  if (
    name.includes("auth.digitalizedexpertise.network/auth/realms/tk-mincloud")
  )
    return "{TK V1}";

  return name;
};

const sortByName = (a: UserSimpleWithIssuer, b: UserSimpleWithIssuer) => {
  const aName = getIssuerName(a.name);
  const bName = getIssuerName(b.name);
  if (aName > bName) return 1;
  if (aName < bName) return -1;

  return 0;
};

const getColumns = ({
  t,
  isLoading,
  setIsLoading,
  hasActions,
  onDetails,
}: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      name: t("Display Name"),
      fieldName: "displayName",
      minWidth: 200,
      isSortable: true,
      onRender: (data: UserSimple) => {
        return (
          <Stack horizontal>
            {isLoading.load && isLoading.memberId === data.memberId ? (
              <Spinner size={SpinnerSize.xSmall} />
            ) : null}
            <Link
              to={"./"}
              style={linkStyle}
              onClick={async () => {
                setIsLoading({ load: true, memberId: data.memberId });
                await detailsUsersRBAC(
                  data.memberId ? data.memberId : data.id
                ).then((resp: UserDetails) => {
                  onDetails(
                    resp.properties
                      ? resp
                      : {
                          ...resp,
                          properties: { ...resp.properties, memberId: data.id },
                        },
                    data
                  );
                });
                setIsLoading({ load: false, memberId: data.memberId });
              }}
            >
              {data.name}
            </Link>
          </Stack>
        );
      },
    },
    {
      key: "lastLoginDate",
      name: t("Last login date"),
      fieldName: "lastLoginDate",
      minWidth: 200,
      isSortable: true,
      onRender: ({ lastLoginDate }) =>
        lastLoginDate && formatWitHrs(new Date(lastLoginDate)),
    },
    {
      key: "email",
      name: t("E-mail"),
      fieldName: "email",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "firstName",
      name: t("First Name"),
      fieldName: "firstName",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "lastName",
      name: t("Last Name"),
      fieldName: "lastName",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "jobTitle",
      name: t("Job Title"),
      fieldName: "jobTitle",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "companyName",
      name: t("Company"),
      fieldName: "companyName",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "department",
      name: t("Department"),
      fieldName: "department",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "city",
      name: t("City"),
      fieldName: "city",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "countryOrRegion",
      name: t("Country Or Region"),
      fieldName: "countryOrRegion",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "mobilePhone",
      name: t("Mobile Phone"),
      fieldName: "mobilePhone",
      minWidth: 100,
      isSortable: true,
    },
  ];

  return columns;
};

const getColumnsPending = ({
  t,
  onDetailsPending,
}: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "memberId",
      name: t("Member Id"),
      fieldName: "memberId",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "displayName",
      name: t("Display Name"),
      fieldName: "displayName",
      minWidth: 200,
      isSortable: true,
      onRender: (data: WithOutPermissionsUserExtendedProperties) => (
        <Link
          to={"./"}
          style={linkStyle}
          onClick={() => {
            onDetailsPending(data);
          }}
        >
          {data.displayName}
        </Link>
      ),
    },
    {
      key: "email",
      name: t("Email"),
      fieldName: "email",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "lastName",
      name: t("Last Name"),
      fieldName: "lastName",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "firstName",
      name: t("First Name"),
      fieldName: "firstName",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "jobTitle",
      name: t("Job Title"),
      fieldName: "jobTitle",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "companyName",
      name: t("Company Name"),
      fieldName: "companyName",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "department",
      name: t("Department"),
      fieldName: "department",
      minWidth: 200,
      isSortable: true,
    },
  ];

  return columns;
};

// const onCopy = (
//   item: WithPutPermissionsUserExtendedProperties,
//   index: number,
//   column: IColumn
// ) => {
//   const pass = item[column.key];
//   return (
//     <>
//       <Stack horizontal key={item.id + column.key}>
//         <TextField defaultValue={pass ? pass : ""} disabled={true} />
//         <CopyToClipboard
//           text={pass}
//           onCopy={(text) =>
// /            !text &&
//             notification.warning("You are trying to copy an empty string.")
//           }
//         >
//           <IconButton iconProps={copyIcon} ariaLabel={"icon"}></IconButton>
//         </CopyToClipboard>
//       </Stack>
//     </>
//   );
// };

const UsersPivot = ({
  issuers,
  tableColumns,
  tableFilters,
  isLoading,
  isError,
  ...rest
}: UserTabsProps) => {
  const { t } = useTranslation();
  if (issuers.length === 0) {
    return null;
  }

  return (
    <Pivot
      {...rest}
      aria-label={t("Pivots of user issuers (Google, DALOG, TK, etc)")}
      styles={{
        root: {
          display: "flex",
          justifyContent: "center",
        },
      }}
    >
      {[...issuers].sort(sortByName).map((issuer) => (
        <PivotItem
          key={`tab-issuer-${issuer.name}`}
          itemKey={`issuer-${issuer.name}`}
          headerText={getIssuerName(issuer.name)}
        >
          <Table
            key={`table-issuer-${issuer.name}`}
            persistOpts={{
              key: "table-usersRBCA",
              version: 2,
            }}
            header={{
              title: `${getIssuerName(issuer.name)} ${t("Users")}`,
            }}
            items={issuer.users}
            columns={tableColumns}
            filters={tableFilters}
            hasSelection={false}
            isLoading={isLoading}
            isError={isError}
          />
        </PivotItem>
      ))}
    </Pivot>
  );
};

export const UsersRBCA = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const itemsPending = useAppSelector(selectUsersPendingRBAC);
  const statusPending = useAppSelector(selectUsersPendingRBACStatus);
  const errorPending = useAppSelector(selectUsersPendingRBACError);
  const items = useAppSelector(selectUsersRBACPlain);
  const status = useAppSelector(selectUsersRBACStatus);
  const error = useAppSelector(selectUsersRBACError);
  const hasWritePermission = true;
  const stringFilter = useAppSelector(selectUsersRBACStringFilter);
  const [isLoading, setIsLoading] = useState<ToLoadItem>({
    load: false,
    memberId: "",
  });
  const [selected, setSelected] = useState<{
    data?: UserSimple;
    dataDet?: UserDetails;
    dataPending?: WithOutPermissionsUserExtendedProperties;
    context: "details" | "detailsPending";
  } | null>(null);
  const { filters, handleSearch, search } = useTableFilters<UserSimple>({
    keys: [
      "name",
      "memberId",
      "lastName",
      "firstName",
      "email",
      "companyName",
      "city",
      "mobilePhone",
      "lastLoginDate",
    ],
  });

  const selectedTab = searchParams.get("tab") || "users";

  const handlePivotChange = (item: PivotItem | undefined) => {
    if (item) {
      navigate(`/users?tab=${item.props.itemKey}`);
    }
  };
  useEffect(() => {
    if (id && items.length > 0) {
      //const sel = items.find((ele) => ele?.ddpProperties?.id === id);
      setIsLoading({ load: true, memberId: id });
      detailsUsersRBAC(id).then((resp) => {
        setIsLoading({ load: false, memberId: id });
        resp.status === 404 ? onNotFound() : onDetails(resp);
      });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, items]);

  useEffect(() => {
    if (status === Status.error) notification.error(error);
    return () => {};
  }, [error, status]);

  useEffect(() => {
    if (statusPending === Status.error) notification.error(errorPending);
    return () => {};
  }, [errorPending, statusPending]);

  useEffect(() => {
    if (search !== "") dispatch(setStringFilter(search));
  }, [dispatch, search]);

  useEffect(() => {
    handleSearch?.(stringFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringFilter]);

  useEffect(() => {
    dispatch(listAsyncUsersRBAC());
    dispatch(listAsyncUsersPendingRBAC());
  }, [dispatch]);

  // Handlers
  const onNotFound = () => {
    navigate("../users/");
    notification.warning(t("The item does not exist."));
  };

  const onDetails = (userDet: UserDetails) =>
    setSelected({
      data: undefined,
      dataDet: userDet,
      context: "details",
    });

  const onDetailsPending = (user: WithOutPermissionsUserExtendedProperties) =>
    setSelected({
      dataPending: user,
      context: "detailsPending",
    });

  const onSuccess = (hasError: boolean, displayName: string): void => {
    if (hasError) {
      const message = t(`Failed updating {{displayName}}`, { displayName });
      notification.error(message);
    } else {
      dispatch(listAsyncUsersRBAC());
      const message = t(`{{displayName}} updated successfully`, {
        displayName,
      });
      notification.success(message);
    }
  };

  // Table components
  const commandBarItems: CommandBarItemProps[] = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{t("Users")}</Text>,
    },
    ...(hasWritePermission
      ? [
          {
            key: "refresh",
            text: t("Refresh"),
            type: CommandBarItemType.Button,
            iconProps: { iconName: "Refresh" },
            onClick: () => {
              dispatch(listAsyncUsersRBAC());
              dispatch(listAsyncUsersPendingRBAC());
            },
          },
        ]
      : [
          {
            key: "refresh",
            text: t("Refresh"),
            type: CommandBarItemType.Button,
            iconProps: { iconName: "Refresh" },
            onClick: () => {
              dispatch(listAsyncUsersRBAC());
              dispatch(listAsyncUsersPendingRBAC());
            },
          },
        ]),
  ];

  return (
    <Stack style={pageStyle}>
      <BaseCommandBar
        items={computeCommandBarItems(commandBarItems)}
        styles={{
          root: {
            padding: 0,
            background: "transparent",
          },
        }}
        onSearch={handleSearch}
      />
      <ScrollablePane
        scrollContainerFocus={true}
        scrollbarVisibility={ScrollbarVisibility.auto}
        scrollContainerAriaLabel={"scroll-projects"}
        style={{
          height: "90vh",
          position: "relative",
        }}
      >
        {WithLoadingPanelHOC(
          status === Status.idle,
          <Pivot
            selectedKey={selectedTab}
            onLinkClick={handlePivotChange}
            aria-label={t("Pivots to edit service account")}
            styles={{
              root: pivotStyle,
              link: pivotItemStyle,
              linkIsSelected: pivotItemSelectedStyle,
            }}
          >
            <PivotItem headerText={t("Users")} itemKey={"users"}>
              <UsersPivot
                issuers={items}
                tableColumns={getColumns({
                  t,
                  onSuccess: onSuccess,
                  hasActions: hasWritePermission,
                  onDetails: onDetails,
                  isLoading: isLoading,
                  setIsLoading: setIsLoading,
                })}
                tableFilters={filters}
                isLoading={status === Status.loading || !items}
                isError={status === Status.error}
                style={{ paddingTop: "1em" }}
              />
            </PivotItem>
            <PivotItem
              headerText={t("Without Permissions")}
              itemKey={"pending"}
            >
              <Table
                key={"usersPendingRBCA"}
                persistOpts={{
                  key: "table-usersPendingRBCA",
                  version: 2,
                }}
                header={{
                  title: t("Users without Permissions"),
                }}
                items={itemsPending}
                columns={getColumnsPending({
                  t,
                  onSuccess: onSuccess,
                  hasActions: false,
                  onDetailsPending: onDetailsPending,
                  isLoading: isLoading,
                  setIsLoading: setIsLoading,
                })}
                filters={filters}
                hasSelection={false}
                isLoading={itemsPending === undefined}
                isError={statusPending === Status.error}
              />
            </PivotItem>
          </Pivot>
        )}
        {selected?.context === "details" && (
          <DetailsDialog
            data={selected?.dataDet}
            show={selected?.context === "details"}
            onSuccess={onSuccess}
            onClose={() => {
              setSelected(null);
            }}
          />
        )}
        {selected?.context === "detailsPending" && (
          <DetailsDialogPending
            data={selected?.dataPending}
            show={selected?.context === "detailsPending"}
            onSuccess={onSuccess}
            onClose={() => {
              setSelected(null);
            }}
          />
        )}
      </ScrollablePane>
    </Stack>
  );
};
