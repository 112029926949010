import type {
  ICommandBarItemProps,
  ICommandBarStyles,
  IStackItemStyles,
} from "@fluentui/react";
import { Stack, Text, TooltipHost } from "@fluentui/react";
import { sortBy } from "lodash-es";
import * as ReactRouterDOM from "react-router-dom";
import { useNavigate } from "react-router-dom";

import FluentClock from "../../../../../assets/svg/FluentClock";
import FluentSchedule from "../../../../../assets/svg/FluentSchedule";
import MachineCvSensors from "../../../../../assets/svg/MachineCvSensors";
import MachineCVSummary from "../../../../../assets/svg/MachineCVSummary";
import MultipleComments from "../../../../../assets/svg/MultipleComments";
import RawDataIcon from "../../../../../assets/svg/RawDataIcon";
import TrendsIcon from "../../../../../assets/svg/TrendsIcon";
import { format } from "../../../../../utils";
import {
  conditionColor,
  getConditionFormat,
} from "../../../../../modules/machine-cv/constants";
import type { MachineCvOverviewMachine } from "../../../../../types";
import Accordion from "../../../../common/Accordion";
import type { AccordionItem } from "../../../../common/Accordion/types";
import BaseCommandBar from "../../../../common/CommandBar";
import {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../../common/CommandBar/methods";
import Image from "../../../../common/Image";
import { MarkdownText } from "../../../../common/MarkdownEditor";

import Condition from "./Condition";

import { useTranslation } from "react-i18next";

type MachineCardProps = {
  machine: MachineCvOverviewMachine;
};

type MachineCardCommandBarItem = {
  name: string;
  link: string;
  icon?: JSX.Element;
  disabled?: boolean;
};

const commandBarStyles: ICommandBarStyles = {
  root: {
    padding: 0,
    alignItems: "center",
    height: 30,
    margin: "24px 8px 0px 8px",
  },
  primarySet: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
};

const notationStack: IStackItemStyles = {
  root: {
    display: "block",
    fontSize: "12px",
    lineHeight: "16px",
  },
};

const getCommandBarItems: (t: any) => MachineCardCommandBarItem[] = (t) => [
  {
    name: t("Summary"),
    link: "/machine-cv/summary",
    icon: <MachineCVSummary />,
  },
  {
    name: t("Sensors"),
    link: "/machine-cv/sensors",
    icon: <MachineCvSensors />,
  },
  {
    name: t("Tasks"),
    link: "/machine-cv/tasks",
    icon: <FluentClock />,
  },
  {
    name: t("Comments"),
    link: "/machine-cv/comments",
    icon: <MultipleComments />,
  },
  {
    name: t("Reports"),
    link: "/machine-cv/reports",
    icon: <FluentSchedule />,
  },
  {
    name: t("Trend"),
    link: "/trend-view",
    icon: <TrendsIcon fill="#2c529f" />,
  },
  {
    name: t("Raw Data"),
    link: "/raw-data",
    icon: <RawDataIcon />,
  },
];

const linkStyle = ({ isActive }: { isActive: boolean }) => {
  return {
    pointerEvents: !isActive ? "none" : "auto",
    color: !isActive ? "#a19f9d" : "#2c529f",
  } as React.CSSProperties;
};

const getAccordionItems = (
  machine: MachineCvOverviewMachine,
  t
): AccordionItem[] => {
  return [
    {
      key: `machine-condition-${machine.metaData.id}`,
      title: t("Condition"),
      disabled: machine.conditions.length === 0,
      onRenderContent: () => (
        <Condition conditions={sortBy(machine.conditions, "name")} />
      ),
      onRenderSubtitle: () => (
        <Stack
          className="machine-cv-card-condition-span"
          style={{ backgroundColor: conditionColor[machine.condition] }}
        >
          <Text
            as="span"
            variant="medium"
            style={{ fontWeight: 600, color: "white", textAlign: "center" }}
          >
            {getConditionFormat(t)[machine.condition]}
          </Text>
        </Stack>
      ),
    },
    {
      key: `machine-condition-description-${machine.metaData.id}`,
      title: t("Condition Description"),
      disabled: !machine.metaData?.comment,
      content: machine.metaData?.comment,
    },
    {
      key: `machine-tasks-${machine.metaData.id}`,
      title: t("Tasks"),
      overview: `${machine.tasks.activeTasksCount}`,
      disabled: !machine.tasks.nextDueDescription,
      content: machine.tasks.nextDueDescription,
    },
    {
      key: `machine-last-comment-${machine.metaData.id}`,
      title: t("Last Comment"),
      overview: machine.comments?.dateTime
        ? format(new Date(machine.comments?.dateTime))
        : "",
      disabled: !machine.tasks.nextDueDescription,
      content: machine.tasks.nextDueDescription,
    },
    {
      key: `machine-last-report-${machine.metaData.id}`,
      title: t("Last Report"),
      overview: machine.logEntries?.dateTime
        ? format(new Date(machine.logEntries?.dateTime))
        : "",
      disabled: !machine.logEntries?.dateTime,
      onRenderContent: () => (
        <Stack
          style={{
            background: "#e1dfdd",
            fontSize: "12px",
            padding: "1em 1em 1em 0.5em",
          }}
        >
          <MarkdownText text={machine.logEntries?.text} />
        </Stack>
      ),
    },
  ];
};

const getFooterItems = (
  machine: MachineCvOverviewMachine,
  t
): ICommandBarItemProps[] => {
  const result = getCommandBarItems(t).map(
    ({ name, link, icon, disabled }) => ({
      key: `footer-item-button-${name}`,
      type: CommandBarItemType.Custom,
      renderedInOverflow: true,
      onRender: () => (
        <TooltipHost
          key={`footer-item-tooltip-${name}`}
          content={name}
          styles={{ root: { display: "flex", cursor: "pointer" } }}
        >
          <ReactRouterDOM.NavLink
            to={`${link}?id=${machine.metaData.id}`}
            style={linkStyle({ isActive: !disabled })}
          >
            {icon}
          </ReactRouterDOM.NavLink>
        </TooltipHost>
      ),
    })
  );

  return computeCommandBarItems(result);
};

const MachineCardHeader = ({ machine }: MachineCardProps) => {
  return (
    <Stack horizontalAlign="start" verticalAlign="center">
      <Text as="h3" style={{ fontWeight: 600, lineHeight: "20px" }}>
        {machine.metaData.name}
      </Text>
      <Text as="h4" styles={notationStack}>
        {machine.metaData.customerCode}
      </Text>
    </Stack>
  );
};

const MachineCard = ({ machine }: MachineCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Handlers
  const onMouseEnter = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.currentTarget.parentElement?.parentElement?.classList.add(
      "machine-cv-card-selected"
    );
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.currentTarget.parentElement?.parentElement?.classList.remove(
      "machine-cv-card-selected"
    );
  };

  return (
    <Stack className="machine-cv-card box-shadow-panel">
      <Stack
        horizontal
        grow
        verticalFill
        verticalAlign="center"
        horizontalAlign="space-between"
      >
        <Stack
          verticalFill
          className="machine-cv-card-panel-left"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <MachineCardHeader machine={machine} />
          <Stack
            grow
            className="machine-cv-card-image-container"
            verticalAlign="center"
            onClick={() =>
              navigate(`/machine-cv/summary?id=${machine.metaData.id}`)
            }
          >
            <Image
              id={machine?.metaData?.iconId}
              alt={machine.metaData.name}
              className="machine-cv-card-image"
            />
          </Stack>
        </Stack>
        <Stack
          className="machine-cv-card-panel-right"
          verticalAlign="start"
          horizontalAlign="stretch"
        >
          <Accordion items={getAccordionItems(machine, t)} />
        </Stack>
      </Stack>
      <Stack>
        <BaseCommandBar
          items={getFooterItems(machine, t)}
          styles={commandBarStyles}
        />
      </Stack>
    </Stack>
  );
};

export default MachineCard;
