import { IconButton, Spinner, SpinnerSize } from "@fluentui/react";

import { useWebAnalysisPermissions } from "../../../../Hooks";
import useSensorStore from "./hooks/useSensorStore";
import { TRIGGERS_TAB } from "./SensorTabs";
import { useClearSensorState } from "../../hooks/useSensorRequests";
import { useEffect } from "react";
import { notification } from "../../../common/Notification";
import { useTranslation } from "react-i18next";

const SensorHeader = ({ sensor, activeTab }: any) => {
  const { t } = useTranslation();
  const sensorNodeId = sensor ? sensor.sensorNodeId : null;
  const { triggers, settings } = useSensorStore((store: any) => ({
    triggers: store.triggers,
    settings: store.settings,
  }));

  const {
    clearState,
    isLoading: isLoadingClearSensorState,
    isError,
  } = useClearSensorState({ sensorNodeId });

  const { useReadyPermissionGeneral } = useWebAnalysisPermissions();
  const hasWirelessSensorReaderPermissions = useReadyPermissionGeneral({
    permissionRoles: ["WirelessSensorReader", "WirelessSensorContributor"],
  });

  useEffect(() => {
    if (isError) {
      notification.error(t("There was a problem with the request."));
    }
  }, [isError]);

  const { loading: triggersLoading, refetch: triggersRefetch }: any =
    sensorNodeId ? triggers[sensorNodeId] || {} : {};
  const { loading: settingsLoading, refetch: settingsRefetch }: any =
    sensorNodeId ? settings[sensorNodeId] || {} : {};

  const isDisabled =
    activeTab === TRIGGERS_TAB ? triggersLoading : settingsLoading;

  const refresh = () => {
    if (isDisabled) return;

    if (activeTab === TRIGGERS_TAB) {
      triggersRefetch && triggersRefetch();
    } else {
      settingsRefetch && settingsRefetch();
    }
  };

  const broom = () => {
    if (isDisabled) return;
    clearState();
    if (activeTab === TRIGGERS_TAB) {
      triggersRefetch && triggersRefetch();
    } else {
      settingsRefetch && settingsRefetch();
    }
  };

  return (
    <>
      <div className="sensor-header">
        <span className="sensor-title">
          {sensor
            ? sensor.sensorNo &&
              sensor.sensorNo.trim() !== "" &&
              hasWirelessSensorReaderPermissions
              ? sensor.sensorNo + " (" + sensor.sensorNodeId + ") "
              : sensor.sensorNodeId
            : ""}
        </span>
        {isLoadingClearSensorState ? (
          <Spinner size={SpinnerSize.small} />
        ) : (
          <IconButton
            disabled={isDisabled}
            iconProps={{ iconName: "Broom" }}
            onClick={broom}
          />
        )}
        <IconButton
          disabled={isDisabled}
          iconProps={{ iconName: "Refresh" }}
          onClick={refresh}
        />
      </div>
    </>
  );
};

export default SensorHeader;
