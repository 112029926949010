import {
  Text,
  Icon,
  TooltipHost,
  IObjectWithKey,
  IRenderFunction,
  IDetailsRowProps,
  DetailsRow,
  IDetailsRowStyles,
} from "@fluentui/react";
import { createContext, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { Status } from "../../schema/status";
import { WirelessSensorNode, WirelessSensorNodeResponse } from "./models";
import {
  listAsyncSensorNodes,
  selectWirelessSensorNodes,
  selectWirelessSensorNodesStatus,
} from "./reducer";
import {
  listAsyncProjs,
  selectProjects,
  selectProjectsStatus,
} from "../Projects/reducer";
import {
  listAsyncGateways,
  selectWirelessGateways,
  selectWirelessGatewaysStatus,
} from "../Gateways/reducer";
import {
  listAsyncMachines,
  selectMachines,
  selectMachinesStatus,
} from "../Machines/reducer";

import Table, { Column, useTableFilters } from "../common/Table";
import BaseCommandBar, {
  CommandBarItemProps,
  CommandBarItemType,
  computeCommandBarItems,
} from "../common/CommandBar";
import { notification } from "../common/Notification";

import {
  commandBarStyles,
  iconStyle,
  linkStyle,
  pageStyle,
  titleStyle,
} from "../../schema/Constants";
import { DeleteConfirm } from "./DeleteConfirm";
import { AddOrEditDialog } from "./AddOrEditDialog";
import { EditBulkDialog } from "./EditBulkDialog";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";
import { format } from "../../schema/Utils";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

type ItemsSelected = WirelessSensorNodeResponse & IObjectWithKey;

export const wirelessContext = createContext<WirelessSensorNodeResponse[]>([]);

export const selWirelessContext = createContext<WirelessSensorNode | undefined>(
  undefined
);

export const selWirelessBulkContext = createContext<
  WirelessSensorNode[] | undefined
>(undefined);

type GetColumnsOpts = {
  hasActions: boolean;
  onEdit: (sensorNode: WirelessSensorNodeResponse) => void;
  onDelete: (sensorNode: WirelessSensorNodeResponse) => void;
};

const getColumns = ({
  hasActions,
  onEdit,
  onDelete,
}: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "sensorNodeId",
      name: t("Sensor Node Id"),
      fieldName: "sensorNodeId",
      minWidth: 100,
      isSortable: true,
      onRender: ({ id, sensorNodeId }: WirelessSensorNodeResponse) => (
        <Link to={id} style={linkStyle}>
          {sensorNodeId}
        </Link>
      ),
    },
    {
      key: "deviceModel",
      name: t("Device Model"),
      fieldName: "deviceModel",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "deviceClass",
      name: t("Device Class"),
      fieldName: "deviceClass",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "wirepassSinkNodeAddress",
      name: t("Wirepass Sink Node Address"),
      fieldName: "wirepassSinkNodeAddress",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "wirepassChannel",
      name: t("Wirepass Channel"),
      fieldName: "wirepassChannel",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "firmware",
      name: t("Firmware"),
      fieldName: "firmware",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "wirelessGateway",
      name: t("Gateway"),
      fieldName: "wirelessGateway",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "machine",
      name: t("Machine"),
      fieldName: "machine",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "project",
      name: t("Project"),
      fieldName: "project",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "company",
      name: t("Company"),
      fieldName: "company",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "corporation",
      name: t("Corporation"),
      fieldName: "corporation",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "trendDataUntil",
      name: t("Trend Data Until"),
      fieldName: "trendDataUntil",
      minWidth: 150,
      isSortable: true,
      onRender: ({ trendDataUntil }: WirelessSensorNodeResponse) =>
        trendDataUntil && format(new Date(trendDataUntil), "Y-MM-dd HH:mm:ss"),
    },
    {
      key: "rawDataUntil",
      name: t("Raw Data Until"),
      fieldName: "rawDataUntil",
      minWidth: 150,
      isSortable: true,
      onRender: ({ rawDataUntil }: WirelessSensorNodeResponse) =>
        rawDataUntil && format(new Date(rawDataUntil), "Y-MM-dd HH:mm:ss"),
    },
    {
      key: "controlDataUntil",
      name: t("Control Data Until"),
      fieldName: "controlDataUntil",
      minWidth: 150,
      isSortable: true,
      onRender: ({ controlDataUntil }: WirelessSensorNodeResponse) =>
        controlDataUntil &&
        format(new Date(controlDataUntil), "Y-MM-dd HH:mm:ss"),
    },
    {
      key: "isActive",
      name: t("Is Active"),
      fieldName: "isActive",
      minWidth: 100,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (sensorNode: WirelessSensorNodeResponse) => (
        <div style={{ display: "flex" }}>
          <TooltipHost
            key={0}
            content={t("Edit")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Edit"
              onClick={() => onEdit(sensorNode)}
              style={iconStyle}
            />
          </TooltipHost>
          <TooltipHost
            key={1}
            content={t("Delete")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => onDelete(sensorNode)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    });
  }

  return columns;
};

export const WirelessSensorNodesList: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const auth = useContext(authContext);
  const hasWritePermission = auth.wirelessSensorContributor;
  const sensorNodes = useAppSelector(selectWirelessSensorNodes);
  const status = useAppSelector(selectWirelessSensorNodesStatus);
  const [selectedItems, setSelectedItems] = useState<ItemsSelected[]>();
  const dispatch = useAppDispatch();

  const machs = useAppSelector(selectMachines);
  const machineStatus = useAppSelector(selectMachinesStatus);
  const projs = useAppSelector(selectProjects);
  const projectStatus = useAppSelector(selectProjectsStatus);
  const gateways = useAppSelector(selectWirelessGateways);
  const gatewaysStatus = useAppSelector(selectWirelessGatewaysStatus);
  const sensorNodeStatus = useAppSelector(selectWirelessSensorNodesStatus);

  useEffect(() => {
    if (projectStatus === Status.void) dispatch(listAsyncProjs());
    if (machineStatus === Status.void) dispatch(listAsyncMachines());
    if (projectStatus === Status.idle && gatewaysStatus === Status.void)
      dispatch(listAsyncGateways(projs));
    else if (
      gatewaysStatus === Status.idle &&
      machineStatus === Status.idle &&
      sensorNodeStatus === Status.void
    )
      dispatch(
        listAsyncSensorNodes({
          wireless: gateways,
          machs: machs,
        })
      );
  }, [
    dispatch,
    projectStatus,
    machineStatus,
    gatewaysStatus,
    projs,
    gateways,
    machs,
    sensorNodeStatus,
  ]);

  const [selected, setSelected] = useState<{
    data: WirelessSensorNodeResponse;
    context: "add" | "edit" | "delete" | "editB";
    selItems?: ItemsSelected[];
  }>();

  const onAdd = () => setSelected({ data: null, context: "add" });

  const editBulkOnclick = (): boolean | void => {
    setSelected({ data: undefined, selItems: selectedItems, context: "editB" });
  };

  const onEdit = (sensorNode: WirelessSensorNodeResponse) =>
    setSelected({ data: sensorNode, context: "edit" });

  const onDelete = (sensorNode: WirelessSensorNodeResponse) =>
    setSelected({ data: sensorNode, context: "delete" });

  const commandBarItems: CommandBarItemProps[] = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{t("Sensor Nodes")}</Text>,
    },
    ...(hasWritePermission && machineStatus === Status.idle
      ? [
          {
            key: "add",
            text: t("Add"),
            type: CommandBarItemType.Button,
            iconProps: { iconName: "Add" },
            onClick: onAdd,
          },

          {
            key: "edit",
            text: t("Bulk Edit"),
            type: CommandBarItemType.Button,
            disabled: !(selectedItems?.length > 1),
            //disabled: true,
            iconProps: {
              iconName: "ColumnVerticalSectionEdit",
            },
            onClick: editBulkOnclick,
          },
        ]
      : []),
  ];

  const { filters, handleSearch } = useTableFilters<WirelessSensorNodeResponse>(
    {
      keys: [
        "corporation",
        "company",
        "deviceClass",
        "deviceModel",
        "machine",
        "project",
        "sensorNodeId",
        "wirelessGateway",
        "isActive",
      ],
    }
  );

  const OnRenderRow: IRenderFunction<IDetailsRowProps> = (
    props?: IDetailsRowProps
  ): JSX.Element => {
    if (!props) {
      return <DetailsRow item={undefined} itemIndex={-1} />;
    }
    const customStyles: Partial<IDetailsRowStyles> = { root: {} };
    (customStyles.root as any).userSelect = "any";
    return <DetailsRow {...props} styles={customStyles} />;
  };

  return (
    <div style={pageStyle}>
      <BaseCommandBar
        items={computeCommandBarItems(commandBarItems)}
        onSearch={handleSearch}
        styles={commandBarStyles}
      />

      <Table
        persistOpts={{
          key: "table-sensorNodes",
          version: 2,
        }}
        header={{
          title: t("Sensor Nodes"),
        }}
        items={sensorNodes}
        columns={getColumns({
          hasActions: hasWritePermission,
          onEdit,
          onDelete,
        })}
        filters={filters}
        onSelectionChanged={(items: ItemsSelected[]) => setSelectedItems(items)}
        //hasSelection={false}
        isLoading={
          status === Status.loading ||
          projectStatus === Status.loading ||
          gatewaysStatus === Status.loading ||
          machineStatus === Status.loading
        }
        isError={status === Status.error}
        onRenderRow={OnRenderRow}
      />

      <DeleteConfirm
        data={selected?.data}
        show={selected?.context === "delete"}
        onSuccess={(hasError) => {
          if (hasError) {
            notification.error(
              t(`Failed deleting {{sensorId}} sensor Node`, {
                sensorId: selected?.data?.sensorNodeId,
              })
            );
          } else {
            dispatch(
              listAsyncSensorNodes({
                wireless: gateways,
                machs: machs,
              })
            );
            notification.success(
              t(`{{sensorId}} sensor Node deleted successfully`, {
                sensorId: selected?.data?.sensorNodeId,
              })
            );
          }

          setSelected(null);
        }}
        onClose={() => setSelected(null)}
      />
      {["add", "edit"].includes(selected?.context) && (
        <AddOrEditDialog
          options={machs.map((mach) => ({
            key: mach.id!,
            text: mach.dalogId + " (" + mach.project.name + ")",
          }))}
          data={selected?.data}
          items={sensorNodes}
          show={["add", "edit"].includes(selected?.context)}
          onSuccess={(hasError, data, context) => {
            if (hasError) {
              const message =
                context === "add"
                  ? t(`Failed creating Sensor node`)
                  : t(`Failed updating Sensor node`);

              notification.error(message);
            } else {
              dispatch(
                listAsyncSensorNodes({
                  wireless: gateways,
                  machs: machs,
                })
              );

              const message =
                context === "add"
                  ? t(`Sensor node created successfully`)
                  : t(`Sensor node updated successfully`);

              notification.success(message);
            }
          }}
          onClose={() => {
            setSelected(null);
          }}
        />
      )}
      {["editB"].includes(selected?.context) && (
        <EditBulkDialog
          sensorNodeIds={selected?.selItems.map((item) => {
            return {
              id: item.id,
              sensorId: item.sensorNodeId,
              deviceClass: item.deviceClass,
              deviceModel: item.deviceModel,
              isActive: item.isActive,
            };
          })}
          options={machs.map((mach) => ({
            key: mach.id!,
            text: mach.dalogId + " (" + mach.project.name + ")",
          }))}
          data={selected?.selItems.at(0)}
          show={["editB"].includes(selected?.context)}
          onSuccess={(hasError) => {
            if (hasError) {
              const message = t(`Failed updating`);

              notification.error(message);
            } else {
              dispatch(
                listAsyncSensorNodes({
                  wireless: gateways,
                  machs: machs,
                })
              );

              const message = t(`Updated  successfully`);

              notification.success(message);
            }
          }}
          onClose={() => {
            setSelected(null);
          }}
        />
      )}
    </div>
  );
};
