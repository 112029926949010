import * as React from "react";
import { Stack, Separator, TextField } from "@fluentui/react";
import { SecondPanelContext } from "./SubmissionsDetails";
import {
  CompanySubmission,
  CorporationSubmission,
  ProjectSubmission,
  SensorNodesSubmission,
} from "./models";
import {
  listAsyncGateways,
  selectWirelessGatewaysStatus,
} from "../../Gateways/reducer";
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import {
  listAsyncProjs,
  selectProjects,
  selectProjectsStatus,
} from "../../Projects/reducer";
import { useEffect } from "react";
import { Status } from "../../../schema/status";
import {
  listAsyncMachines,
  selectMachinesStatus,
} from "../../Machines/reducer";
import GatewaySupportInput from "./Gateways/GatewaySupportInput";
import { MachineSupportInput } from "./Machines/MachineSupportInput";
import { useTranslation } from "react-i18next";

const scrollableContentStyles: React.CSSProperties = {
  maxHeight: "calc(100vh - 200px)",
  overflowY: "auto",
};

interface DetailsPanelProps {
  context: SecondPanelContext;
}

const DetailsPanel: React.FC<DetailsPanelProps> = ({
  context,
}: DetailsPanelProps) => {
  const { t } = useTranslation();
  const status = useAppSelector(selectWirelessGatewaysStatus);
  const dispatch = useAppDispatch();
  const projs = useAppSelector(selectProjects);
  const projectStatus = useAppSelector(selectProjectsStatus);
  const machineStatus = useAppSelector(selectMachinesStatus);
  // const { bigScreen } = useViewport();

  // Gets the machines and projects list.
  useEffect(() => {
    if (machineStatus !== Status.void) {
      return;
    }

    machineStatus === Status.void && dispatch(listAsyncMachines());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineStatus]);

  useEffect(() => {
    if (projectStatus === Status.void) dispatch(listAsyncProjs());
  }, [dispatch, projectStatus]);

  useEffect(() => {
    if (status === Status.void && projectStatus === Status.idle)
      dispatch(listAsyncGateways(projs));
  }, [dispatch, projectStatus, status, projs]);

  return (
    <Stack
      style={{ minWidth: "350px", maxWidth: "500px" }}
      tokens={{ childrenGap: 10 }}
    >
      <Separator />
      <Stack verticalFill>
        <Stack.Item grow={1} style={scrollableContentStyles}>
          <div
            style={{
              height: context.context === "Gateway" ? "70vh" : "60vh",
            }}
          >
            <TextField
              defaultValue={context.initialValues.corpo.name}
              disabled={
                !!context.initialValues.corpo.id ||
                context.context !== "Corporation"
              }
              label={t("Corporation")}
              onChange={(ev, newVal) => {
                const corpNew: CorporationSubmission = {
                  name: newVal.trim(),
                  uniqueId: context.initialValues.corpo.uniqueId,
                };
                context.updateFunction(
                  corpNew,
                  context.initialValues.corpo.uniqueId
                );
              }}
            />
            {context.initialValues.comp && (
              <>
                <TextField
                  defaultValue={context.initialValues.comp.name}
                  disabled={
                    !!context.initialValues.comp.id ||
                    context.context !== "Company"
                  }
                  label={t("Company")}
                  onChange={(ev, newVal) => {
                    const compNew: CompanySubmission = {
                      name: newVal.trim(),
                      uniqueId: context.initialValues.comp.uniqueId,
                    };
                    context.updateFunction(
                      compNew,
                      context.initialValues.comp.uniqueId
                    );
                  }}
                />

                {context.initialValues.proj && (
                  <>
                    <TextField
                      defaultValue={context.initialValues.proj?.name}
                      disabled={
                        !!context.initialValues.proj.id ||
                        context.context !== "Project"
                      }
                      label={t("Project to assign:")}
                      onChange={(ev, newVal) => {
                        const projNew: ProjectSubmission = {
                          name: newVal.trim(),
                          uniqueId: context.initialValues.proj.uniqueId,
                        };
                        context.updateFunction(
                          projNew,
                          context.initialValues.proj.uniqueId
                        );
                      }}
                    />

                    {context.initialValues.gat ? (
                      <>
                        <GatewaySupportInput context={context} />
                      </>
                    ) : (
                      context.initialValues.mach && (
                        <>
                          <MachineSupportInput
                            context={context}
                            matchUniqueId={context.initialValues.mach.uniqueId}
                          />

                          {context.initialValues.sen && (
                            <>
                              <TextField
                                key={context.initialValues.sen.uniqueId}
                                defaultValue={
                                  context.initialValues.sen?.sensorSerialNumber
                                }
                                disabled={context.context !== "Sensor Node"}
                                label={t("Sensor Serial Number")}
                                onChange={(ev, newVal) => {
                                  const senNew: SensorNodesSubmission = {
                                    sensorSerialNumber: newVal.trim(),
                                    uniqueId:
                                      context.initialValues.sen.uniqueId,
                                  };
                                  context.updateFunction(
                                    senNew,
                                    context.initialValues.sen.uniqueId
                                  );
                                }}
                              />
                            </>
                          )}
                        </>
                      )
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default DetailsPanel;
