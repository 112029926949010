import Table, { Column } from "../common/Table";
import { notification } from "../common/Notification";
import { NoData } from "../common/NoData";
import BaseDialog, { DialogSize } from "../common/Dialog";

import {
  DialogType,
  DialogFooter,
  IDialogProps,
  PrimaryButton,
  DefaultButton,
  StackItem,
  Pivot,
  Stack,
  PivotItem,
  ScrollablePane,
  ScrollbarVisibility,
  IStackTokens,
  Link,
  Spinner,
  Checkbox,
  DatePicker,
} from "@fluentui/react";

import { Icon as IconF } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { listAsyncRoles, selectRoles, selectRolesStatus } from "./reducer";
import { RoleType, ScopeLevelType, Status } from "../../schema/status";
import {
  CustomItemsType,
  listAsyncScopesOfRole,
  selectScopesOfRoleItems,
  selectScopesOfRoleStatus,
} from "./ScopesOfRole/reducer";
import { IRole } from "./models";
import { format } from "../../schema/Utils";
import { ScopeSelections } from "../ServiceAccount/ScopesSelections";
import { linkStyle } from "../../schema/Constants";
import { ServiceAccountAddRoleToShow } from "../ServiceAccount/AddDialog";
import { useTranslation } from "react-i18next";
import { getDayPickerStrings } from "../common/Form";

export type ControlledItem = CustomItemsType & {
  check: boolean;
};

const stackTokens: IStackTokens = {
  childrenGap: 20,
};

type DeleteDialogProps = IDialogProps & {
  show: boolean;
  onClose: () => void;
  rolesToAdd: ServiceAccountAddRoleToShow[];
  setRolesToAdd: React.Dispatch<
    React.SetStateAction<ServiceAccountAddRoleToShow[]>
  >;
};

interface pivotsProps {
  name: string;
  completed: boolean;
  availableOnComplete: string[];
  next?: string;
  prev?: string;
}

interface pivotsCustom {
  selected: string;
  items: pivotsProps[];
}

export type RoleItemDescription = {
  roleId: string;
  role?: RoleType | undefined;
  scopeLevel?: ScopeLevelType | undefined;
  scopeLevelId?: string | undefined;
};

export const AddRole = ({
  show,
  onClose,
  rolesToAdd,
  setRolesToAdd,
  ...rest
}: DeleteDialogProps) => {
  const { t } = useTranslation();
  const [pivotProps, setPivotProps] = useState<pivotsCustom>({
    selected: "role",
    items: [],
  });
  const dispatch = useAppDispatch();
  const [rolesId, setRolesId] = useState<RoleItemDescription[] | undefined>();

  const rolesStatus = useAppSelector(selectRolesStatus);
  const roles = useAppSelector(selectRoles);
  const allItems: CustomItemsType[] = useAppSelector(selectScopesOfRoleItems);
  const scopePerRoleStatus = useAppSelector(selectScopesOfRoleStatus);
  const [withOutExpiration, setWithOutExpiration] = useState(false);
  const [controlledItems, setControlledItems] = useState<ControlledItem[]>();

  const [expiresAtUtc, setExpiresAtUtc] = useState<string>();

  useEffect(() => {
    rolesId &&
      setControlledItems(
        allItems?.map((ele) => {
          if (ele.name === "name") return { ...ele, check: true };
          else return { ...ele, check: false };
        })
      );
    return () => {};
  }, [allItems, rolesId]);

  useEffect(() => {
    if (rolesStatus === Status.void) dispatch(listAsyncRoles());
    else if (rolesStatus === Status.idle && rolesId)
      dispatch(listAsyncScopesOfRole(rolesId.at(0).roleId));
  }, [dispatch, rolesStatus, rolesId]);

  useEffect(() => {
    setPivotProps({
      selected: pivotProps.selected,
      items: [
        {
          name: "role",
          completed: rolesId ? true : false,
          availableOnComplete: ["scope"],
          next: "scope",
        },
        {
          name: "scope",
          completed:
            rolesId &&
            controlledItems?.filter((ele) => ele.check === true).length > 0,
          availableOnComplete: ["role", "expiration"],
          next: "expiration",
          prev: "role",
        },
        {
          name: "expiration",
          completed:
            rolesId &&
            controlledItems?.filter((ele) => ele.check === true).length > 0,
          availableOnComplete: ["role", "scope", "review"],
          next: "review",
          prev: "members",
        },
        {
          name: "review",
          completed:
            rolesId &&
            controlledItems?.filter((ele) => ele.check === true).length > 0,
          availableOnComplete: ["role", "scope", "expiration"],
          prev: "expiration",
        },
      ],
    });
    return () => {};
  }, [pivotProps.selected, rolesId, controlledItems]);

  const handleClose = () => {
    setWithOutExpiration(false);
    setPivotProps({
      selected: "role",
      items: [],
    });
    setRolesId(undefined);

    onClose?.();
  };

  const handleAdd = async () => {
    const auxItems: ServiceAccountAddRoleToShow[] = [];
    const filteredItems = controlledItems?.filter((ele) => ele.check === true);

    rolesId.forEach((rol) =>
      filteredItems.forEach((ele) =>
        auxItems.push({
          roleId: rol.roleId,
          scopeLevelId: ele.scopeId,
          resource: ele.name,
          role: rol.role,
          scopeResourceId: ele.scopeResourceId,
          scopeLevel: ele.scope,
          expiresAtUtc: expiresAtUtc,
        })
      )
    );

    setWithOutExpiration(false);
    setPivotProps({
      selected: "role",
      items: [],
    });

    const filteredArr = [...auxItems, ...rolesToAdd].reduce((acc, current) => {
      const x = acc.find(
        (item) =>
          item.roleId === current.roleId &&
          item.scopeLevelId === current.scopeLevelId &&
          item.scopeResourceId === current.scopeResourceId
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        notification.info(t("One or more items are already selected."));
        return acc;
      }
    }, []);

    setRolesToAdd(filteredArr);

    handleClose();
  };

  const getColumns = () => {
    const columns: Column[] = [
      {
        key: "type",
        name: t("Name"),
        fieldName: "Name",
        minWidth: 150,
        isSortable: true,
        onRender: (item: IRole) => (
          <Link
            onClick={(e) => {
              setPivotProps({ ...pivotProps, selected: "scope" });
              setRolesId([{ roleId: item.id, role: item.type }]);
            }}
            style={linkStyle}
          >
            {item.type}
          </Link>
        ),
      },
      {
        key: "description",
        name: t("Description"),
        fieldName: "description",
        minWidth: 200,
        isSortable: true,
      },
      {
        key: "hasScope",
        name: t("Has Scope"),
        fieldName: "hasScope",
        minWidth: 70,
        isSortable: true,
        onRender: (item: IRole) => (
          <div style={{ display: "flex" }}>{item.hasScope.toString()}</div>
        ),
      },
      {
        key: "parent",
        name: t("Parent"),
        fieldName: "parent",
        minWidth: 150,
        isSortable: true,
        onRender: (item: IRole) => (
          <div style={{ display: "flex" }}>
            {item.parent ? item.parent.type : undefined}
          </div>
        ),
      },
    ];
    return columns;
  };

  const onLinkClick = (item) => {
    const myProps = pivotProps.items.find(
      (ite) => ite.name === pivotProps.selected
    );
    const myPropsToGo = pivotProps.items.find(
      (ite) => ite.name === item.props.itemKey
    );

    if (myProps.completed || myPropsToGo.completed)
      setPivotProps({
        selected: item.props.itemKey,
        items: [...pivotProps.items],
      });
    else {
      notification.warning(
        t("Please complete the {{name}} selection", { name: myProps.name })
      );
    }
  };

  const rolePivot = useCallback(() => {
    return (
      <Stack>
        <Table
          // perPage={roles.length}
          persistOpts={{
            key: "table-role",
            version: 2,
          }}
          header={{
            title: t("Role selection"),
          }}
          items={roles}
          columns={getColumns()}
          hasSelection={false}
          isLoading={rolesStatus === Status.loading}
          isError={rolesStatus === Status.error}
        />
      </Stack>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, t]);

  const scopePivot = useCallback(() => {
    return (
      <>
        {scopePerRoleStatus === Status.idle ? (
          <Stack>
            <ScopeSelections
              controlledItems={controlledItems}
              setControlledItems={setControlledItems}
            />
          </Stack>
        ) : scopePerRoleStatus === Status.loading ? (
          <Spinner />
        ) : (
          <Stack
            styles={{ root: { height: "100vh" } }}
            verticalAlign="space-around"
          >
            <NoData />
          </Stack>
        )}
      </>
    );
  }, [scopePerRoleStatus, controlledItems]);

  const expirationPivot = useCallback(() => {
    return (
      <Stack horizontalAlign="center">
        <Checkbox
          label={t("Without expiration date.")}
          onChange={(ev, checked) => {
            setWithOutExpiration(checked);
            if (checked === true) setExpiresAtUtc(undefined);
          }}
          styles={{ root: { padding: 2 } }}
          checked={withOutExpiration}
        />
        {!withOutExpiration ? (
          <>
            <DatePicker
              label={t("Select the expiration date")}
              style={{ width: "20vw", textAlign: "center" }}
              placeholder={t("Select a date")}
              ariaLabel={t("Select a date")}
              strings={getDayPickerStrings(t)}
              onSelectDate={(date) => setExpiresAtUtc(format(date))}
            />
            <h5>{t("Permission expires on (UTC)")}</h5>
          </>
        ) : (
          <></>
        )}
      </Stack>
    );
  }, [withOutExpiration]);

  const reviewPivot = useCallback(() => {
    const filteredItems = controlledItems?.filter((ele) => ele.check === true);
    return (
      <>
        {
          <>
            <Stack
              verticalFill
              //verticalAlign="space-evenly"
              horizontalAlign="baseline"
              style={{ padding: 40, paddingLeft: 80 }}
            >
              <Stack horizontal>
                <StackItem style={{ width: "20vw" }}>
                  <h3>{t("Role:")}</h3>
                </StackItem>
                <>
                  {rolesId.map((rol) => (
                    <div key={rol.roleId}>
                      <StackItem>{rol.role}</StackItem>
                    </div>
                  ))}
                </>
              </Stack>

              <Stack horizontal>
                <StackItem style={{ width: "20vw" }}>
                  <h3>{t("Scopes:")} </h3>
                </StackItem>
                <StackItem>
                  {filteredItems?.map((ele) => (
                    <div key={ele.scopeResourceId}>
                      <StackItem>
                        <b>{ele.scope}</b>: {ele.name}.
                      </StackItem>
                    </div>
                  ))}
                </StackItem>
              </Stack>

              <Stack horizontal>
                <StackItem style={{ width: "20vw" }}>
                  <h3> {t("Expires:")} </h3>
                </StackItem>
                <StackItem>{expiresAtUtc ? t("True") : t("False")}</StackItem>
              </Stack>
              {expiresAtUtc ? (
                <Stack horizontal>
                  <StackItem style={{ width: "20vw" }}>
                    <h3> {t("Expires on:")} </h3>
                  </StackItem>
                  <StackItem>{expiresAtUtc}</StackItem>
                </Stack>
              ) : null}
            </Stack>
          </>
        }
      </>
    );
  }, [controlledItems, expiresAtUtc, rolesId]);

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        title: t("Add Role"),
        type: DialogType.close,
        onDismiss: handleClose,
      }}
      size={DialogSize.L}
    >
      <Stack
        tokens={stackTokens}
        horizontalAlign="center"
        styles={{ root: { display: "grid" } }}
      >
        <StackItem>
          <Pivot
            aria-label={t("Pivot to add permission")}
            selectedKey={pivotProps.selected}
            onLinkClick={onLinkClick}
          >
            <PivotItem
              headerText={t("Role")}
              itemKey={"role"}
              onRenderItemLink={() => (
                <div>
                  {rolesId ? (
                    <>
                      <IconF iconName="SkypeCheck" /> {t("Role")}
                    </>
                  ) : (
                    <> {t("Role")}</>
                  )}
                </div>
              )}
            >
              <ScrollablePane
                scrollContainerFocus={true}
                scrollbarVisibility={ScrollbarVisibility.auto}
                scrollContainerAriaLabel={"scroll-projects"}
                style={{
                  height: "60vh",
                  position: "relative",
                }}
              >
                {rolePivot()}
              </ScrollablePane>
            </PivotItem>
            <PivotItem
              headerText={t("Scope")}
              itemKey={"scope"}
              onRenderItemLink={() => (
                <div>
                  {rolesId &&
                  controlledItems?.filter((ele) => ele.check === true).length >
                    0 ? (
                    <>
                      <IconF iconName="SkypeCheck" />{" "}
                      {t("Scope [{{scopeName}}]", {
                        scopeName: controlledItems
                          ?.filter((item) => item.check === true)
                          .length.toString(),
                      })}
                    </>
                  ) : (
                    <> {t("Scope")}</>
                  )}
                </div>
              )}
            >
              <ScrollablePane
                scrollContainerFocus={true}
                scrollbarVisibility={ScrollbarVisibility.auto}
                scrollContainerAriaLabel={"scroll-projects"}
                style={{
                  height: "60vh",
                  position: "relative",
                }}
              >
                {scopePivot()}
              </ScrollablePane>
            </PivotItem>
            <PivotItem
              headerText={"Expiration"}
              itemKey={"expiration"}
              onRenderItemLink={() => (
                <div>
                  {rolesId &&
                  controlledItems?.filter((ele) => ele.check === true).length >
                    0 &&
                  (expiresAtUtc || withOutExpiration) ? (
                    <>
                      <IconF iconName="SkypeCheck" /> {t("Expiration")}
                    </>
                  ) : (
                    <> {t("Expiration")}</>
                  )}
                </div>
              )}
            >
              <ScrollablePane
                scrollContainerFocus={true}
                scrollbarVisibility={ScrollbarVisibility.auto}
                scrollContainerAriaLabel={"scroll-projects"}
                style={{
                  height: "60vh",
                  position: "relative",
                }}
              >
                <Stack
                  verticalFill
                  verticalAlign="center"
                  style={{ height: "50vh" }}
                >
                  {expirationPivot()}
                </Stack>
              </ScrollablePane>
            </PivotItem>
            <PivotItem headerText={t("Review")} itemKey={"review"}>
              {rolesId && (
                <ScrollablePane
                  scrollContainerFocus={true}
                  scrollbarVisibility={ScrollbarVisibility.auto}
                  scrollContainerAriaLabel={"scroll-projects"}
                  style={{
                    height: "60vh",
                    position: "relative",
                  }}
                >
                  {reviewPivot()}
                </ScrollablePane>
              )}
            </PivotItem>
          </Pivot>
        </StackItem>
      </Stack>
      <DialogFooter>
        <PrimaryButton
          text={t("Add Role")}
          disabled={
            !(
              rolesId &&
              controlledItems?.filter((ele) => ele.check === true).length > 0
            )
          }
          onClick={handleAdd}
        />
        <DefaultButton text={t("Close")} onClick={handleClose} />
      </DialogFooter>
    </BaseDialog>
  );
};
