import { useEffect, useMemo, useState } from "react";

import GatewayIcon from "../../../../assets/svg/WirelessSensors/GatewayIcon";
import SensorNodeIcon from "../../../../assets/svg/WirelessSensors/SensorNodeIcon";
import type { TreeItem } from "../../components/Tree";
import WirelessSensorsTree from "../../components/Tree";
import { useGatewaySensorsProfiles } from "../../hooks/useGatewaySensorsProfiles";
import { useGatewayTriggering } from "../../hooks/useGatewayTriggering";
import { getColumns } from "./columns";
import type { Topology } from "../../../../types";
import { notification } from "../../../common/Notification";
import type { TableProps } from "../../../common/Table";
import Table from "../../../common/Table";

import { useGatewayTelemetry } from "../../hooks/useGatewayTelemetry";
import { useTranslation } from "react-i18next";

const GatewayContent = ({ gateway }: any) => {
  const { t } = useTranslation();
  const gatewayId = gateway?.serialNumber;
  const {
    data: sensorsProfiles,
    isLoading,
    isError,
    refetch,
  } = useGatewaySensorsProfiles({ gatewayId });
  const [triggerLoading, setTriggerLoading] = useState(false);
  const { triggerGatewayAsync } = useGatewayTriggering({
    gatewayId: gatewayId as string,
  });

  const {
    data,
    isLoading: isLoadingGatTelemetry,
    isError: isErrorGatTel,
  } = useGatewayTelemetry({
    gatewayId: gateway.serialNumber ?? "",
  });
  const [tree, setTree] = useState<TreeItem>({});

  useEffect(() => {
    if (!isErrorGatTel && !isLoadingGatTelemetry && data) {
      const treeData: TreeItem = {
        name: data.gatewayTelemetry.gatewayId,
        icon: <GatewayIcon />,
        condition: data.gatewayTelemetry.enabled ? "Ok" : "Alert",
        children: data?.gatewayTelemetry.topology.children?.map((topology) =>
          topologyTelemetryToTreeItem(topology)
        ),
      };
      setTree(treeData);
    }

    return;
  }, [data, isLoadingGatTelemetry, isErrorGatTel]);

  const tableProps = useMemo<TableProps>(
    () => ({
      isLoading,
      isError,
      persistOpts: {
        key: "gateway-sensor-trigger-status",
        version: 1,
      },
      header: {
        title: "Gateway Sensor Trigger Status ",
      },
      columns: getColumns(t),
      hasSelection: false,
      items: sensorsProfiles,
    }),
    [isLoading, t, isError, sensorsProfiles]
  );

  const onTrigger = () => {
    setTriggerLoading(true);
    triggerGatewayAsync().then(
      () => {
        notification.success(t("The gateway trigger was sent successfully"));
        setTriggerLoading(false);
      },
      (err) => {
        notification.error(
          err?.response?.data?.detail || t("Send trigger gateway error")
        );
        setTriggerLoading(false);
      }
    );
  };

  const topologyTelemetryToTreeItem = (telemetry: Topology): TreeItem => {
    return {
      name: telemetry.value,
      children: telemetry.children.map(topologyTelemetryToTreeItem),
      icon: <SensorNodeIcon />,
      condition: "Ok",
    };
  };

  return (
    <>
      <WirelessSensorsTree
        data={tree}
        title={t("Gateway Telemetry")}
        buttonProps={{
          loading: triggerLoading,
          buttonText: t("Send trigger to gateway"),
          onSend: onTrigger,
        }}
        isLoading={isLoadingGatTelemetry}
        split={false}
        onRefresh={refetch}
      />
      <Table {...tableProps} />
    </>
  );
};

export default GatewayContent;
