/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";

import { VpnConnectionsAPI } from "../Schema/api";
import {
  AutomationStatusType,
  ConnectionEntryBasic,
  ConnectionStatusType,
  MetadataProject,
} from "../Schema/models";
import {
  ConnectionItemDialogProps,
  ConnectionsActionsList,
} from "../Schema/viewModels";
import { AxiosContext } from "../VpnConnectionsManager/VpnConnectionsManager";
import { WithLoadingPanelHOC } from "./generic/HOCs";
import { AddConnectionDialog } from "./vpnConnections/AddEditConnectionDialog";
import ConnectionsList, {
  ConnectionsTableItem,
} from "./vpnConnections/ConnectionsList";
import VpnPCsListDialog from "./vpnPCs/VpnPCsListDialog";
import DownloadImagesDialog from "./downloadImages/DownloadImagesDialog";
import BaseCommandBar, {
  CommandBarItemProps,
  CommandBarItemType,
  computeCommandBarItems,
} from "../../common/CommandBar";
import { useTableFilters } from "../../common/Table";
import { notification } from "../../common/Notification";

import { useTranslation } from "react-i18next";

type BarItemsProps = {
  t: any;
  hasAdminPermissions: boolean;
  onAdd: () => void;
  onVpnPcDetails: () => void;
  onDownloadImages: () => void;
};

type VpnConnectionsSectionProps = {
  hasAdminPermissions: boolean;
  projects: MetadataProject[];
  onClose: () => void;
};

const getBarItems = ({
  t,
  hasAdminPermissions,
  onAdd,
  onVpnPcDetails,
  onDownloadImages,
}: BarItemsProps): CommandBarItemProps[] => {
  let userActionsProps: CommandBarItemProps[] = [
    {
      key: "vpn-add-connection",
      text: t("Add"),
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Add" },
      onClick: onAdd,
    },
  ];

  let adminActionProps: CommandBarItemProps[] = [
    ...userActionsProps,
    {
      key: "vpn-pc-details",
      text: t("VPN PC Details"),
      type: CommandBarItemType.Button,
      iconProps: { iconName: "ThisPC" },
      onClick: onVpnPcDetails,
    },
    {
      key: "vpn-download-images",
      text: t("Download Images"),
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Download" },
      onClick: onDownloadImages,
    },
  ];

  return hasAdminPermissions ? adminActionProps : userActionsProps;
};

const buildTableItems = (
  projects: MetadataProject[],
  items: ConnectionEntryBasic[]
): ConnectionsTableItem[] => {
  let result: ConnectionsTableItem[] = items.map((item) => {
    let project = projects.find((p) => p.id === item.projectId);
    let entry: ConnectionsTableItem = {
      id: item?.id || "",
      projectName: project?.name || "",
      companyName: project?.company?.name || "",
      automationStatus: AutomationStatusType[item.automationStatus.status],
      connectionStatus: ConnectionStatusType[item.connectionStatus.status],
      comments: item.connectionStatus.comment || "",
    };

    return entry;
  });

  return result;
};

const ActionDialog = ({
  action,
  projects,
  onClose,
}: ConnectionItemDialogProps) => {
  let result: JSX.Element | null = null;

  switch (action) {
    case ConnectionsActionsList.Add:
      result = <AddConnectionDialog projects={projects} onClose={onClose} />;
      break;

    case ConnectionsActionsList.VpnPCsDetails:
      result = <VpnPCsListDialog onClose={onClose} />;
      break;

    case ConnectionsActionsList.DownloadImages:
      result = <DownloadImagesDialog onClose={() => onClose()} />;
      break;
  }

  return result;
};

const VpnConnectionsSection = ({
  hasAdminPermissions,
  projects,
  onClose,
}: VpnConnectionsSectionProps) => {
  const { t } = useTranslation();
  const axiosInstance = useContext(AxiosContext);
  const [tableItems, setTableItems] = useState<ConnectionsTableItem[]>([]);
  const [selectedAction, setSelectedAction] = useState<ConnectionsActionsList>(
    ConnectionsActionsList.None
  );
  const { filters, handleSearch } = useTableFilters<ConnectionsTableItem>({
    keys: ["projectName", "companyName"],
  });
  const [listChanged, setListChanged] = useState<boolean>(true);

  // Gets the VPN connection list.
  useEffect(() => {
    if (projects.length === 0 || !axiosInstance || !listChanged) {
      return;
    }

    VpnConnectionsAPI.list(axiosInstance).then((response) => {
      if (response.status !== 200) {
        notification.error(
          t(`Failure getting the VPN connections list: {{statusText}}.`, {
            statusText: response.statusText,
          })
        );

        onClose?.();
        return;
      }

      let tableItems: ConnectionsTableItem[] = buildTableItems(
        projects,
        response.data
      );

      setTableItems(tableItems);
      setListChanged(false);
    });
  }, [projects, listChanged]);

  // Handlers
  const onAdd = () => {
    setSelectedAction(ConnectionsActionsList.Add);
  };

  const onVpnPcDetails = () =>
    setSelectedAction(ConnectionsActionsList.VpnPCsDetails);

  const onDownloadImages = () =>
    setSelectedAction(ConnectionsActionsList.DownloadImages);

  const onCloseDialog = (listChanged?: boolean) => {
    listChanged && setListChanged(true);
    setSelectedAction(ConnectionsActionsList.None);
  };

  return (
    <div className="vpn-connections-container">
      <header>
        <Stack horizontal verticalAlign="center" tokens={{ padding: 8 }}>
          <h1
            id="vpn-connections-section"
            className="area-title"
            style={{ minWidth: "14em" }}
          >
            {t("VPN Connections Manager")}
          </h1>
          <Stack.Item style={{ width: "100%" }}>
            <BaseCommandBar
              aria-labelledby="vpn-connections-section"
              items={computeCommandBarItems(
                getBarItems({
                  t,
                  hasAdminPermissions,
                  onAdd,
                  onVpnPcDetails,
                  onDownloadImages,
                })
              )}
              onSearch={handleSearch}
              styles={{
                root: { marginBottom: "0em", background: "transparent" },
              }}
            />
          </Stack.Item>
        </Stack>
      </header>
      <main className="vpn-connections-container">
        {WithLoadingPanelHOC(
          tableItems?.length > 0,
          <ConnectionsList
            hasAdminPermissions={hasAdminPermissions}
            filters={filters}
            projects={projects}
            tableItems={tableItems}
            onConnectionDataChanged={() => setListChanged(true)}
          />
        )}

        {selectedAction !== ConnectionsActionsList.None && (
          <ActionDialog
            action={selectedAction}
            projects={projects}
            onClose={onCloseDialog}
          />
        )}
      </main>
    </div>
  );
};

export default VpnConnectionsSection;
