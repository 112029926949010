import * as React from "react";
import {
  Stack,
  Persona,
  Text,
  PersonaSize,
  Separator,
  DefaultPalette,
  TextField,
  PrimaryButton,
} from "@fluentui/react";
import { Message } from "./models";
import { formatDateHuman } from "../../../schema/Utils";
import { extractNameInitials } from "../../MachineCV/UserPersona";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ChatPanelProps {
  messages: Message[];
  me: string;
  onSent: (mess: string) => void;
}

const fixedItemStyles: React.CSSProperties = {
  color: DefaultPalette.white,
  padding: "10px",
  width: "100%",
};

const scrollableContentStyles: React.CSSProperties = {
  maxHeight: "calc(100vh - 270px)",
  overflowY: "auto",
};

const ChatPanel: React.FC<ChatPanelProps> = ({ messages, me, onSent }) => {
  const { t } = useTranslation();
  const [messageInput, setMessageInput] = useState("");

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Stack horizontal horizontalAlign="baseline">
        <Text variant={"xLarge"} nowrap>
          {t("Chat")}
        </Text>
      </Stack>
      <Separator />

      <Stack verticalFill>
        <Stack.Item grow={1} style={scrollableContentStyles}>
          <div style={{ height: "1000px" }}>
            {messages &&
              messages
                .sort(
                  (a, b) =>
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                )
                .map((message, index) => (
                  <div key={message.createdBy + index}>
                    <Stack verticalFill>
                      {index > 0 ? (
                        formatDateHuman(new Date(message.createdAt), false) !==
                          formatDateHuman(
                            new Date(messages[index - 1].createdAt),
                            false
                          ) && (
                          <Stack.Item align="center" style={{ paddingTop: 20 }}>
                            <Text variant="mediumPlus">
                              {formatDateHuman(
                                new Date(message.createdAt),
                                false
                              )}
                            </Text>
                          </Stack.Item>
                        )
                      ) : (
                        <Stack.Item align="center">
                          <Text variant="mediumPlus">
                            {formatDateHuman(
                              new Date(message.createdAt),
                              false
                            )}
                          </Text>
                        </Stack.Item>
                      )}

                      <Stack.Item
                        align={me === message.createdBy ? "start" : "end"}
                      >
                        <Text
                          variant="mediumPlus"
                          style={{ marginLeft: 20, marginRight: 20 }}
                        >
                          {message.createdBy}
                        </Text>
                        <Text
                          variant="mediumPlus"
                          style={{ marginLeft: 20, marginRight: 20 }}
                        >
                          {formatDateHuman(new Date(message.createdAt))}
                        </Text>
                      </Stack.Item>

                      {me === message.createdBy ? (
                        <>
                          <Stack.Item align="start">
                            <Stack horizontal>
                              <Stack.Item
                                style={{ marginLeft: 10, marginRight: 10 }}
                              >
                                <Persona
                                  size={PersonaSize.size32}
                                  initialsColor={"#2c529f"}
                                  imageInitials={extractNameInitials(
                                    message.createdBy
                                  )}
                                />
                              </Stack.Item>
                              <Stack.Item
                                style={{
                                  marginLeft: 10,
                                  marginRight: 10,
                                  boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                }}
                              >
                                <Text
                                  style={{
                                    paddingLeft: 10,
                                    alignContent: "space-around",
                                    borderRadius: 5,
                                  }}
                                >
                                  {message.text}
                                </Text>
                              </Stack.Item>
                            </Stack>
                          </Stack.Item>
                        </>
                      ) : (
                        <>
                          <Stack.Item align="end">
                            <Stack horizontal>
                              <Stack.Item
                                style={{
                                  marginLeft: 10,
                                  marginRight: 10,
                                  boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                }}
                              >
                                <Text
                                  style={{
                                    paddingLeft: 10,
                                    alignContent: "space-around",
                                    borderRadius: 5,
                                  }}
                                >
                                  {message.text}
                                </Text>
                              </Stack.Item>
                              <Stack.Item>
                                <Persona
                                  size={PersonaSize.size32}
                                  initialsColor={"#2c529f"}
                                  imageInitials={extractNameInitials(
                                    message.createdBy
                                  )}
                                />
                              </Stack.Item>
                            </Stack>
                          </Stack.Item>
                        </>
                      )}
                    </Stack>
                  </div>
                ))}
          </div>
        </Stack.Item>
        <Stack.Item style={fixedItemStyles}>
          <Stack
            horizontal
            horizontalAlign="center"
            verticalAlign="center"
            tokens={{ childrenGap: 20 }}
          >
            <TextField
              value={messageInput}
              onChange={(event, newValue) => setMessageInput(newValue || "")}
              placeholder={t("Comment...")}
              styles={{ root: { width: "70%" } }}
            />
            <PrimaryButton
              text=""
              onClick={() => {
                onSent(messageInput);
                setMessageInput("");
              }}
              iconProps={{ iconName: "Send" }}
              style={{ maxWidth: 50 }}
            />
          </Stack>
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default ChatPanel;
