import Table, { Column } from "../../common/Table";
import { ImageDataExtractionDuration } from "../models";
import { WithLoadingPanelHOC } from "../../Generic/HOCs";
import { format } from "../../../schema/Utils";
import { Filter } from "../../common/Table/Table";
import { useTranslation } from "react-i18next";

const getTableColumns: (t) => Column[] = (t) => [
  {
    key: "timeStamp",
    name: t("Time Stamp"),
    fieldName: "timeStamp",
    minWidth: 150,
    isSortable: true,
    onRender: (item: ImageDataExtractionDuration) =>
      format(new Date(item.timeStamp), "Y-MM-dd HH:mm"),
  },
  {
    key: "fileName",
    name: t("File Name"),
    fieldName: "fileName",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "overall",
    name: t("Overall"),
    fieldName: "overall",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "event",
    name: t("Event"),
    fieldName: "event",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "fastTrend",
    name: t("Fast Trend"),
    fieldName: "fastTrend",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "short",
    name: t("Short"),
    fieldName: "short",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "trend",
    name: t("Trend"),
    fieldName: "trend",
    minWidth: 100,
    isSortable: true,
  },
];

type ImagesDataExtractionTableProps = {
  tableItems: ImageDataExtractionDuration[];
  filters: Filter[];
  isLoading: boolean;
};

/**
 * Gets the images statistics data extraction table component.
 * @param tableItems The table items.
 * @param filters The table search filters
 * @param isLoading Value determining whether the table is in loading state.
 * @returns The images statistics data extraction table component.
 */
const ImagesDataExtractionTable = ({
  tableItems,
  filters,
  isLoading,
}: ImagesDataExtractionTableProps) => {
  const { t } = useTranslation();

  return WithLoadingPanelHOC(
    !isLoading,
    <Table
      items={tableItems}
      columns={getTableColumns(t)}
      header={{ title: t("Images data extraction duration") }}
      hasSelection={false}
      filters={filters}
      persistOpts={{
        key: "images-statistics-table",
        version: 2,
      }}
    />
  );
};

export default ImagesDataExtractionTable;
