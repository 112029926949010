import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { useEffect, useRef } from "react";
import { useMeasure } from "react-use";

import { getRangeIndex, format } from "../../../../utils";
import { useUniqueId } from "../../../../Hooks";
import type {
  AxisRangeProps,
  TrendChartInterface,
} from "../../../analysis-raw-data/types";
import { format as signalFormatter } from "../../../common/utils/signalFormatter";
import type { ResponseSimplifiedSignal } from "../../../../types";
import type { XYChartRef } from "../../../../Components/common/XYChart";
import { XYChart } from "../../../../Components/common/XYChart";
import { createChartExport } from "../../../../Components/common/XYChart/export";
import { createDefaultTooltip } from "../../../../Components/common/XYChart/tooltip";

import DalogLogo from "../../../../assets/images/dalog-logo.svg";
import { watermarkLogos } from "../../constants/chartWatermarkLogos";
import useChartTimestampStore from "../../hooks/useChartTimestampStore";
import useSignalMetaData from "../../hooks/useSignalMetaData";

import { useTranslation } from "react-i18next";

const chartColor = "#2C529F";

export function createRange({
  value,
  color,
  root,
  chart,
  xAxis,
}: AxisRangeProps) {
  if (xAxis.axisRanges.values.length > 0) {
    xAxis.axisRanges.removeIndex(0);
  }

  const right = xAxis.get("renderer").get("inversed");

  const rangeDataItem = xAxis.makeDataItem({
    value: value,
    above: true,
  });

  const range = xAxis.createAxisRange(rangeDataItem);

  const label = range.get("label");

  range?.get("grid")?.setAll({
    stroke: color,
    strokeOpacity: 1,
    strokeWidth: 1,
  });

  label?.setAll({
    text: format(value as number, "dd MMM yyyy HH:mm"),
    fill: am5.color(0xffffff),
    centerY: am5.p100,
    dy: right ? -15 : 5,
    background: am5.Rectangle.new(root, {
      fill: color,
    }),
  });

  chart?.plotContainer.children.push(label as am5.Container);
}

const TrendChart = ({
  data,
  exportId,
  signal,
  isInModal,
}: TrendChartInterface) => {
  const { t } = useTranslation();
  const ref = useRef<XYChartRef | null>(null);
  const [contentRef] = useMeasure<HTMLDivElement>();
  const xAxisRef = useRef<am5xy.DateAxis<am5xy.AxisRenderer> | null>(null);
  const seriesRef = useRef<am5xy.LineSeries | null>(null);
  const rootRef = useRef<am5.Root | null>(null);
  const chartRef = useRef<am5xy.XYChart | null>(null);
  const signalRef = useRef<ResponseSimplifiedSignal | null>(null);

  const uniqueId = useUniqueId();

  const { corporation, company, project, machine } = useSignalMetaData(signal);

  const { trendChartTimestamp } = useChartTimestampStore((store) => ({
    timestamps: store.timestamps,
    trendChartTimestamp: store.trendChartTimestamp,
  }));

  const chartModalStyle = isInModal
    ? {
        root: {
          height: "100%",
        },
        chart: {
          height: "100%",
        },
      }
    : {};

  useEffect(() => {
    if (!ref?.current) return;

    const root = ref.current.root;
    const chart = ref.current.chart;

    // === Sending grid lines behind the chart series lines ===

    chart.gridContainer.toBack();

    // === Setting 0 basegrid line opacity ===
    const myTheme = am5.Theme.new(root);

    myTheme.rule("Grid", ["base"]).setAll({
      strokeOpacity: 0.1,
    });

    root.setThemes([myTheme]);

    // === X Axis ===

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "minute", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {}),
        maxDeviation: 0,
        marginTop: 16,
        extraTooltipPrecision: 2,
        tooltip: am5.Tooltip.new(root, {
          labelText: undefined,
          forceHidden: true,
          animationDuration: 0,
        }),
      })
    );

    // === X Axis grid opacity ===

    const xRenderer = xAxis.get("renderer");

    xRenderer.grid.template.setAll({
      strokeOpacity: 0.04,
    });

    xRenderer.labels.template.set("fontSize", 10);

    // === Y Axis ===

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
        maxDeviation: 0,
        strictMinMax: true,
        marginRight: 16,
        extraTooltipPrecision: 2,
        tooltip: am5.Tooltip.new(root, {
          labelText: undefined,
          forceHidden: true,
          animationDuration: 0,
        }),
      })
    );

    // === Y Axis Label ===

    yAxis.children.unshift(
      am5.Label.new(root, {
        ariaLabel: "yAxisLabel",
        ariaValueText: `${signal.unit}`,
        rotation: -90,
        text: `${signalFormatter(signal)} (${signal.unit})`,
        y: am5.p50,
        centerX: am5.p50,
        fill: am5.color(chartColor),
        fontSize: 10,
      })
    );

    // === Y Axis Label Color ===

    const yRenderer = yAxis.get("renderer");

    yRenderer.labels.template.setAll({
      fill: am5.color(chartColor),
      fontSize: 10,
    });

    yRenderer.grid.template.setAll({
      strokeOpacity: 0.04,
    });

    // === Series ===

    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        stroke: am5.color(chartColor),
        tooltip: am5.Tooltip.new(root, {
          labelText: undefined,
          forceHidden: true,
          animationDuration: 0,
        }),
      })
    );

    // === Footer Label ===

    const breadcrumbRoot = am5.Root.new(
      `breadcrumb-container-${machine.id}-${uniqueId}`
    );
    const breadcrumbContainer = document.getElementById(
      `breadcrumb-container-${machine.id}-${uniqueId}`
    );

    const breadcrumb = breadcrumbRoot.container.children.push(
      am5.Label.new(breadcrumbRoot, {
        html: `
        <span style='font-size: 12px;color: #878583;'>
          ${corporation?.name}  〉 ${company?.name}  〉 ${project?.name} 〉 ${
            machine?.name
          }  〉 <strong style='color: #333;'>${signalFormatter(signal)}</strong>
        </span>`,
        width: am5.percent(100),
        fill: am5.color("#878583"),
        paddingTop: 26,
      })
    );

    breadcrumb.events.on("boundschanged", () => {
      if (breadcrumbContainer) {
        breadcrumbContainer.style.height = breadcrumb.height() + "px";
      }
    });

    watermarkLogos.forEach((logoData) => {
      const logo = am5.Picture.new(root, {
        src: DalogLogo,
        height: logoData.height,
        opacity: logoData.opacity,
        x: logoData.x,
        y: logoData.y,
        centerX: logoData.centerX,
        centerY: logoData.centerY,
      });

      chart.plotContainer.children.push(logo);
    });

    createDefaultTooltip({
      ref: ref.current,
      type: "date",
      text: "t",
    });

    const cursor = chart.get("cursor");

    cursor?.set("snapToSeries", [series]); //TODO: snapToSeries: [series, series2], cursor snap to all series
    cursor?.set("snapToSeriesBy", "y!");
    cursor?.set("xAxis", xAxis);
    cursor?.set("yAxis", yAxis);

    createChartExport({
      root,
      chart,
      signal,
      selector: exportId,
      corporation: corporation?.name,
      company: company?.name,
      project: project?.name,
      machine: machine?.name,
    });

    xAxisRef.current = xAxis;
    seriesRef.current = series;
    rootRef.current = root;
    chartRef.current = chart;
    signalRef.current = signal;

    return () => {
      breadcrumbRoot.dispose();
      ref.current = null;
    };
  }, []);

  useEffect(() => {
    if (!ref?.current) return;

    const series = ref.current?.chart.series.getIndex(0);

    if (data.length > 0) {
      series?.data.setAll(data);
      series?.appear(500, 0);
    } else {
      series?.data.setAll([]);
    }

    const modal = am5.Modal.new(rootRef.current as am5.Root, {
      content: `<div class="loader">${t("Loading...")}</div>
      <div class="loader-label">${t("Loading chart...")}</div>
      <div class="loader-sublabel">${t("loading time depends on the amount of data")}</div>
      `,
    });

    modal.getPrivate("wrapper").classList.add("chart-modal");
    modal.getPrivate("content").classList.add("chart-modal--content");

    series?.events.on("datavalidated", function (ev) {
      ev.target.data.length < 1 ? modal.open() : modal.close();
    });

    // return () => {
    //   ref.current = null;
    // };
  }, [data, t]);

  useEffect(() => {
    if (
      !xAxisRef.current ||
      !seriesRef.current ||
      trendChartTimestamp === null ||
      data.length === 0
    ) {
      return;
    }

    const idx = getRangeIndex({
      data: data,
      threshold: new Date(trendChartTimestamp).getTime(),
      defaultValue: -1,
    });

    if (idx === -1) {
      return;
    }

    createRange({
      value: data[idx].date,
      color: am5.color(0x000000),
      root: rootRef.current as am5.Root,
      chart: chartRef.current,
      xAxis: xAxisRef.current,
    });
  }, [trendChartTimestamp, data]);

  return (
    <div ref={contentRef} style={{ height: "calc(100% - 61px - 53px)" }}>
      <XYChart ref={ref} exportSelector={exportId} styles={chartModalStyle} />
      <div id={`breadcrumb-container-${machine.id}-${uniqueId}`} />
    </div>
  );
};

export default TrendChart;
