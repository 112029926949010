import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { FontIcon, mergeStyles } from "@fluentui/react";
import { useEffect, useRef, useState } from "react";
import { useMeasure } from "react-use";

import { useUniqueId } from "../../../../Hooks";
import type { WaveformChartInterface } from "../../../analysis-raw-data/types";
import { format as signalFormatter } from "../../../common/utils/signalFormatter";
import type { XYChartRef } from "../../../../Components/common/XYChart";
import { XYChart } from "../../../../Components/common/XYChart";
import { createChartExport } from "../../../../Components/common/XYChart/export";
import { createDefaultTooltip } from "../../../../Components/common/XYChart/tooltip";

import DalogLogo from "../../../../assets/images/dalog-logo.svg";
import { watermarkLogos } from "../../constants/chartWatermarkLogos";
import useChartLabels from "../../hooks/useChartLabels";
import useSignalMetaData from "../../hooks/useSignalMetaData";
import ContextMenu from "../ContextMenu";
import { useDifference } from "../WaveformChartSection/DifferenceContext";
import { getRangeTimeDifference } from "./getRangeTimeDifference";

import { useTranslation } from "react-i18next";

const chartColor = "#2C529F";

const iconClass = mergeStyles({
  lineHeight: 1,
  fontSize: 17,
  marginRight: 10,
});

const X_AXIS_LABEL_KEY = "Time in (s)";

const WaveformChart = ({
  data,
  exportId,
  signal,
  isInModal,
  hideBreadcrumb,
  ...rest
}: WaveformChartInterface) => {
  const { t } = useTranslation();
  const ref = useRef<XYChartRef | null>(null);
  const [contentRef] = useMeasure<HTMLDivElement>();
  const rootRef = useRef<am5.Root | null>(null);
  const chartRef = useRef<am5xy.XYChart | null>(null);
  const xAxisRef = useRef<am5xy.ValueAxis<am5xy.AxisRenderer> | null>(null);
  const yAxisRef = useRef<am5xy.ValueAxis<am5xy.AxisRenderer> | null>(null);
  const seriesRef = useRef<am5xy.LineSeries | null>(null);
  const { corporation, company, project, machine } = useSignalMetaData(signal);
  const [cursorClickXValue, setCursorClickXValue] = useState(0);
  const [, setCursorClickYValue] = useState(0);
  const [difference, setDifference] = useState<number>(0);
  const [axisRangesLength, setAxisRangesLength] = useState<number>(0);
  const shouldCreateRasterRef = useRef(false);
  const shouldShowChartLabels = useChartLabels();
  const uniqueId = useUniqueId();

  const { differences, setLine1, setLine2, setRasterized, removeSignal } =
    useDifference();
  const { line1, line2, rasterized } =
    signal.id && differences[signal.id]
      ? differences[signal.id]
      : { line1: 0, line2: 0, rasterized: false };

  const chartModalStyle = isInModal
    ? {
        root: {
          height: "100%",
        },
        chart: {
          height: "100%",
        },
      }
    : {};

  useEffect(() => {
    if (!ref.current) return;

    const root = ref.current.root;
    const chart = ref.current.chart;

    // === Sending grid lines behind the chart series lines ===

    chart.gridContainer.toBack();

    // === Setting 0 basegrid line opacity ===
    const myTheme = am5.Theme.new(root);

    myTheme.rule("Grid", ["base"]).setAll({
      strokeOpacity: 0.1,
    });

    root.setThemes([myTheme]);

    // === X Axis ===

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        maxDeviation: 0,
        strictMinMax: true,
        marginTop: 16,
        extraTooltipPrecision: 2,
        tooltip: am5.Tooltip.new(root, {
          labelText: undefined,
          forceHidden: true,
          animationDuration: 0,
        }),
      })
    );

    // === X Axis Label ===

    xAxis.children.push(
      am5.Label.new(root, {
        text: t(X_AXIS_LABEL_KEY),
        x: am5.p50,
        centerX: am5.p50,
        fontSize: 10,
      })
    );

    // === X Axis grid opacity ===

    const xRenderer = xAxis.get("renderer");

    xRenderer.grid.template.setAll({
      strokeOpacity: 0.04,
    });

    xRenderer.labels.template.set("fontSize", 10);

    // === Y Axis ===

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
        maxDeviation: 0,
        strictMinMax: true,
        marginRight: 16,
        extraTooltipPrecision: 2,
        tooltip: am5.Tooltip.new(root, {
          labelText: undefined,
          forceHidden: true,
          animationDuration: 0,
        }),
      })
    );

    // === Y Axis Label ===

    yAxis.children.unshift(
      am5.Label.new(root, {
        ariaLabel: "yAxisLabel",
        ariaValueText: `${signal.unit}`,
        rotation: -90,
        text: `${signalFormatter(signal)} (${signal.unit})`,
        y: am5.p50,
        centerX: am5.p50,
        fill: am5.color(chartColor),
        fontSize: 10,
      })
    );

    // === Y Axis Label Color ===

    const yRenderer = yAxis.get("renderer");

    yRenderer.labels.template.setAll({
      fill: am5.color(chartColor),
      fontSize: 10,
    });

    yRenderer.grid.template.setAll({
      strokeOpacity: 0.04,
    });

    // === Series ===

    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        stroke: am5.color(chartColor),
        minBulletDistance: 10,
        tooltip: am5.Tooltip.new(root, {
          labelText: undefined,
          forceHidden: true,
          animationDuration: 0,
        }),
      })
    );

    series.bullets.push(function () {
      // create the circle first
      const circle = am5.Circle.new(root, {
        radius: 6,
        stroke: series.get("fill"),
        strokeWidth: 2,
        interactive: true, //required to trigger the state on hover
        fill: am5.color(0xffffff),
      });

      circle.states.create("hover", {
        scale: 2,
      });

      return am5.Bullet.new(root, {
        sprite: circle,
      });
    });

    // === Footer Label ===

    const breadcrumbRoot = am5.Root.new(
      `breadcrumb-container-${machine.id}-${uniqueId}`
    );

    if (!hideBreadcrumb) {
      const breadcrumbContainer = document.getElementById(
        `breadcrumb-container-${machine.id}-${uniqueId}`
      );

      const breadcrumb = breadcrumbRoot.container.children.push(
        am5.Label.new(breadcrumbRoot, {
          html: `
        <span style='font-size: 12px;color: #878583;'>
          ${corporation?.name}  〉 ${company?.name}  〉 ${project?.name} 〉 ${
            machine?.name
          }  〉 <strong style='color: #333;'>${signalFormatter(signal)}</strong>
        </span>`,
          width: am5.percent(100),
          fill: am5.color("#878583"),
          paddingTop: 26,
          exportable: true,
        })
      );

      breadcrumb.events.on("boundschanged", () => {
        if (breadcrumbContainer) {
          breadcrumbContainer.style.height = breadcrumb.height() + "px";
        }
      });
    }

    watermarkLogos.forEach((logoData) => {
      const logo = am5.Picture.new(root, {
        src: DalogLogo,
        height: logoData.height,
        opacity: logoData.opacity,
        x: logoData.x,
        y: logoData.y,
        centerX: logoData.centerX,
        centerY: logoData.centerY,
      });

      chart.plotContainer.children.push(logo);
    });

    // chart.children.push(
    //   am5.Label.new(root, {
    //     id: `${root.dom.id}-footer-label`,
    //     text: `
    //     ${corporation?.name}  〉 ${company?.name}  〉 ${project?.name}  〉 ${
    //       machine?.name
    //     } 〉 [bold #3B3A39]${signalFormatter(signal)}[/]
    //   `,
    //     x: 0,
    //     centerX: 0,
    //     fontSize: 12,
    //     fill: am5.color('#878583'),
    //     oversizedBehavior: 'wrap-no-break',
    //     textAlign: 'left',
    //   }),
    // );

    root.dom.addEventListener("contextmenu", (ev) => {
      ev.preventDefault();

      // === Get cursor position value

      const cursor = chart.get("cursor");

      const selectStatedX = cursor?.getPrivate("positionX") as number;
      const selectStatedY = cursor?.getPrivate("positionY") as number;

      const xValue = xAxis.positionToValue(xAxis.toAxisPosition(selectStatedX));
      const yValue = yAxis.positionToValue(yAxis.toAxisPosition(selectStatedY));

      setCursorClickXValue(xValue);
      setCursorClickYValue(yValue);
    });

    createDefaultTooltip({
      ref: ref.current,
      text: "s",
    });

    const cursor = chart.get("cursor");

    cursor?.set("snapToSeries", [series]);
    cursor?.set("snapToSeriesBy", "y!");
    cursor?.set("xAxis", xAxis);
    cursor?.set("yAxis", yAxis);

    createChartExport({
      root,
      chart,
      signal,
      selector: exportId,
      corporation: corporation?.name,
      company: company?.name,
      project: project?.name,
      machine: machine?.name,
    });

    rootRef.current = root;
    chartRef.current = chart;
    xAxisRef.current = xAxis;
    yAxisRef.current = yAxis;
    seriesRef.current = series;

    /// === Set line 1 and line 2 values. If they exist ===

    if (line1) setLineOne(true);
    if (line2) setLineTwo(true);

    return () => {
      ref.current = null;
      breadcrumbRoot.dispose();
    };
  }, []);

  useEffect(() => {
    if (!seriesRef?.current) return;

    const series = seriesRef?.current;

    if (Object.keys(data).length > 0) {
      series?.data.setAll(data.data);
      // series?.appear(500, 0);
    } else {
      series.data.setAll([]);
    }

    const modal = am5.Modal.new(rootRef.current as am5.Root, {
      content: `<div class="loader">${t("Loading...")}</div>
      <div class="loader-label">${t("Loading chart...")}</div>
      <div class="loader-sublabel">${t("loading time depends on the amount of data")}</div>
      `,
    });

    modal.getPrivate("wrapper").classList.add("chart-modal");
    modal.getPrivate("content").classList.add("chart-modal--content");

    series?.events.on("datavalidated", function (ev) {
      ev.target.data.length < 1 ? modal.open() : modal.close();
    });

    if (rasterized) createRaster();

    // return () => {
    //   ref.current = null;
    // };
  }, [data]);

  useEffect(() => {
    if (!seriesRef.current) return;
    const xAxis = seriesRef.current.get("xAxis");

    const xAxisLabel = xAxis.children.values.find((item) =>
      item.isType("Label")
    );

    xAxisLabel.set("text" as keyof am5.ISpriteSettings, t(X_AXIS_LABEL_KEY));
  }, [t]);

  // useEffect(() => {
  //   if (!ref?.current) return;
  //   const root = ref.current.root;

  //   setTimeout(() => {
  //     document.querySelectorAll(`#${root.dom.id} canvas`).forEach((canvas) => {
  //       canvas.classList.add('chart-canvas');
  //     });
  //   }, 1000);
  // }, []);

  const setLineOne = (isFromPrevious = false) => {
    if (!rootRef.current || !chartRef.current || !xAxisRef.current) return;

    const root = rootRef.current;
    const chart = chartRef.current;
    const xAxis = xAxisRef.current;
    const axisRanges = xAxis.axisRanges;

    if (axisRanges.length === 0) {
      const range = xAxis.createAxisRange(
        xAxis.makeDataItem({
          above: true,
        })
      );

      range.set("value", isFromPrevious ? line1 : cursorClickXValue);
      !isFromPrevious && setLine1(signal.id as string, cursorClickXValue);

      range.get("grid")?.setAll({
        strokeOpacity: 1,
        stroke: am5.color("#EB3F3D"),
        strokeWidth: 1,
        layer: 1,
      });

      // === Range button

      const rangeButton = am5.Button.new(root, {
        themeTags: ["resize", "horizontal"],
        width: 8,
        height: 16,
        dy: -20,
        background: am5.RoundedRectangle.new(root, {
          fill: am5.color("#EB3F3D"),
          stroke: am5.color("#EB3F3D"),
          centerY: am5.p50,
          centerX: am5.p50,
          cornerRadiusBL: 2,
          cornerRadiusBR: 2,
          cornerRadiusTL: 2,
          cornerRadiusTR: 2,
          dx: 0,
          dy: 0,
          layer: 100,
        }),
      });

      rangeButton.toFront();

      // === Restrict from being dragged vertically

      rangeButton.adapters.add("y", () => 0);

      // === Restrict from being dragged outside of plot

      rangeButton.adapters.add(
        "x",
        (x: number | am5.Percent | null | undefined) =>
          Math.max(0, Math.min(chart.plotContainer.width(), x as number))
      );

      rangeButton.adapters?.add("y", () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return -1 * chart.plotContainer.getPrivate("height");
      });

      // === Change range when x changes

      rangeButton.events.on("dragged", () => {
        if (!xAxisRef.current) return;
        const axisRanges = xAxisRef.current.axisRanges;
        const count = axisRanges.length;
        const x = rangeButton.x();
        const position = xAxis.toAxisPosition(x / chart.plotContainer.width());
        const value = xAxis.positionToValue(position);
        setLine1(signal.id as string, value);
        range.set("value", value);
        // === Calculate difference between ranges
        getRangeTimeDifference(axisRanges, setDifference);

        if (count >= 2 && shouldCreateRasterRef.current) {
          for (let i = 2; i < count; i++) {
            axisRanges.pop();
          }
          createRaster();
        }
      });

      getRangeTimeDifference(axisRanges, setDifference);

      range.set(
        "bullet",
        am5xy.AxisBullet.new(root, {
          location: 0,
          sprite: rangeButton,
        })
      );
    } else {
      const axisRanges = xAxisRef.current.axisRanges;
      const count = axisRanges.length;

      const range = axisRanges.getIndex(
        0
      ) as am5.DataItem<am5xy.IValueAxisDataItem>;
      range?.set("value", isFromPrevious ? line1 : cursorClickXValue);
      !isFromPrevious && setLine1(signal.id as string, cursorClickXValue);
      getRangeTimeDifference(axisRanges, setDifference);

      if (count >= 2 && shouldCreateRasterRef.current) {
        for (let i = 2; i < count; i++) {
          axisRanges.pop();
        }

        createRaster();
      }
    }
  };

  const setLineTwo = (isFromPrevious = false) => {
    if (!rootRef.current || !chartRef.current || !xAxisRef.current) return;

    const root = rootRef.current;
    const chart = chartRef.current;
    const xAxis = xAxisRef.current;
    const axisRanges = xAxis.axisRanges;

    //if axisRanges is not empty find the first range and set its value to the cursor position

    if (axisRanges.length === 1) {
      const range = xAxis.createAxisRange(xAxis.makeDataItem({}));

      range.set("value", isFromPrevious ? line2 : cursorClickXValue);
      !isFromPrevious && setLine2(signal.id as string, cursorClickXValue);

      range.get("grid")?.setAll({
        strokeOpacity: 1,
        stroke: am5.color("#EB3F3D"),
        strokeWidth: 1,
        layer: 1,
      });

      // === Range button

      const rangeButton = am5.Button.new(root, {
        themeTags: ["resize", "horizontal"],
        width: 8,
        height: 16,
        dy: -20,
        background: am5.RoundedRectangle.new(root, {
          fill: am5.color("#EB3F3D"),
          stroke: am5.color("#EB3F3D"),
          centerY: am5.p50,
          centerX: am5.p50,
          cornerRadiusBL: 2,
          cornerRadiusBR: 2,
          cornerRadiusTL: 2,
          cornerRadiusTR: 2,
          dx: 0,
          dy: 0,
          layer: 100,
        }),
      });

      rangeButton.toFront();

      // === Restrict from being dragged vertically

      rangeButton.adapters.add("y", () => 0);

      // === Restrict from being dragged outside of plot

      rangeButton.adapters.add(
        "x",
        (x: number | am5.Percent | null | undefined) =>
          Math.max(0, Math.min(chart.plotContainer.width(), x as number))
      );

      rangeButton.adapters?.add("y", () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return -1 * chart.plotContainer.getPrivate("height");
      });

      // === Change range when x changes

      rangeButton.events.on("dragged", () => {
        if (!xAxisRef.current) return;
        const axisRanges = xAxisRef.current.axisRanges;
        const count = axisRanges.length;

        const x = rangeButton.x();
        const position = xAxis.toAxisPosition(x / chart.plotContainer.width());
        const value = xAxis.positionToValue(position);
        setLine2(signal.id as string, value);
        range.set("value", value);

        // === Calculate difference between ranges

        getRangeTimeDifference(axisRanges, setDifference);

        if (count >= 2 && shouldCreateRasterRef.current) {
          for (let i = 2; i < count; i++) {
            axisRanges.pop();
          }

          createRaster();
        }
      });

      getRangeTimeDifference(axisRanges, setDifference);

      range.set(
        "bullet",
        am5xy.AxisBullet.new(root, {
          location: 0,
          sprite: rangeButton,
        })
      );
    } else {
      const axisRanges = xAxisRef.current.axisRanges;
      const count = axisRanges.length;

      const range = axisRanges.getIndex(
        1
      ) as am5.DataItem<am5xy.IValueAxisDataItem>;
      range?.set("value", isFromPrevious ? line2 : cursorClickXValue);
      !isFromPrevious && setLine2(signal.id as string, cursorClickXValue);

      getRangeTimeDifference(axisRanges, setDifference);

      if (count >= 2 && shouldCreateRasterRef.current) {
        for (let i = 2; i < count; i++) {
          axisRanges.pop();
        }

        createRaster();
      }
    }
  };

  const createRaster = () => {
    if (
      !xAxisRef.current ||
      !seriesRef?.current ||
      !rootRef.current ||
      !chartRef.current
    )
      return;

    const xAxis = xAxisRef.current;
    const axisRanges = xAxis.axisRanges;
    const series = seriesRef.current;
    const root = rootRef.current;
    const chart = chartRef.current;

    if (axisRanges.length < 2) return;

    const [rangeOne, rangeTwo] = [
      axisRanges.getIndex(0)?.get("value") as number,
      axisRanges.getIndex(1)?.get("value") as number,
    ].sort((a, b) => a - b);

    if (rangeOne && rangeTwo) {
      const difference = rangeTwo - rangeOne;

      const end = series.dataItems[series.dataItems.length - 1].get(
        "valueX"
      ) as number;

      let start = rangeTwo;

      while (start + difference <= end) {
        const range = xAxis.createAxisRange(xAxis.makeDataItem({}));

        range.set("value", start + difference);

        range.get("grid")?.setAll({
          strokeOpacity: 1,
          stroke: am5.color("#EB3F3D"),
          strokeWidth: 1.2,
          layer: 1,
        });

        //AxisBullet with label at the top of the chart
        const sidebandButton = am5.RoundedRectangle.new(root, {});

        sidebandButton.toFront();

        // === Range button styling

        sidebandButton.setAll({
          fill: am5.color("#EB3F3D"),
          stroke: am5.color("#EB3F3D"),
          opacity: 0.8,
          width: 4,
          height: 4,
          centerY: am5.p50,
          centerX: am5.p50,
          cornerRadiusBL: 2,
          cornerRadiusBR: 2,
          cornerRadiusTL: 2,
          cornerRadiusTR: 2,
          dx: 0,
          dy: -20,
          x: am5.p50,
          layer: 1,
        });

        sidebandButton.adapters?.add("y", () => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return -1 * chart.plotContainer.getPrivate("height");
        });

        range.set(
          "bullet",
          am5xy.AxisBullet.new(root, {
            sprite: sidebandButton,
          })
        );

        start += difference;
      }
    }

    setRasterized(signal.id as string, true);
    getRangeTimeDifference(axisRanges, setDifference);
    shouldCreateRasterRef.current = true;
  };

  const clearRangeLines = () => {
    if (!xAxisRef.current) return;

    const xAxis = xAxisRef.current;
    const axisRanges = xAxis.axisRanges;

    axisRanges.clear();

    getRangeTimeDifference(axisRanges, setDifference);
    setDifference(0);
    shouldCreateRasterRef.current = false;
    removeSignal(signal.id as string);
  };

  useEffect(() => {
    if (!xAxisRef.current) return;

    const xAxis = xAxisRef.current;
    const axisRanges = xAxis.axisRanges;

    setAxisRangesLength(axisRanges?.length);
  }, [xAxisRef.current?.axisRanges.length]);

  useEffect(() => {
    if (!rootRef.current || !chartRef.current || !xAxisRef.current) return;

    const root = rootRef.current;
    const xAxis = xAxisRef.current;
    const axisRanges = xAxis.axisRanges;

    if (axisRanges.length < 2) return;

    const range = axisRanges.getIndex(0);

    const label = range?.get("label");

    const hz = 1 / difference;

    label?.setAll({
      text:
        difference !== null
          ? `${parseFloat(difference?.toFixed(3))} s / ${parseFloat(
              hz.toFixed(2)
            )} hz`
          : "",
      fill: am5.color(0xffffff),
      fontSize: 10,
      layer: 100,
      background: am5.Rectangle.new(root, {
        fill: am5.color("#EB3F3D"),
      }),
    });
  }, [difference]);

  const contextMenuOptions = [
    {
      label: t("Set as line 1"),
      icon: "Remove",
      onClick: () => setLineOne(false),
      style: {},
      disabled: false,
    },
    {
      label: t("Set as line 2"),
      icon: "Remove",
      onClick: () => setLineTwo(false),
      style: {},
      disabled: false,
    },
    {
      label: t("Create raster from line 1 and 2"),
      icon: "GripperBarVertical",
      onClick: createRaster,
      style: {
        opacity: axisRangesLength < 2 || axisRangesLength > 2 ? 0.2 : 1,
      },
      disabled: false,
    },
    {
      label: t("Remove all lines"),
      icon: "Clear",
      onClick: clearRangeLines,
      style: { opacity: axisRangesLength === 0 ? 0.2 : 1 },
      disabled: axisRangesLength === 0,
    },
  ];

  return (
    <>
      {/* {difference} */}
      <ContextMenu root={ref?.current?.root as am5.Root}>
        {contextMenuOptions.map(
          ({ label, icon, style, disabled, onClick }, index) => (
            <button
              key={index}
              className="contextMenu--option"
              disabled={disabled}
              style={style}
              onClick={onClick}
            >
              <FontIcon
                aria-label={label}
                iconName={icon}
                className={iconClass}
                style={style}
              />
              {label}
            </button>
          )
        )}
      </ContextMenu>

      <div {...rest} ref={contentRef}>
        <XYChart
          ref={ref}
          exportSelector={exportId}
          isMinimized={!shouldShowChartLabels}
          styles={chartModalStyle}
        />
        {!hideBreadcrumb && (
          <div id={`breadcrumb-container-${machine.id}-${uniqueId}`} />
        )}
      </div>
    </>
  );
};

export default WaveformChart;
