/* eslint-disable @typescript-eslint/no-explicit-any */
import type * as am5xy from "@amcharts/amcharts5/xy";
import { useEffect, useRef, useState } from "react";

import TrendSignalIcon from "../../../../../../assets/svg/TrendSignalIcon";
import { TrendViewPlotSpinner } from "../../../../../../modules/analysis-trend-view/components/TrendViewPlotPlaceholders";
import type { XAxisDates } from "../../../config";
import useDriveLoadStore from "../../../hooks/useDriveLoadStore";
import { useSignalsDataPoints } from "../../../hooks/useSignalsDataPoints";
import type { ResponseSimplifiedSignal } from "../../../../../../types";
import NoData from "../../../../../common/NoData/NoData";
import type {
  XYChartRef,
  XYChartStyles,
} from "../../../../../common/XYChart/XYChart";

import MopChartContainer from "../MopChartContainer";
import SignalsTrendChart from "./SignalsTrendChart";
import { useTranslation } from "react-i18next";

type MopTrendChartProps = React.HTMLAttributes<HTMLDivElement> & {
  machineId: string;
  signals: ResponseSimplifiedSignal[];
  selectedSignals: ResponseSimplifiedSignal[];
  onSelectedChange: (signals: ResponseSimplifiedSignal[]) => void;
  chartStyles?: XYChartStyles;
};

const MopTrendChart = ({
  machineId,
  signals,
  selectedSignals,
  onSelectedChange,
  chartStyles,
  ...rest
}: MopTrendChartProps) => {
  const { t } = useTranslation();
  const ref = useRef<XYChartRef | null>(null);
  const [refresh, setRefresh] = useState<boolean>(true);
  const {
    period,
    isConnectedTimeAxis,
    selectedTableEvent,
    torqueChartDates,
    trendChartDates,
    updateDriveLoadStore,
  } = useDriveLoadStore((store: any) => ({
    period: store.period,
    isConnectedTimeAxis: store.isConnectedTimeAxis,
    selectedTableEvent: store.selectedTableEvent,
    torqueChartDates: store.torqueChartDates,
    trendChartDates: store.trendChartDates,
    updateDriveLoadStore: store.updateDriveLoadStore,
  }));

  const { data, isLoading } = useSignalsDataPoints(
    selectedSignals.filter((s) => s.id),
    period
  );

  // Refreshes the chart.
  useEffect(() => {
    setRefresh(true);
  }, [data]);

  // Zooms this chart according to the torque chart dates (if connect time axis is true).
  useEffect(() => {
    if (
      !ref?.current ||
      !torqueChartDates?.start ||
      !torqueChartDates?.end ||
      !isConnectedTimeAxis
    ) {
      return;
    }

    const xAxis = ref?.current?.chart.xAxes.values.at(
      0
    ) as am5xy.DateAxis<am5xy.AxisRenderer>;
    if (!xAxis) {
      return;
    }

    const start = xAxis.getPrivate("min");
    const end = xAxis.getPrivate("max");
    if (!start || !end) {
      return;
    }

    const startDate = new Date(start);
    const endDate = new Date(end);
    if (
      torqueChartDates.start <= startDate ||
      torqueChartDates.end >= endDate
    ) {
      xAxis.zoomToDates(startDate, endDate);
      return;
    }

    xAxis.zoomToDates(torqueChartDates.start, torqueChartDates.end);
  }, [torqueChartDates.start, torqueChartDates.end]);

  // Subscribes / unsubscribes to chart events
  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const xAxis = ref.current.chart.xAxes.values.at(
      0
    ) as am5xy.DateAxis<am5xy.AxisRenderer>;
    if (!xAxis) {
      return;
    }

    // Subscribes to events.
    const disposer = xAxis.on("end", onZoomEnd);

    // Unsubscribes from events
    return () => {
      disposer && !disposer.isDisposed() && disposer.dispose();
    };
  });

  // Handlers
  const onDataValidated = () => {
    if (!isConnectedTimeAxis) {
      return;
    }

    const xAxis = ref?.current?.chart.xAxes.values.at(
      0
    ) as am5xy.DateAxis<am5xy.AxisRenderer>;
    if (!xAxis) {
      return;
    }

    const start = xAxis.getPrivate("min");
    const end = xAxis.getPrivate("max");

    if (!start || !end) {
      return;
    }

    const result: XAxisDates = {
      start: new Date(start),
      end: new Date(end),
    };

    updateDriveLoadStore({ trendChartDates: result });
  };

  const onZoomEnd = () => {
    if (!isConnectedTimeAxis) {
      return;
    }

    const xAxis = ref?.current?.chart.xAxes.values.at(
      0
    ) as am5xy.DateAxis<am5xy.AxisRenderer>;
    if (!xAxis) {
      return;
    }

    const start = xAxis.getPrivate("selectionMinFinal");
    const end = xAxis.getPrivate("selectionMaxFinal");

    if (!start || !end) {
      return;
    }

    const result: XAxisDates = { start: new Date(start), end: new Date(end) };
    trendChartDates !== result &&
      updateDriveLoadStore({ trendChartDates: result });
  };

  let content: JSX.Element | null = null;
  if (isLoading)
    content = (
      <TrendViewPlotSpinner
        text={t("Fetching new data...")}
        styles={{ root: { height: "100%", width: "100%", boxShadow: "none" } }}
      />
    );
  else if (signals?.length === 0) {
    content = <NoData className="m-a" text={t("No signals selected")} />;
  } else if (data?.size === 0) {
    content = <NoData className="m-a" />;
  } else {
    content = (
      <SignalsTrendChart
        {...rest}
        ref={ref}
        key={`signals-trend-chart-${machineId}`}
        className="grouped-chart-container"
        data={data}
        refresh={refresh}
        selectedTableEvent={selectedTableEvent}
        styles={chartStyles}
        onDrawFinished={() => setRefresh(false)}
        onDataValidated={onDataValidated}
      />
    );
  }

  return (
    <MopChartContainer
      key={`signals-trend-chart-checkboxes-${machineId}`}
      signals={signals}
      selectedSignals={selectedSignals}
      icon={TrendSignalIcon}
      chart={ref?.current?.chart}
      onSelectedChanged={onSelectedChange}
    >
      {content}
    </MopChartContainer>
  );
};

export default MopTrendChart;
