import { Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";

import { useGatewayDetails } from "../../hooks/useGatewayDetails";
import { useWSUrlParams } from "../../hooks/useWSUrlParams";
import GatewayContent from "./GatewayContent";
import { NoData } from "../../../common/NoData";
import { useTranslation } from "react-i18next";

const Sensor = () => {
  const { t } = useTranslation();
  const { serialNumber } = useWSUrlParams();
  const { gateway, isLoading } = useGatewayDetails({ serialNumber });

  return (
    <>
      {isLoading ? (
        <Spinner style={{ marginTop: 30 }} size={SpinnerSize.large} />
      ) : (
        <>
          {gateway ? (
            <GatewayContent key={serialNumber} gateway={gateway} />
          ) : (
            <NoData
              style={{ marginTop: 30 }}
              text={t("The gateway was not found")}
            />
          )}
        </>
      )}
    </>
  );
};

export default Sensor;
