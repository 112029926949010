import {
  useIsMutating,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { forEach, isEmpty } from "lodash-es";

import { queryClient } from "../../../modules/core";
import { getApiClient } from "../../../modules/core/apiClient/useApiStore";
import { notification } from "../../common/Notification";

import type {
  MeasurementSettingId,
  TimedMeasurementType,
  UpdateMeasurementSettingsRequestMultiple,
  UpdateTimedSettingsRequest,
  UpdateTimedSettingsRequestMultiple,
  UseSensorSettingsProps,
} from "../pages/Sensor/types";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const TIMED_SETTINGS = [
  "TimedVibration1",
  "TimedVibration2",
  "TimedVibration3",
];

interface UseClearSensorStateProps {
  sensorNodeId: string;
}

export const useClearSensorState = ({
  sensorNodeId,
}: UseClearSensorStateProps) => {
  const {
    mutate: clearState,
    isLoading,
    isError,
  } = useMutation(() =>
    getApiClient().post(`/dws/v1/sensors/${sensorNodeId}/state/clear`)
  );

  return {
    clearState,
    isLoading,
    isError,
  };
};

export const useSensorTriggers = ({
  sensorNodeId,
  options,
}: UseSensorSettingsProps) => {
  const { t } = useTranslation();
  const { data, isLoading, isError, refetch } = useQuery(
    [`ws-sensor-triggers-${sensorNodeId}`],
    async () => {
      try {
        const response = await getApiClient().get(
          `/dws/v1/sensors/${sensorNodeId}/settings/timed`
        );
        const { timedMeasurementSettings } = response.data;

        // Transform the response to match the expected format
        const transformedData = TIMED_SETTINGS.map((setting) => ({
          enabled: timedMeasurementSettings[setting]?.enabled ?? false,
          intervalInMinutes: `${timedMeasurementSettings[setting]?.intervalInMinutes || ""}`,
          enabledMeasurementSettings:
            timedMeasurementSettings[setting]?.enabledMeasurementSettings || [],
        }));

        return transformedData;
      } catch (error: any) {
        notification.error(
          t(`Error fetching timed settings: {{errorMessage}}`, {
            errorMessage: error.message,
          })
        );
        throw error;
      }
    },
    options
  );

  const syncUpdatedData = (updatedData: any) => {
    queryClient.setQueryData(
      [`ws-sensor-triggers-${sensorNodeId}`],
      (data: any) => {
        return data.map(({ status }: any, idx: number) => {
          const newValue = {
            ...updatedData[idx],
            intervalInMinutes:
              updatedData[idx].intervalInMinutes ||
              updatedData[idx].intervalInMinutes === 0
                ? `${updatedData[idx].intervalInMinutes}`
                : "",
          };
          return {
            status,
            value: { ...newValue },
          };
        });
      }
    );
  };

  return {
    refetch,
    syncUpdatedData,
    data,
    isLoading,
    isError,
  };
};

export const useSensorTriggersUpdate = ({ sensorNodeId }: any) => {
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useMutation({
    mutationKey: [`ws-sensor-update-triggers-${sensorNodeId}`],
    mutationFn: ({ sensorNodeId, data }: any) => {
      const promises: any = [];
      forEach(data, (item, timeId) => {
        if (item.shouldBeUpdated) {
          const itemData = {
            enabled: item.enabled,
            enabledMeasurementSettings: item.enabledMeasurementSettings,
            ...(item.intervalInMinutes
              ? { intervalInMinutes: Number(item.intervalInMinutes) }
              : {}),
          };
          promises.push({
            data: { ...itemData },
            timeId,
            promiseUrl: `/dws/v1/sensors/${sensorNodeId}/settings/timed/${timeId}`,
          });
        }
      });

      return Promise.allSettled(
        promises.map(({ promiseUrl, data, timeId }: any) =>
          getApiClient()
            .put(promiseUrl, data)
            .then(({ data }) => data)
            .catch((error) =>
              notification.error(
                t(`Error in {{timeId}}: {{errorMessage}}`, {
                  timeId,
                  errorMessage: error.message,
                })
              )
            )
        )
      );
    },
  });

  const isLoadingByKey = useIsMutating({
    mutationKey: [`ws-sensor-update-triggers-${sensorNodeId}`],
  });

  return {
    updateSensorTriggers: mutateAsync,
    isLoading: isLoading || !!isLoadingByKey,
  };
};

export const useSensorMultipleTriggersUpdate = () => {
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useMutation({
    mutationKey: [`ws-sensor-update-triggers-multiple`],
    mutationFn: ({ sensorNodeIds, data }: any) => {
      const promises: any = [];
      forEach(data, (item, timeId) => {
        const itemData: UpdateTimedSettingsRequestMultiple = {
          sensorNodeIds: sensorNodeIds,
          timedMeasurementType: timeId as TimedMeasurementType,
          timedMeasurementSettings: {
            enabled: item.enabled,
            enabledMeasurementSettings: item.enabledMeasurementSettings,
            ...(item.intervalInMinutes
              ? { intervalInMinutes: Number(item.intervalInMinutes) }
              : {}),
          } as UpdateTimedSettingsRequest,
        };
        promises.push({
          data: { ...itemData },
        });
      });

      return Promise.allSettled(
        promises.map(({ data }: any) =>
          getApiClient()
            .post(`/dws/v1/sensors/broadcast/settings/timed`, data)
            .then(({ data }) => data)
            .catch((error) =>
              notification.error(
                t(`Error in {{timedMeasurementType}}: {{errorMessage}}`, {
                  timedMeasurementType: data.timedMeasurementType,
                  errorMessage: error.message,
                })
              )
            )
        )
      );
    },
  });

  const isLoadingByKey = useIsMutating({
    mutationKey: [`ws-sensor-update-triggers-multiple`],
  });

  return {
    updateMultipleSensorTriggers: mutateAsync,
    isLoading: isLoading || !!isLoadingByKey,
  };
};

export const useMeasurementSettings = (
  sensorNodeId: string,
  measurementSet: string
) => {
  const { data, isError, isLoading, refetch } = useQuery(
    [`ws-sensor-settings-${sensorNodeId}-${measurementSet}`],
    async () => {
      const response = await getApiClient().get(
        `/dws/v1/sensors/${sensorNodeId}/settings/measurements/${measurementSet}`
      );

      const settingData = response.data || {};
      return {
        gRange: settingData.gRange || "",
        dataAxis: settingData.dataAxis || "",
        dataSampleCount: settingData.dataSampleCount?.toString() || "",
        selectedCalculations: settingData.selectedCalculations || [],
      };
    },
    {
      staleTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  return {
    data,
    isError,
    isLoading,
    refetch,
  };
};

export const useSensorSettings = ({
  activeSettings = [],
  sensorNodeId,
  options = {},
  settingsToLoad = [],
}: UseSensorSettingsProps) => {
  const queryClient = useQueryClient();
  const filteredSettings = activeSettings.filter((setting: any) =>
    settingsToLoad.includes(setting)
  );

  // Create individual queries for each measurementSet
  const queries = useQueries({
    queries: filteredSettings.map((measurementSet: any) => ({
      queryKey: [`ws-sensor-settings-${sensorNodeId}-${measurementSet}`],
      queryFn: async () => {
        try {
          const response = await getApiClient().get(
            `/dws/v1/sensors/${sensorNodeId}/settings/measurements/${measurementSet}`
          );
          const settingData = response.data || {};
          return {
            gRange: settingData.gRange || "",
            dataAxis: settingData.dataAxis || "",
            dataSampleCount: settingData.dataSampleCount?.toString() || "",
            selectedCalculations: settingData.selectedCalculations || [],
          };
        } catch (error) {
          return {
            gRange: "",
            dataAxis: "",
            dataSampleCount: "",
            selectedCalculations: [],
          };
        }
      },
      ...options,
      staleTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: true,
    })),
  });

  const data = useMemo(() => {
    const results: Record<string, any> = {};
    queries.forEach((query, index) => {
      if (query?.data) {
        results[filteredSettings[index]] = query.data;
      }
    });
    return results;
  }, [queries, filteredSettings]);

  const isError = useMemo(
    () => queries.some((query) => query?.isError),
    [queries]
  );

  const refetch = useCallback(() => {
    queries.forEach((query) => query?.refetch?.());
  }, [queries]);

  const syncUpdatedData = (updatedData: Record<string, any>) => {
    Object.entries(updatedData).forEach(([measurementSet, data]) => {
      const formattedData = {
        ...data,
        dataSampleCount: data.dataSampleCount?.toString() || "",
      };
      queryClient.setQueryData(
        [`ws-sensor-settings-${sensorNodeId}-${measurementSet}`],
        formattedData
      );
    });
  };

  return {
    refetch,
    syncUpdatedData,
    data,
    isError,
  };
};

export const prepareSensorSettingsData = (data: any) => {
  if (!data || isEmpty(data)) return {};
  const measurement: any = [];
  forEach(data, (dataItem = {}) => {
    const value: any = dataItem?.value || {};
    measurement.push({
      gRange: value.gRange || "",
      dataAxis: value.dataAxis || "",
      dataSampleCount:
        value.dataSampleCount || value.dataSampleCount === 0
          ? `${value.dataSampleCount}`
          : "",
      selectedCalculations: value.selectedCalculations || [],
    });
  });
  return measurement;
};

export const useSensorSettingsUpdate = ({
  serialNumber,
  sensorNodeId,
}: any) => {
  const { mutateAsync, isLoading } = useMutation({
    mutationKey: [`ws-sensor-update-settings-${serialNumber}-${sensorNodeId}`],
    mutationFn: ({ sensorNodeId, data }: any) => {
      const promises: any = [];
      forEach(data, (item, measurementId) => {
        if (item.shouldBeUpdated) {
          const itemData = {
            gRange: item.gRange,
            dataAxis: item.dataAxis,
            selectedCalculations: item.selectedCalculations,
            enabledMeasurementSettings: item.enabledMeasurementSettings,
            ...(item.dataSampleCount
              ? { dataSampleCount: Number(item.dataSampleCount) }
              : {}),
          };
          console.log({ data, item, measurementId });
          promises.push({
            data: { ...itemData },
            measurementId,
            promiseUrl: `/dws/v1/sensors/${sensorNodeId}/settings/measurements/${measurementId}`,
          });
        }
      });

      return Promise.allSettled(
        promises.map(({ promiseUrl, data, measurementId }: any) =>
          getApiClient()
            .put(promiseUrl, data)
            .then(({ data }) => data)
            .catch((error) =>
              notification.error(`Error in ${measurementId}: ${error.message}`)
            )
        )
      );
    },
  });

  const isLoadingByKey = useIsMutating({
    mutationKey: [`ws-sensor-update-settings-${serialNumber}-${sensorNodeId}`],
  });

  return {
    updateSensorSettings: mutateAsync,
    isLoading: isLoading || !!isLoadingByKey,
  };
};

export const useSensorMultipleSettingsUpdate = () => {
  const { mutateAsync, isLoading } = useMutation({
    mutationKey: [`ws-sensor-update-settings-multiple`],
    mutationFn: ({ sensorNodeIds, data }: any) => {
      const promises: any = [];
      forEach(data, (item, measurementId) => {
        if (item.shouldBeUpdated) {
          const itemData: UpdateMeasurementSettingsRequestMultiple = {
            sensorNodeIds: sensorNodeIds,
            measurementId: measurementId as MeasurementSettingId,
            measurementSettings: {
              gRange: item.gRange === "" ? undefined : item.gRange,
              dataAxis: item.dataAxis === "" ? undefined : item.dataAxis,
              selectedCalculations: item.selectedCalculations,
              ...(item.dataSampleCount
                ? { dataSampleCount: Number(item.dataSampleCount) }
                : {}),
            },
          };
          promises.push({
            data: { ...itemData },
          });
        }
      });

      return Promise.allSettled(
        promises.map(({ data }: any) =>
          getApiClient()
            .post(`/dws/v1/sensors/broadcast/settings/measurements`, data)
            .then(({ data }) => data)
            .catch((error) =>
              notification.error(
                `Error in ${data.measurementId}: ${error.message}`
              )
            )
        )
      );
    },
  });

  const isLoadingByKey = useIsMutating({
    mutationKey: [`ws-sensor-update-settings-multiple`],
  });

  return {
    updateMultipleSensorSettings: mutateAsync,
    isLoading: isLoading || !!isLoadingByKey,
  };
};
