/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { IObjectWithKey, IStackStyles } from "@fluentui/react";
import { ActionButton, ComboBox } from "@fluentui/react";
import classNames from "classnames";
import { isEmpty } from "lodash-es";
import React, { useCallback, useEffect, useMemo, useRef } from "react";

import ClearIcon from "../../../../assets/svg/ClearIcon";
import {
  useLocationSearch,
  useMachineCVBreadcrumb,
  useSearch,
} from "../../../../Hooks";
import useSummaryStore from "../hooks/useSummaryStore";
import { useTrendSignalsList } from "../hooks/useTrendSignalsList";
import { handleComboBoxChange, getStatusOptions } from "./methods";
import type { ResponseSimplifiedSignal } from "../../../../types";
import { onRenderLabel } from "../../../common/CommandBar/methods";
import NoData from "../../../common/NoData/NoData";
import { Spin } from "../../../common/Spin";

import type { SectionCommandBarItem } from "../SectionContainer";
import SectionContainer from "../SectionContainer";
import { getColumns } from "./columns";
import type { TableRef } from "./Table";
import CustomTable from "./Table";

import { useTranslation } from "react-i18next";

type CommandBarHeaderProps = {
  signalsStatus: any;
  onSignalStatusChange: (signalsStatus: any) => void;
  onSelectionClear: () => void;
  t?: any;
};

const noDataStyles: IStackStyles = {
  root: {
    marginTop: 16,
    marginBottom: 32,
  },
};

const getCommandBarItems = ({
  signalsStatus,
  onSignalStatusChange,
  onSelectionClear,
  t,
}: CommandBarHeaderProps): SectionCommandBarItem[] => {
  const result: SectionCommandBarItem[] = [
    {
      key: "signals-status-combobox",
      onRender: () => (
        <ComboBox
          style={{ marginRight: 8 }}
          allowFreeform={false}
          allowFreeInput={false}
          className={classNames(
            "layout-dropdown secondary-dropdown combobox-dropdown"
          )}
          calloutProps={{ calloutWidth: undefined, calloutMinWidth: 200 }}
          selectedKey={signalsStatus}
          options={getStatusOptions(t)}
          multiSelect={true}
          onChange={(_, option: any) => {
            handleComboBoxChange({
              selectedOption: option,
              selectedOptions: signalsStatus,
              options: getStatusOptions(t),
              onChange: onSignalStatusChange,
            });
          }}
          onRenderLabel={() =>
            onRenderLabel({
              label: t("Status"),
              icon: "Info",
            })
          }
        />
      ),
    },
    {
      key: "clear-selection-button",
      onRender: () => (
        <ActionButton
          styles={{
            root: [
              { minWidth: 125, marginRight: 16 },
              {
                ".ms-Button-flexContainer": {
                  justifyContent: "space-between",
                },
              },
            ],
          }}
          onClick={onSelectionClear}
        >
          <ClearIcon />
          {t("Clear selection")}
        </ActionButton>
      ),
    },
  ];

  return result;
};

export const SignalsSection = ({
  ...rest
}: React.HTMLAttributes<HTMLElement>) => {
  const { t } = useTranslation();
  const [{ id }, ,] = useLocationSearch();
  const { signals, isLoading, isError } = useTrendSignalsList(id as string);
  const { data, handleSearch } = useSearch({
    data: signals.map((signal: ResponseSimplifiedSignal) => ({
      ...signal,
      key: signal?.id,
    })),
    keys: ["name", "signalDescription", "condition", "sensorNo"],
  });
  const tableRef = useRef<TableRef>(null);

  const { data: breadcrumbData } = useMachineCVBreadcrumb({
    machineId: id as string,
    options: { enabled: !!id, retry: 0 },
  });

  useEffect(() => {
    setSelectedSignals([]);
    tableRef.current?.clearSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbData]);

  const {
    selectedSignals,
    isGroupedEverything,
    signalsStatus,
    setSignalsStatus,
    updateOperations,
    setSelectedSignals,
    removeFromSelectedSignals,
    addToSelectedSignals,
  } = useSummaryStore((state) => ({
    selectedSignals: state.selectedSignals,
    signalsStatus: state.signalsStatus,
    isGroupedEverything: state.isGroupedEverything,
    setSignalsStatus: state.setSignalsStatus,
    updateOperations: state.updateOperations,
    setSelectedSignals: state.setSelectedSignals,
    removeFromSelectedSignals: state.removeFromSelectedSignals,
    addToSelectedSignals: state.addToSelectedSignals,
  }));

  // Use useMemo to create filtered items
  const items = useMemo(() => {
    return signalsStatus.includes("selectAll")
      ? data
      : data.filter((e) => signalsStatus.includes(e.condition));
  }, [data, signalsStatus]);

  useEffect(() => {
    updateOperations({ loadingSignals: isLoading });
  }, [isLoading, updateOperations]);

  useEffect(() => {
    updateOperations({ loadingSignals: isLoading });
  }, [isLoading]);

  useEffect(() => {
    const operationSignal = signals.filter((signal: any) => {
      return signal.name === "operation_hours_OP";
    })[0];
    updateOperations({ signal: operationSignal });
  }, [signals]);

  // Handlers
  const onSelectionClear = useCallback(() => {
    setSelectedSignals([]);
    tableRef.current?.clearSelection();
  }, []);

  const handleSelectionChanged = useCallback(
    (newSelection: any[], selectableItems: any[]) => {
      removeFromSelectedSignals(selectableItems as IObjectWithKey[]);
      addToSelectedSignals(newSelection);
    },
    []
  );

  const onSearchValueChanged = (_: any, newValue?: string) => {
    handleSearch(newValue ?? "");
    selectedSignals.length > 0 &&
      tableRef.current?.selectItems(selectedSignals);
  };

  const onSignalStatusChange = (signalsStatus: any) => {
    setSignalsStatus(signalsStatus);
  };

  useEffect(() => {
    selectedSignals.length > 0 &&
      tableRef.current?.selectItems(selectedSignals);
  }, [signalsStatus]);

  let content: JSX.Element | null = null;
  if (isLoading) {
    content = <Spin />;
  } else if (isError) {
    content = (
      <NoData
        className="table-no-data"
        text={t("Data error")}
        styles={noDataStyles}
      />
    );
  } else {
    content = (
      <CustomTable
        ref={tableRef}
        persistOpts={{
          key: "table-signalSelection",
          version: 2,
        }}
        items={items}
        columns={getColumns(t)}
        isGroupedEverything={isGroupedEverything}
        onSelectionChanged={handleSelectionChanged}
        // onFiltersApplied={(filtered) => {
        //   console.log({ filtered_items: filtered, selectedSignals });
        // }}
      />
    );
  }

  return (
    <SectionContainer
      title={t("Signals")}
      commandBarItems={getCommandBarItems({
        signalsStatus,
        onSignalStatusChange,
        onSelectionClear,
        t,
      })}
      isLoading={isLoading}
      noData={isEmpty(signals)}
      searchBoxProps={{
        hidden: isEmpty(signals),
        onChange: onSearchValueChanged,
      }}
      {...rest}
    >
      {content}
    </SectionContainer>
  );
};
