import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

import { YEARLY_EVENTS } from "../components/Events/config";
import {
  DropdownOption,
  getPeriodDropdownOptions,
  getViewDropdownOptions,
} from "../config";
import { DriveLoadEnriched } from "../../../../types";
import { t } from "i18next";

interface EventData {
  activeTab: string;
  year: any;
  month: any;
}

interface DateRange {
  start?: Date;
  end?: Date;
}

export interface DriveLoadStore {
  period: DropdownOption;
  colorChartView: DropdownOption;
  driveLoad: DriveLoadEnriched;
  averageTrend: boolean;
  showDates: boolean;
  eventsTableLoading: boolean;
  eventsTableData: any[];
  selectedTableEvent: any;
  selectedFilterEventsNames: any[];
  events: EventData;
  isConnectedTimeAxis: boolean;
  torqueChartDates: DateRange;
  trendChartDates: DateRange;
  getStoreValue: (key: keyof DriveLoadStore) => any;
  updateDriveLoadStore: (
    updatedStoreProperties: Partial<DriveLoadStore>
  ) => void;
}

export const driveLoadStoreInitialValue: Omit<
  DriveLoadStore,
  "getStoreValue" | "updateDriveLoadStore"
> = {
  period: getPeriodDropdownOptions(t)[4],
  colorChartView: getViewDropdownOptions(t)[2],
  driveLoad: {} as DriveLoadEnriched,
  averageTrend: false,
  showDates: false,
  eventsTableLoading: false,
  eventsTableData: [],
  selectedTableEvent: {},
  selectedFilterEventsNames: [],
  events: {
    activeTab: YEARLY_EVENTS,
    year: {},
    month: {},
  },
  isConnectedTimeAxis: false,
  torqueChartDates: {
    start: undefined,
    end: undefined,
  },
  trendChartDates: {
    start: undefined,
    end: undefined,
  },
};

const useDriveLoadStore = createWithEqualityFn<DriveLoadStore>(
  (set, get) => ({
    ...driveLoadStoreInitialValue,
    getStoreValue: (key) => get()[key],
    updateDriveLoadStore: (updatedStoreProperties = {}) =>
      set((store) => ({
        ...store,
        ...updatedStoreProperties,
      })),
  }),
  shallow
);

export default useDriveLoadStore;
