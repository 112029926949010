import "./MachineCard.scss";

import { type CSSProperties, useEffect, useState } from "react";

import { useSearch } from "../../../../../Hooks";
import type { SizeProps } from "../../../../../modules/common/components/Dashboards/ShowMoreGrid";
import { ShowMoreGrid } from "../../../../../modules/common/components/Dashboards/ShowMoreGrid";
import type { ConditionStatus } from "../../../../../types";
import type { CommandBarItemProps } from "../../../../common/CommandBar";
import CommandBar, { CommandBarItemType } from "../../../../common/CommandBar";
import { Spin } from "../../../../common/Spin";

import { MachineCard } from "./MachineCard";
import { useTranslation } from "react-i18next";

export type MachineCardType = {
  id: string;
  title: string;
  subtitle?: string;
  iconId?: string;
  query: string;
  condition: ConditionStatus;
  isBookmarked?: boolean;
};

type MachinesProps = {
  items: MachineCardType[];
  isLoading: boolean;
  sizeProps?: any;
};

export const machinesGridStyle: CSSProperties = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(450px, 1fr))",
  gap: 16,
  marginTop: 10,
};

const containerTitleStyle: CSSProperties = {
  fontWeight: 600,
  fontSize: 16,
  lineHeight: "22px",
  alignSelf: "center",
};

export const Machines = ({ items, isLoading, sizeProps }: MachinesProps) => {
  const { t } = useTranslation();

  const headerItems: CommandBarItemProps[] = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <div style={containerTitleStyle}>{t("Machines")}</div>,
    },
  ];

  const { data, handleSearch } = useSearch<MachineCardType>({
    data: items,
    keys: ["title", "subtitle", "condition"],
  });

  const [currentSizeProps, setCurrentSizeProps] = useState<SizeProps>(
    sizeProps ?? { size: 6, increment: 3 }
  );

  // Updates the size props
  useEffect(() => {
    if (!sizeProps) {
      return;
    }

    setCurrentSizeProps(sizeProps);
  }, [sizeProps]);

  const content = isLoading ? (
    <Spin />
  ) : (
    <ShowMoreGrid
      data={data}
      sizeProps={currentSizeProps}
      renderComponent={(data) => (
        <div style={machinesGridStyle}>
          {data.map((item) => (
            <MachineCard key={item.id} data={item} />
          ))}
        </div>
      )}
    />
  );

  return (
    <div
      className="machines-container"
      style={{
        marginBottom: 80,
      }}
    >
      <CommandBar items={headerItems} onSearch={handleSearch} />
      {content}
    </div>
  );
};
