import { useMemo, useContext, useState } from "react";

import {
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
} from "../common/Form";

import { DialogSize } from "../common/Dialog";

import { IDialogProps, DialogType, Text } from "@fluentui/react";
import { z } from "zod";
import { LocalAccountInfo } from "./context";
import { UserUpdate } from "../UsersRBCA/models";
import { updateUserRBAC } from "../UsersRBCA/api";
import type { FieldError } from "react-hook-form";
import FormDialog from "../Generic/FormDialog";
import FormItemRow from "../Generic/FormItemRow";
import { WithLoadingPanelHOC } from "../Generic/HOCs";
import { useZodWatch } from "../../Hooks";
import { useTranslation } from "react-i18next";

type EditDialogProps = IDialogProps & {
  onSuccess: (hasError: boolean, displayName: string) => void;
  onClose: () => void;
};

type FormData = {
  lastName: string;
  firstName: string;
  jobTitle?: string;
  companyName?: string;
  streetAddress?: string;
  department?: string;
  city?: string;
  stateOrProvince?: string;
  zipOrPostalCode?: string;
  countryOrRegion?: string;
  mobilePhone?: string;
};

const getFormItemProps: (t) => FormItemProps[] = (t) => [
  {
    name: "lastName",
    type: FormItemType.TextField,
    groupProps: { label: t("Last Name *") },
  },
  {
    name: "firstName",
    type: FormItemType.TextField,
    groupProps: { label: t("First Name *") },
  },
  {
    name: "jobTitle",
    type: FormItemType.TextField,
    groupProps: { label: t("Job Title") },
  },
  {
    name: "companyName",
    type: FormItemType.TextField,
    groupProps: { label: t("Company Name") },
  },
  {
    name: "streetAddress",
    type: FormItemType.TextField,
    groupProps: { label: t("Street") },
  },
  {
    name: "department",
    type: FormItemType.TextField,
    groupProps: { label: t("Department") },
  },
  {
    name: "city",
    type: FormItemType.TextField,
    groupProps: { label: t("City") },
  },
  {
    name: "stateOrProvince",
    type: FormItemType.TextField,
    groupProps: { label: t("State") },
  },
  {
    name: "zipOrPostalCode",
    type: FormItemType.TextField,
    groupProps: { label: t("Zip") },
  },
  {
    name: "countryOrRegion",
    type: FormItemType.TextField,
    groupProps: { label: t("Country") },
  },
  {
    name: "mobilePhone",
    type: FormItemType.TextField,
    groupProps: { label: t("Mobile") },
  },
];

const getSchema = (t) =>
  z.object({
    lastName: z.string().min(1, { message: t("This field is required") }),
    firstName: z.string().min(1, { message: t("This field is required") }),
    jobTitle: z.string().optional(),
    companyName: z.string().optional(),
    department: z.string().optional(),
    streetAddress: z.string().optional(),
    city: z.string().optional(),
    stateOrProvince: z.string().optional(),
    zipOrPostalCode: z.string().optional(),
    countryOrRegion: z.string().optional(),
    mobilePhone: z
      .union([
        z.string().length(0, { message: t("Invalid number") }),
        z.string().regex(/^[+(\s.\-/\d)]{5,30}/),
      ])
      .optional()
      .transform((e) => (e === "" ? undefined : e)),
  });

const EditUserProfileDialog = ({ onSuccess, onClose }: EditDialogProps) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getSchema(t), [t]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const myAccount = useContext(LocalAccountInfo);
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!myAccount && {
      defaultValues: {
        lastName: myAccount.val.lastName ? myAccount.val.lastName : "",
        firstName: myAccount.val.firstName ? myAccount.val.firstName : "",
        jobTitle: myAccount.val.jobTitle ? myAccount.val.jobTitle : "",
        companyName: myAccount.val.companyName ? myAccount.val.companyName : "",
        department: myAccount.val.department ? myAccount.val.department : "",
        streetAddress: myAccount.val.streetAddress
          ? myAccount.val.streetAddress
          : "",
        city: myAccount.val.city ? myAccount.val.city : "",
        stateOrProvince: myAccount.val.stateOrProvince
          ? myAccount.val.stateOrProvince
          : "",
        zipOrPostalCode: myAccount.val.zipOrPostalCode
          ? myAccount.val.zipOrPostalCode
          : "",
        countryOrRegion: myAccount.val.countryOrRegion
          ? myAccount.val.countryOrRegion
          : "",
        mobilePhone: myAccount.val.mobilePhone ? myAccount.val.mobilePhone : "",
      },
    }),
  });
  const { dataHasChanged } = useZodWatch<FormData>(
    control._defaultValues,
    control._formValues,
    watch
  );

  // Handlers.
  const onSubmit = (formData: FormData) => {
    if (!myAccount?.val) {
      return;
    }

    const aux: UserUpdate = {
      ...formData,
      memberId: myAccount.val.memberId,
      email: myAccount.val.email,
    };

    setIsLoading(true);
    updateUserRBAC(aux).then((response) => {
      setIsLoading(false);
      if (!response.memberId) {
        onSuccess?.(true, t("User profile details"));
        return;
      }

      onSuccess?.(false, t("User profile details"));
      myAccount.set({ ...myAccount.val, ...(response as UserUpdate) });
      onClose?.();
    });
  };

  return (
    <FormDialog
      title={t("Edit User Profile")}
      isLoading={isLoading}
      isValid={isValid && dataHasChanged}
      type={DialogType.normal}
      size={DialogSize.S}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
    >
      {WithLoadingPanelHOC(
        myAccount?.val !== undefined,
        <>
          <div style={{ paddingBottom: "0.25em" }}>
            <FormItemRow label={t("Email:")}>
              <Text variant="medium" style={{ fontWeight: "600" }}>
                {myAccount.val.email}
              </Text>
            </FormItemRow>
          </div>
          {renderFormItems(getFormItemProps(t), {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
        </>
      )}
    </FormDialog>
  );
};

export default EditUserProfileDialog;
