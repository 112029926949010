import { useMemo, useState, useEffect } from "react";
import {
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
} from "../../../common/Form";
import BaseDialog from "../../../common/Dialog";

import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  IDialogProps,
  DialogType,
  Text,
  Stack,
} from "@fluentui/react";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import { AlertLevel, CreateAlertLevel } from "./models";
import { ResponseSimplifiedSignal } from "../../models";
import {
  AlertLevelDirection,
  AlertLevelNotification,
  BooleanDefault,
  SignalCondition,
} from "../../../../schema/status";
import {
  format,
  getOptionFromEnum,
  isEmptyString,
} from "../../../../schema/Utils";
import {
  ComboBoxCustomV1,
  propsCustomComboBoxV1,
} from "../../../Generic/ComboBoxV1";
import { addAlertLevel, deleteAlertLevel, editAlertLevel } from "./api";
import { textStyle } from "../../../../schema/Constants";

import { useTranslation } from "react-i18next";

const getSchema = (t) =>
  z
    .object({
      conditionGroup: z.string().optional().nullable(),
      direction: z.nativeEnum(AlertLevelDirection),
      notificationType: z.nativeEnum(AlertLevelNotification),
      conditionManualValue: z.nativeEnum(SignalCondition),
      conditionManualEnabled: z.nativeEnum(BooleanDefault),
      faultyLow: z.string().optional().nullable(),
      faultyHigh: z.string().optional().nullable(),
      alertLow: z.string().optional().nullable(),
      alertHigh: z.string().optional().nullable(),
      indicationLow: z.string().optional().nullable(),
      indicationHigh: z.string().optional().nullable(),
      dangerLow: z.string().optional().nullable(),
      dangerHigh: z.string().optional().nullable(),
      conditionCluster: z.string().optional().nullable(),
    })
    .refine(
      (input) => {
        var regExpression = /^\d*\.?\d*$/;
        const valid = new RegExp(regExpression);

        if (input.faultyLow) {
          return valid.test(input.faultyLow);
        } else return true;
      },
      {
        path: ["faultyLow"],
        message: t("Try a number"),
      }
    )
    .refine(
      (input) => {
        const valid = new RegExp(/^\d*\.?\d*$/);

        if (input.faultyHigh) {
          return valid.test(input.faultyHigh);
        } else return true;
      },
      {
        path: ["faultyHigh"],
        message: t("Try a number"),
      }
    )
    .refine(
      (input) => {
        const valid = new RegExp(/^\d*\.?\d*$/);

        if (input.alertLow) {
          return valid.test(input.alertLow);
        } else return true;
      },
      {
        path: ["alertLow"],
        message: t("Try a number"),
      }
    )
    .refine(
      (input) => {
        const valid = new RegExp(/^\d*\.?\d*$/);

        if (input.alertHigh) {
          return valid.test(input.alertHigh);
        } else return true;
      },
      {
        path: ["alertHigh"],
        message: t("Try a number"),
      }
    )
    .refine(
      (input) => {
        const valid = new RegExp(/^\d*\.?\d*$/);

        if (input.indicationLow) {
          return valid.test(input.indicationLow);
        } else return true;
      },
      {
        path: ["indicationLow"],
        message: t("Try a number"),
      }
    )
    .refine(
      (input) => {
        const valid = new RegExp(/^\d*\.?\d*$/);

        if (input.indicationHigh) {
          return valid.test(input.indicationHigh);
        } else return true;
      },
      {
        path: ["indicationHigh"],
        message: t("Try a number"),
      }
    )
    .refine(
      (input) => {
        const valid = new RegExp(/^\d*\.?\d*$/);

        if (input.dangerLow) {
          return valid.test(input.dangerLow);
        } else return true;
      },
      {
        path: ["dangerLow"],
        message: t("Try a number"),
      }
    )
    .refine(
      (input) => {
        const valid = new RegExp(/^\d*\.?\d*$/);

        if (input.dangerHigh) {
          return valid.test(input.dangerHigh);
        } else return true;
      },
      {
        path: ["dangerHigh"],
        message: t("Try a number"),
      }
    );

const getFields: (t) => FormItemProps[] = (t) => [
  {
    name: "conditionGroup",
    type: FormItemType.TextField,
    groupProps: { label: t("Condition Group") },
  },
  {
    name: "direction",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Direction") },
    options: getOptionFromEnum(t, AlertLevelDirection),
  },
  {
    name: "notificationType",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Notification Type") },
    options: getOptionFromEnum(t, AlertLevelNotification),
  },
  {
    name: "conditionManualValue",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Condition Manual Value") },
    options: getOptionFromEnum(t, SignalCondition),
  },
  {
    name: "conditionManualEnabled",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Condition Manual Enabled") },
    options: getOptionFromEnum(t, BooleanDefault),
  },
  {
    name: "faultyLow",
    type: FormItemType.TextField,
    groupProps: { label: t("Faulty Low") },
  },
  {
    name: "faultyHigh",
    type: FormItemType.TextField,
    groupProps: { label: t("Faulty High") },
  },
  {
    name: "alertLow",
    type: FormItemType.TextField,
    groupProps: { label: t("Alert Low") },
  },
  {
    name: "alertHigh",
    type: FormItemType.TextField,
    groupProps: { label: t("Alert High") },
  },
  {
    name: "indicationLow",
    type: FormItemType.TextField,
    groupProps: { label: t("Indication Low") },
  },
  {
    name: "indicationHigh",
    type: FormItemType.TextField,
    groupProps: { label: t("Indication High") },
  },
  {
    name: "dangerLow",
    type: FormItemType.TextField,
    groupProps: { label: t("Danger Low") },
  },
  {
    name: "dangerHigh",
    type: FormItemType.TextField,
    groupProps: { label: t("Danger High") },
  },
  {
    name: "conditionCluster",
    type: FormItemType.TextField,
    groupProps: { label: t("Condition Cluster") },
  },
];

type AddOrEditDialogProps = IDialogProps & {
  machineId: string;
  data: AlertLevel | string | undefined;
  signals?: ResponseSimplifiedSignal[];
  show: boolean;
  onSuccess: (hasError: boolean, context: "add" | "edit" | "delete") => void;
  onClose: () => void;
  context: "add" | "edit" | "delete";
};

export const CUDDialog = ({
  machineId,
  data,
  signals,
  show,
  onSuccess,
  onClose,
  context,
  ...rest
}: AddOrEditDialogProps) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getSchema(t), [t]);

  const [isLoading, setLoading] = useState(false);
  const [idSelected, setIdSelected] = useState<string>(
    data && typeof data !== "string" && data?.signalId ? data?.signalId : ""
  );

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data &&
      typeof data !== "string" && {
        defaultValues: {
          conditionGroup: data.conditionGroup,
          direction: data.direction,
          notificationType: data.notificationType,
          conditionManualValue: data.conditionManualValue,
          conditionManualEnabled: data.conditionManualEnabled
            ? BooleanDefault.True
            : BooleanDefault.False,
          faultyLow: data.faultyLow?.toString(),
          faultyHigh: data.faultyHigh?.toString(),
          alertLow: data.alertLow?.toString(),
          alertHigh: data.alertHigh?.toString(),
          indicationLow: data.indicationLow?.toString(),
          indicationHigh: data.indicationHigh?.toString(),
          dangerLow: data.dangerLow?.toString(),
          dangerHigh: data.dangerHigh?.toString(),
          conditionCluster: data.conditionCluster?.toString(),
        },
      }),
  });

  useEffect(() => {
    data && typeof data !== "string"
      ? reset({
          conditionGroup: data?.conditionGroup,
          direction: data?.direction,
          notificationType: data?.notificationType,
          conditionManualValue: data?.conditionManualValue,
          conditionManualEnabled: data?.conditionManualEnabled
            ? BooleanDefault.True
            : BooleanDefault.False,
          faultyLow: data?.faultyLow?.toString(),
          faultyHigh: data?.faultyHigh?.toString(),
          alertLow: data?.alertLow?.toString(),
          alertHigh: data?.alertHigh?.toString(),
          indicationLow: data?.indicationLow?.toString(),
          indicationHigh: data?.indicationHigh?.toString(),
          dangerLow: data?.dangerLow?.toString(),
          dangerHigh: data?.dangerHigh?.toString(),
          conditionCluster: data?.conditionCluster?.toString(),
        })
      : reset({
          conditionGroup: "",
          direction: AlertLevelDirection.Unknown,
          notificationType: AlertLevelNotification.Unknown,
          conditionManualValue: SignalCondition.NoStatus,
          conditionManualEnabled: BooleanDefault.False,
          faultyLow: "",
          faultyHigh: "",
          alertLow: "",
          alertHigh: "",
          indicationLow: "",
          indicationHigh: "",
          dangerLow: "",
          dangerHigh: "",
          conditionCluster: "",
        });
  }, [data, reset]);

  const argsIdField: propsCustomComboBoxV1 = {
    keyMe: 30,
    label: t("Select Signal *"),
    options: signals
      ?.map((sig) => ({
        key: sig.id,
        text:
          sig.name +
          " (SS No: " +
          sig.sensorSerialNo +
          ") (" +
          format(new Date(sig.dataFrom)) +
          ", " +
          format(new Date(sig.dataUntil)) +
          ") ",
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1)),
    selection: setIdSelected,
    errorMessage: idSelected ? "" : t("Select a signal"),
    defaultKey: idSelected,
  };

  const textFComp = ComboBoxCustomV1(argsIdField);

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);

    const toSend: CreateAlertLevel = {
      signalId: idSelected,
      conditionGroup: formData.conditionGroup,
      direction: formData.direction,
      notificationType: formData.notificationType,
      conditionManualValue: formData.conditionManualValue,
      conditionManualEnabled:
        formData.conditionManualEnabled === "True" ? true : false,
      faultyLow: isEmptyString(formData.faultyLow)
        ? undefined
        : Number(formData.faultyLow),
      faultyHigh: isEmptyString(formData.faultyHigh)
        ? undefined
        : Number(formData.faultyHigh),
      alertLow: isEmptyString(formData.alertLow)
        ? undefined
        : Number(formData.alertLow),
      alertHigh: isEmptyString(formData.alertHigh)
        ? undefined
        : Number(formData.alertHigh),
      indicationLow: isEmptyString(formData.indicationLow)
        ? undefined
        : Number(formData.indicationLow),
      indicationHigh: isEmptyString(formData.indicationHigh)
        ? undefined
        : Number(formData.indicationHigh),
      dangerLow: isEmptyString(formData.dangerLow)
        ? undefined
        : Number(formData.dangerLow),
      dangerHigh: isEmptyString(formData.dangerHigh)
        ? undefined
        : Number(formData.dangerHigh),
      conditionCluster: formData.conditionCluster,
    };

    switch (context) {
      case "add":
        await addAlertLevel(machineId, toSend).then((response) =>
          onSuccess("status" in response, context)
        );
        break;
      case "edit":
        await editAlertLevel(
          machineId,
          typeof data !== "string" && "id" in data ? data.id : "",
          typeof data !== "string" && "id" in data && { id: data.id, ...toSend }
        ).then((response) => onSuccess("status" in response, context));
        break;
      case "delete":
        await deleteAlertLevel(
          machineId,
          typeof data === "string" && data
        ).then((response) => onSuccess("status" in response, context));
        break;
    }

    handleClose();
  });

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  return (
    <>
      {context && (
        <BaseDialog
          {...rest}
          hidden={!show}
          dialogContentProps={{
            type: DialogType.normal,
            title:
              context.charAt(0).toUpperCase() +
              context.slice(1) +
              " " +
              t("Alert Level"),
            closeButtonAriaLabel: t("Close"),
            onDismiss: handleClose,
          }}
        >
          {["add", "edit"].includes(context) ? (
            <form onSubmit={onSubmit}>
              {context === "edit" ? (
                <>
                  <Stack
                    style={{
                      fontWeight: "600",
                      lineHeight: "22px",
                      marginTop: "8px",
                      paddingBottom: "20px",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      {t(`Selected signal:  ({{signalName}})`, {
                        signalName:
                          data &&
                          typeof data !== "string" &&
                          signals.find((sig) => sig.id === data?.signalId)
                            ?.name,
                      })}
                    </Text>
                  </Stack>
                </>
              ) : (
                textFComp
              )}
              {renderFormItems(getFields(t), {
                control,
                errors: errors as { [schemaProp: string]: FieldError },
              })}
              <DialogFooter>
                <PrimaryButton
                  type="submit"
                  text={t("Save Changes")}
                  disabled={isLoading || !isValid || idSelected === undefined}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                />
                <DefaultButton
                  styles={{
                    root: { border: "unset", background: "transparent" },
                  }}
                  text={t("Cancel")}
                  onClick={handleClose}
                />
              </DialogFooter>
            </form>
          ) : ["delete"].includes(context) ? (
            <>
              <div style={textStyle}>
                {t("You are about to delete selected Alert level.")}
              </div>

              <DialogFooter>
                <PrimaryButton
                  text={t("Delete")}
                  disabled={isLoading}
                  onClick={onSubmit}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                />
                <DefaultButton text={t("Close")} onClick={handleClose} />
              </DialogFooter>
            </>
          ) : null}
        </BaseDialog>
      )}
    </>
  );
};
