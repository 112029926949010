import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import GatewayIcon from "../../../../assets/svg/WirelessSensors/GatewayIcon";
import MachineIcon from "../../../../assets/svg/WirelessSensors/MachineIcon";
import SensorNodeIcon from "../../../../assets/svg/WirelessSensors/SensorNodeIcon";
import type { TreeItem } from "../../components/Tree";
import WirelessSensorsTree from "../../components/Tree";
import { useMachineSensorsProfiles } from "../../hooks/useMachineSensorsProfiles";
import { useMachineTriggering } from "../../hooks/useMachineTriggering";
import { getColumns } from "../Gateway/columns";
import type { GatewayTelemetry, Topology } from "../../../../types";
import { notification } from "../../../common/Notification";
import type { TableProps } from "../../../common/Table";
import Table from "../../../common/Table";

import { useMachineTelemetry } from "../../hooks/useMachineTelemetry";
import { useTranslation } from "react-i18next";

const Triggers = () => {
  const { t } = useTranslation();
  const machineId = useParams()?.machineId || "";
  const {
    data: sensorsProfiles,
    isLoading,
    isError,
  } = useMachineSensorsProfiles({ machineId });
  const [triggerMachineLoading, setTriggerMachineLoading] = useState(false);

  const {
    data,
    isLoading: isLoadingMachine,
    isError: isErrorMachine,
    refetch,
  } = useMachineTelemetry({ machineId });
  const [tree, setTree] = useState<TreeItem>({});

  const gatewayTelemetryToTreeItem = (
    telemetry: GatewayTelemetry
  ): TreeItem => {
    return {
      icon: <GatewayIcon />,
      name: telemetry.gatewayId,
      children: telemetry.topology.children.map(topologyTelemetryToTreeItem),
      condition: telemetry.enabled ? "Ok" : "Alert",
    };
  };

  const topologyTelemetryToTreeItem = (telemetry: Topology): TreeItem => {
    return {
      name: telemetry.value,
      children: telemetry.children.map(topologyTelemetryToTreeItem),
      icon: <SensorNodeIcon />,
      condition: "Ok",
      disabled: !data.machineSensors.some(
        (sensor) => sensor === telemetry.value
      ),
    };
  };

  useEffect(() => {
    if (!isErrorMachine && !isLoadingMachine && data) {
      const treeData: TreeItem = {
        name: "",
        icon: <MachineIcon />,
        children: data.gatewayTelemetries.map((telemetry: GatewayTelemetry) =>
          gatewayTelemetryToTreeItem(telemetry)
        ),
      };
      setTree(treeData);
    }

    return;
  }, [data, isLoadingMachine, isErrorMachine]);

  const tableProps = useMemo<TableProps>(
    () => ({
      isLoading,
      isError,
      persistOpts: {
        key: "machine-sensor-trigger-status",
        version: 1,
      },
      header: {
        title: t("Machine Sensor Trigger Status"),
      },
      columns: getColumns(t),
      hasSelection: false,
      items: sensorsProfiles || [],
    }),
    [isLoading, t, isError, sensorsProfiles]
  );

  const { triggerMachineAsync } = useMachineTriggering({
    machineId: machineId as string,
  });

  const onTriggerMachine = () => {
    setTriggerMachineLoading(true);
    triggerMachineAsync().then(
      () => {
        notification.success(t("The machine trigger was sent successfully"));
        setTriggerMachineLoading(false);
      },
      (err) => {
        notification.error(
          err?.response?.data?.detail || t("Send trigger to machine error")
        );
        setTriggerMachineLoading(false);
      }
    );
  };

  return (
    <>
      <WirelessSensorsTree
        data={tree}
        title={t("Machine Telemetry")}
        buttonProps={{
          loading: triggerMachineLoading,
          buttonText: t("Send trigger to machine"),
          onSend: onTriggerMachine,
        }}
        chartStyle={{ height: "65vh" }}
        isLoading={isLoadingMachine}
        onRefresh={() => refetch}
      />
      <Table {...tableProps} />
    </>
  );
};

export default Triggers;
