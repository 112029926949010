/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  IDropdownStyleProps,
  IDropdownStyles,
  IStyleFunctionOrObject,
  IToggleStyleProps,
  IToggleStyles,
} from "@fluentui/react";
import { ActionButton, Dropdown, Stack, Toggle } from "@fluentui/react";
import { format as fnsFormat } from "date-fns";
import React, { useState } from "react";

import PeriodIcon from "../../../../../assets/svg/TasksIcon";
import { maximizeButton } from "../../../../../modules/analysis-raw-data/styles/ChartSection.styles";
import { FORMAT } from "../../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import CustomDateDialog from "../../../../../modules/common/components/CustomDateDialog";
import { dropdownIconStyle } from "../../../../../assets/styles";
import { onRenderLabel } from "../../../../common/CommandBar/methods";

import useDriveLoadStore from "../../hooks/useDriveLoadStore";
import { useTranslation } from "react-i18next";
import { getPeriodDropdownOptions } from "../../config";

const dropdownStyles: IStyleFunctionOrObject<
  IDropdownStyleProps,
  IDropdownStyles
> = {
  root: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
    userSelect: "none",
    alignSelf: "start",
    selectors: {
      ":hover": {
        backgroundColor: "#F3F2F1",
        color: "rgb(32, 31, 30)",
      },
      ":active": {
        backgroundColor: "#EDEBE9",
      },
    },
  },
  dropdown: {
    width: "auto",
  },
  title: {
    lineHeight: 32,
    fontWeight: 600,
  },
};

const toggleStyles: IStyleFunctionOrObject<IToggleStyleProps, IToggleStyles> = {
  root: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
};

type TorqueHeaderProps = React.HTMLAttributes<HTMLElement> & {
  maximizeIcon?: JSX.Element;
  maximizeLabel?: string;
  maximizeAction?: () => void;
  isDriveLoadLayout: boolean;
};

const TorqueHeader = ({
  maximizeIcon,
  maximizeLabel,
  maximizeAction,
  isDriveLoadLayout,
  ...rest
}: TorqueHeaderProps) => {
  const { t } = useTranslation();
  const [isCustomPeriodVisible, setIsCustomPeriodVisible] =
    useState<boolean>(false);
  const { period, averageTrend, updateDriveLoadStore, isConnectedTimeAxis } =
    useDriveLoadStore((store: any) => ({
      period: store.period,
      averageTrend: store.averageTrend,
      updateDriveLoadStore: store.updateDriveLoadStore,
      isConnectedTimeAxis: store.isConnectedTimeAxis,
    }));

  // Handlers
  const onPeriodChange = (ev: any, selectedItem: any) => {
    if (selectedItem.key === getPeriodDropdownOptions(t)[6].key) {
      setIsCustomPeriodVisible(true);
    } else {
      updateDriveLoadStore({ period: selectedItem, torqueChartDates: {} });
    }
  };

  const onCustomDateChange = ({ start, end }: any) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    updateDriveLoadStore({
      period: {
        ...getPeriodDropdownOptions(t)[6],
        startDate: fnsFormat(startDate, FORMAT),
        endDate: fnsFormat(endDate, FORMAT),
      },
      torqueChartDates: {},
    });
  };

  return (
    <React.Fragment>
      <Stack
        {...rest}
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: 20 }}
        role="menubar"
      >
        <Dropdown
          className="layout-dropdown secondary-dropdown"
          styles={dropdownStyles}
          calloutProps={{ calloutMinWidth: 200 }}
          options={getPeriodDropdownOptions(t)}
          selectedKey={period.key}
          onChange={onPeriodChange}
          onRenderLabel={() =>
            onRenderLabel({
              label: t("Period"),
              icon: <PeriodIcon fill="#2c529f" style={dropdownIconStyle} />,
            })
          }
        />
        <Toggle
          inlineLabel
          label={t("Average trend")}
          styles={toggleStyles}
          checked={averageTrend}
          onChange={(_, checked) =>
            updateDriveLoadStore({ averageTrend: checked })
          }
        />
        {!isDriveLoadLayout && (
          <Toggle
            inlineLabel
            label={t("Connect time axis")}
            styles={toggleStyles}
            checked={isConnectedTimeAxis}
            onChange={(_, checked) =>
              updateDriveLoadStore({ isConnectedTimeAxis: checked })
            }
          />
        )}
        {/* <Toggle
          inlineLabel
          label='Connect time axis'
          styles={toggleStyles}
          checked={isConnectedTimeAxis}
          onChange={(_, checked) => updateDriveLoadStore({ isConnectedTimeAxis: checked })}
        /> */}
        <Stack horizontal style={{ flexGrow: 1 }} horizontalAlign="end">
          <ActionButton
            allowDisabledFocus
            styles={maximizeButton}
            onClick={maximizeAction}
          >
            {maximizeIcon}
            <span>{maximizeLabel}</span>
          </ActionButton>
        </Stack>
      </Stack>
      <CustomDateDialog
        initialValues={period}
        hidden={!isCustomPeriodVisible}
        setVisible={setIsCustomPeriodVisible}
        onSubmit={onCustomDateChange}
      />
    </React.Fragment>
  );
};

export default TorqueHeader;
