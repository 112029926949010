import type {
  IDialogStyleProps,
  IDialogStyles,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  DefaultButton,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import type { AxiosResponse } from "axios";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { z } from "zod";

import { format as formatDate, ISOStringToDate } from "../../../../utils";
import {
  getFileIds,
  useFileDelete,
  useFileUpload,
  useCurrentUser,
  useLocationSearch,
} from "../../../../Hooks";

import { FORMAT } from "../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import AddDocumentDialog from "../../../../modules/machine-cv/components/MachineCVDialogs/AddDocumentDialog";
import { getColumns } from "../../MachineCVInfoPage/components/DocumentsTable/columns";
import { REPORT_CATEGORY_OPTIONS } from "../config";
import type { ReportAddProps } from "../hooks/useReportsMutations";
import { useReportAdd, useReportUpdate } from "../hooks/useReportsMutations";
import type { Report } from "../../../../types";
import BaseDialog, { DialogSize } from "../../../common/Dialog/Dialog";
import { renderFormItems, useZodForm } from "../../../common/Form";
import type { FormItemProps } from "../../../common/Form/FormItems/helpers";
import { FormItemType } from "../../../common/Form/FormItems/helpers";
import { notification } from "../../../common/Notification";
import type { TableProps } from "../../../common/Table";
import Table from "../../../common/Table";
import type { UploadedFile } from "../../../common/Uploader/types";
import UploaderButton from "../../../common/Uploader/UploaderButton";

import { useTranslation } from "react-i18next";

type SubmitData = { category: string; createdAt: Date | string };

type ReportDocumentDialogProps = React.HTMLAttributes<HTMLElement> & {
  onClose: (refresh: boolean) => void;
  report?: Report;
};

type ReportReduced = {
  text: string;
  category: string;
  createdAt: string;
};

const dialogStyles: IStyleFunctionOrObject<IDialogStyleProps, IDialogStyles> = {
  root: [
    {
      ".ms-Dialog-header.ms-Dialog--close": {
        height: "auto",
      },
    },
    {
      ".ms-Dialog--close": {
        height: "100%",
      },
    },
    {
      ".ms-Dialog-inner": {
        height: "calc(100% - 63px)",
      },
    },
    {
      ".ms-Dialog-content": {
        height: "100%",
      },
    },
  ],
};

const schema = z.object({
  category: z.string(),
  createdAt: z.date(),
});

const getFields: (t) => FormItemProps[] = (t) => [
  {
    name: "category",
    type: FormItemType.Dropdown,
    options: REPORT_CATEGORY_OPTIONS,
    groupProps: { label: t("Category") },
    placeholder: t("Category"),
  },
  {
    name: "createdAt",
    type: FormItemType.DatePicker,
    formatDate: (date) => formatDate(date ?? new Date()),
    groupProps: { label: t("Created at") },
  },
];

const getInitialValues = (report: ReportReduced) => ({
  text: report?.text,
  category: report?.category,
  ...(report?.createdAt
    ? { createdAt: ISOStringToDate(report?.createdAt) }
    : {}),
});

export const ReportDocumentDialog = ({
  report,
  onClose,
  ...rest
}: ReportDocumentDialogProps) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<UploadedFile[]>(
    report?.files ? [...report.files] : []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDocumentsDialogHidden, setIsDocumentsDialogHidden] =
    useState<boolean>(true);
  const loggedUser = useCurrentUser();
  const [text, setText] = useState<string>(report?.text ?? "");
  const [{ id }] = useLocationSearch();
  const { addReportAsync } = useReportAdd();
  const { updateReportAsync } = useReportUpdate(report?.id ?? "");
  const { uploadFilesAsync } = useFileUpload();
  const { deleteFilesAsync } = useFileDelete();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useZodForm({
    ...(!!report && {
      defaultValues: getInitialValues(report),
    }),
    schema,
  });

  const fileTableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-edit-files-reports",
        version: 1,
      },
      items: files,
      perPage: 5,
      hidePerPage: true,
      hasSelection: false,
      columns: getColumns({
        t,
        onRemove: (fileId) => {
          setFiles((prev) => [
            ...prev.filter((file: UploadedFile) => file?.id !== fileId),
          ]);
        },
      }),
    }),
    [files, t]
  );

  const toggleIsDocumentsDialogHidden = () =>
    setIsDocumentsDialogHidden(!isDocumentsDialogHidden);

  // Sets the report markdown text.
  useEffect(() => {
    if (!report) {
      return;
    }

    setText(report.text);
  }, [report]);

  // Handlers
  const onFinish = (
    uploadResponse: PromiseSettledResult<AxiosResponse>[],
    successIds: string[]
  ) => {
    if (uploadResponse.length === successIds.length) {
      notification.success(
        report
          ? t("Report updated successfully")
          : t("Report created successfully")
      );
    } else {
      notification.success(
        report
          ? t(
              `Report updated successfully with {{updatedFiles}} out of {{totalFiles}} files`,
              {
                updatedFiles: successIds.length,
                totalFiles: uploadResponse.length,
              }
            )
          : t(
              `Report created successfully with {{createdFiles}} out of {{totalFiles}} files`,
              {
                createdFiles: successIds.length,
                totalFiles: uploadResponse.length,
              }
            )
      );
    }

    onClose?.(true);
  };

  const onAdd = async (data: SubmitData) => {
    setIsLoading(true);
    const uploadResponse = await uploadFilesAsync({
      files,
      machineId: id || "",
    });
    const successIds = getFileIds(uploadResponse);

    await addReportAsync({
      text,
      machineId: id as string,
      createdBy: loggedUser.displayName || "no-name-available",
      fileIds: successIds,
      category: data.category,
      createdAt: data.createdAt.toLocaleString(),
    })
      .then(() => {
        onFinish(uploadResponse, successIds);
      })
      .catch(() => {
        notification.error(t("Error posting report"));
        deleteFilesAsync(successIds);
      })
      .finally(() => setIsLoading(false));
  };

  const onEdit = async (data: SubmitData) => {
    setIsLoading(true);
    const uploadResponse = await uploadFilesAsync({
      files,
      machineId: id || "",
    });
    const successIds = getFileIds(uploadResponse);

    const newReport: ReportAddProps = {
      text,
      machineId: report?.machineId ?? "",
      createdBy: report?.createdBy ?? "",
      createdAt: data.createdAt.toLocaleString(),
      category: data.category,
      fileIds: [
        ...files.filter((file) => !file.isValid).map((file) => file.id),
        ...successIds,
      ],
    };

    await updateReportAsync(newReport)
      .then(() => {
        onFinish(uploadResponse, successIds);
      })
      .catch(() => {
        notification.error(t("Error updating report"));
        deleteFilesAsync(successIds);
      })
      .finally(() => setIsLoading(false));
  };

  const onSubmit = handleSubmit(async (data) => {
    const submitData = {
      ...data,
      createdAt: format(data.createdAt, FORMAT),
    };

    if (report) {
      onEdit(submitData as SubmitData);
    } else {
      onAdd(submitData as SubmitData);
    }
  });

  return (
    <React.Fragment>
      <BaseDialog
        {...rest}
        hidden={false}
        dialogContentProps={{
          type: DialogType.close,
          title: report ? t("Edit Report") : t("Create Report"),
          closeButtonAriaLabel: t("Close"),
          onDismiss: () => onClose?.(false),
        }}
        modalProps={{ className: "resizable-modal" }}
        size={DialogSize.L}
        styles={dialogStyles}
      >
        <form style={{ height: "100%" }} onSubmit={onSubmit}>
          <div style={{ height: "calc(100% - 48px)", overflow: "auto" }}>
            {renderFormItems(getFields(t), {
              control,
              errors: errors as { [schemaProp: string]: FieldError },
            })}
            <Stack tokens={{ childrenGap: 5 }}>
              <Text
                as="label"
                variant="medium"
                id={`report-text-label-${report?.id}`}
              >
                {t("Text:")}
              </Text>
              <TextField
                multiline
                aria-labelledby={`report-text-label-${report?.id}`}
                value={text}
                styles={{ field: { height: "20em" } }}
                resizable={false}
                onChange={(_, newValue) => setText(newValue ?? "")}
              />
            </Stack>
            <UploaderButton onClick={toggleIsDocumentsDialogHidden} />
            <Table {...fileTableProps} />
          </div>

          <DialogFooter>
            <PrimaryButton
              type="submit"
              text={report ? t("Save") : t("Create")}
              disabled={isLoading}
              onRenderIcon={() =>
                isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
              }
            />
            <DefaultButton
              styles={{
                root: { border: "unset", background: "transparent" },
              }}
              text={t("Cancel")}
              onClick={() => onClose?.(false)}
            />
          </DialogFooter>
        </form>
      </BaseDialog>
      <AddDocumentDialog
        hidden={isDocumentsDialogHidden}
        onSubmit={(accepted) => setFiles([...files, ...accepted])}
        onClose={toggleIsDocumentsDialogHidden}
      />
    </React.Fragment>
  );
};
