import { useMemo, useState } from "react";

import {
  FormItemProps,
  FormItemType,
  renderFormItems,
  useZodForm,
} from "../../../common/Form";
import { notification } from "../../../common/Notification";

import { z } from "zod";
import type { FieldError } from "react-hook-form";
import {
  PrimaryButton,
  Spinner,
  SpinnerSize,
  StackItem,
} from "@fluentui/react";
import { FormGroupProps } from "../../../common/Form";
import SectionContainer from "../SectionContainer";
import { axiosInstance } from "../../../..";

import { useAppDispatch, useAppSelector } from "../../../../Hooks";
import { updateUserAccountDetails } from "../../../UserAccount/api";
import {
  hasProfilePictureChanged,
  selectAllUserAccountProperties,
  selectUserAccountContactProperties,
  selectUserAccountMemberID,
  selectUserAccountProfilePicture,
} from "../../../UserAccount/selectors";
import { getAsyncUserAccountDetails } from "../../../UserAccount/reducer";
import UpdateAvatar from "./UpdateAvatar";
import { updateUserPhoto } from "../../../UsersRBCA/api";
import { useTranslation } from "react-i18next";

export type ThreadAddProps = {
  machineId: string;
  text: string;
};

export const useAccountUpdate = (data) => {
  return axiosInstance.post("meta/machinecv/v1/comments", data);
};

const getSchema = (t) =>
  z.object({
    email: z
      .string()
      .email({ message: t("Invalid email address") })
      .min(1, { message: t("This field is required") }),
    mobilePhone: z
      .union([
        z.string().length(0, { message: t("Invalid number") }),
        z.string().regex(/^[+(\s.\-/\d)]{5,30}/),
      ])
      .optional()
      .transform((e) => (e === "" ? undefined : e)),
    countryOrRegion: z.string(),
    stateOrProvince: z.string(),
    city: z.string(),
    streetAddress: z.string(),
    zipOrPostalCode: z.string(),
  });

const groupPropsStyles = {
  labelProps: {
    style: {
      fontWeight: 600,
    },
  },
  stackProps: {
    style: {
      flexDirection: "column",
      marginBottom: 12,
      maxWidth: 600,
    },
  },
} as FormGroupProps;

const formStyles = {
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  padding: "8px 16px",
  marginBottom: 65,
  overflow: "auto",
} as const;

const getFormItems: (t) => FormItemProps[] = (t) => [
  {
    name: "email",
    type: FormItemType.TextField,
    groupProps: {
      label: t("E-mail"),
      ...groupPropsStyles,
    },
  },
  {
    name: "mobilePhone",
    type: FormItemType.TextField,
    groupProps: {
      label: t("Mobile"),
      ...groupPropsStyles,
    },
  },
  {
    name: "countryOrRegion",
    type: FormItemType.TextField,
    groupProps: {
      label: t("Country"),
      ...groupPropsStyles,
    },
  },
  {
    name: "stateOrProvince",
    type: FormItemType.TextField,
    groupProps: {
      label: t("State"),
      ...groupPropsStyles,
    },
  },
  {
    name: "city",
    type: FormItemType.TextField,
    groupProps: {
      label: t("City"),
      ...groupPropsStyles,
    },
  },
  {
    name: "streetAddress",
    type: FormItemType.TextField,
    groupProps: {
      label: t("Street"),
      ...groupPropsStyles,
    },
  },
  {
    name: "zipOrPostalCode",
    type: FormItemType.TextField,
    groupProps: {
      label: t("Zip"),
      ...groupPropsStyles,
    },
  },
];

const Contact = ({ myAccount = {} }) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getSchema(t), [t]);
  const [isLoading, setIsLoading] = useState(false);

  const allUserAccountProperties = useAppSelector(
    selectAllUserAccountProperties
  );
  const contactProperties = useAppSelector(selectUserAccountContactProperties);

  const profilePicture = useAppSelector(selectUserAccountProfilePicture);
  const isProfilePictureChanged = useAppSelector(hasProfilePictureChanged);

  const userId = useAppSelector(selectUserAccountMemberID);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useZodForm({
    mode: "onChange",
    schema,
    defaultValues: contactProperties,
  });

  const onSubmit = handleSubmit(async (data: object) => {
    if (!data) return;

    setIsLoading(true);

    const payload = {
      ...allUserAccountProperties,
      ...data,
    };

    if (isProfilePictureChanged) {
      await updateUserPhoto({
        id: userId,
        pictureBase64: profilePicture,
      })
        .then((response) => {
          if (response) {
            notification.success(t("Profile picture updated successfully!"));
          }
        })
        .catch(() => {
          notification.warning(
            t(
              "Something went wrong while trying to update the profile picture, please refresh the page. And try it again."
            )
          );
        });
    }

    await updateUserAccountDetails(userId, payload)
      .then((response) => {
        if (response) {
          notification.success(t("Settings updated successfully!"));
        }
      })
      .catch(() => {
        notification.warning(
          t("Something went wrong, please refresh the page. And try it again.")
        );
      })
      .finally(() => {
        dispatch(getAsyncUserAccountDetails(userId));
        setIsLoading(false);
      });
  });

  return (
    <SectionContainer headerTitle={t("Contact")} isLoading={!myAccount}>
      <form onSubmit={onSubmit} style={{ ...formStyles }}>
        <div className="form-items-container">
          <UpdateAvatar />

          <div
            style={{
              background: "#E1DFDD",
              width: "100%",
              margin: "24px 0px",
              height: "1px",
            }}
          />

          {renderFormItems(getFormItems(t), {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          }).map((element) => (
            <StackItem key={element.key}>{element}</StackItem>
          ))}
        </div>

        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            padding: 16,
            background: "white",
            width: "100%",
            borderTop: "1px solid #F3F2F1",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <PrimaryButton
            type="submit"
            text={t("Update")}
            disabled={!isValid || isLoading}
            style={{
              marginTop: "auto",
              alignSelf: "flex-start",
            }}
            onRenderIcon={() =>
              isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
          />
        </div>
      </form>
    </SectionContainer>
  );
};

export default Contact;
