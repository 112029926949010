import type { DataItem } from "@amcharts/amcharts5";
import * as am5 from "@amcharts/amcharts5";
import type { IValueAxisDataItem } from "@amcharts/amcharts5/xy";
import * as am5xy from "@amcharts/amcharts5/xy";
import type { IDropdownOption } from "@fluentui/react/lib/components/Dropdown";
import { parseISO } from "date-fns";
import { debounce, isEmpty } from "lodash-es";
import { useEffect, useMemo, useRef } from "react";

import DalogLogo from "../../../../assets/images/dalog-logo.svg";
import { default as chartSymbols } from "../../../../assets/svg/ChartSymbolsIcons";
import { DEFAULT_DEBOUNCE } from "../../../../config/constants";
import { useSignalProjectTimezoneOffset, useUniqueId } from "../../../../Hooks";

import { getRangeIndexes, capitalize } from "../../../../utils";
import { watermarkLogos } from "../../../analysis-raw-data/constants/chartWatermarkLogos";
import { yAxisWheel } from "../TrendViewGroupedPlot/methods";
import { setChartDisabled } from "../../../analysis-trend-view/utils/setChartDisabled";
import { format as signalFormatter } from "../../../common/utils/signalFormatter";
import { conditionColor } from "../../../machine-cv/constants";
import type {
  Company,
  Corporation,
  MachineListItem,
  Project,
  ResponseSimplifiedSignal,
} from "../../../../types";
import type { XYChartRef } from "../../../../Components/common/XYChart";
import {
  getDateAxis,
  getLabel,
  getLegend,
  getSeries,
  getValueAxis,
  XYChart,
} from "../../../../Components/common/XYChart";
import { createChartExport } from "../../../../Components/common/XYChart/export";
import { createGroupedTooltip } from "../../../../Components/common/XYChart/tooltip";
import type { XYChartStyles } from "../../../../Components/common/XYChart/XYChart";

import useAlertsStore from "../../hooks/useAlertsStore";
import type { ConnectedTimeRange, Range } from "../../hooks/useControlsStore";
import useSelectedSignalsStore from "../../hooks/useSelectedSignalsStore";
import { getRangeFormat } from "../../utils/getRangeFormat";
import { isOutOfSync, zoomToDates } from "../../utils/zoom";
import { useTrendViewZoomInData } from "./useTrendViewZoomInData";
import { useTranslation } from "react-i18next";

export interface ChartData {
  date: number;
  value: string;
  refDate?: number;
  refValue?: string;
}

interface TrendViewPlotProps {
  isAverageTrend?: boolean;
  loadingCharts: boolean;
  selectedPeriod: IDropdownOption | undefined;
  signalId: string | undefined;
  signalPlotAxis: {
    x: string[];
    y: string[];
  };
  referenceSignalPlotAxis: {
    x: string[];
    y: string[];
  };
  hasReferenceSignal: boolean;
  connectedTimeRange: ConnectedTimeRange | null;
  setSelectedRange: (selectedIndexes: Range, selectedTimes: Range) => void;
  corporation: Corporation;
  company: Company;
  project: Project;
  machine: MachineListItem;
  signal: ResponseSimplifiedSignal;
  referenceSignal: ResponseSimplifiedSignal | undefined;
  exportSelector: string;
  chartStyles?: XYChartStyles;
  disableReloadOnZoom?: boolean;
}

type EntityIds = {
  xAxis: string;
  series: string;
  legend: string;
  yAxis: string;
  refYAxis: string;
  refYAxisContainer: string;
  refSeries: string;
  outOfSync: string;
  noAverageTrend: string;
};

function getHtmlBreadcrumb({
  corporation,
  company,
  project,
  machine,
  signal,
}: any) {
  return `
        <span style='font-size: 12px;color: #878583;'>
          ${corporation?.name}  〉 ${company?.name}  〉 ${project?.name} 〉 ${
            machine?.name
          }  〉 <strong style='color: #333;'>${signalFormatter(signal)}</strong>
        </span>`;
}

const TrendViewPlot = ({
  loadingCharts,
  selectedPeriod,
  signalId,
  signalPlotAxis,
  referenceSignalPlotAxis,
  hasReferenceSignal,
  connectedTimeRange,
  setSelectedRange,
  corporation,
  company,
  project,
  machine,
  signal,
  referenceSignal,
  exportSelector,
  chartStyles,
  isAverageTrend,
  disableReloadOnZoom,
}: TrendViewPlotProps) => {
  const { t } = useTranslation();

  const ref = useRef<XYChartRef | null>(null);

  const breadcrumbRef = useRef<any>(null);

  const legendRef = useRef<any>(null);

  const seriesRef = useRef<am5xy.LineSeries | null>(null);

  const seriesRefSignalRef = useRef<am5xy.LineSeries | null>(null);

  const rangeRef = useRef("");

  const uniqueId = useUniqueId();

  const tzOffset = useSignalProjectTimezoneOffset({ signal });

  const tzReferenceOffset = useSignalProjectTimezoneOffset({
    signal: referenceSignal,
  });

  const { selectedSignalsAlerts, getSignalAlertById } = useSelectedSignalsStore(
    (state) => ({
      selectedSignalsAlerts: state.selectedSignalsAlerts,
      getSignalAlertById: state.getSignalAlertById,
    })
  );

  const { alertsOptions } = useAlertsStore((state) => ({
    alertsOptions: state.alertsOptions,
  }));

  const entityIds = useMemo<EntityIds>(
    () => ({
      xAxis: `x-axis-trend-view-plot-${signalId}`,
      series: `series-trend-view-plot-${signalId}`,
      legend: `legend-trend-view-plot-${signalId}-${uniqueId}`,
      yAxis: `y-axis-trend-view-plot-${signalId}`,
      refYAxis: `ref-y-axis-trend-view-plot-${signalId}`,
      refYAxisContainer: `ref-y-axis-container-trend-view-plot-${signalId}`,
      refSeries: `ref-series-trend-view-plot-${signalId}`,
      outOfSync: `out-of-sync-tren-view-plot-${signalId}`,
      noAverageTrend: `no-average-data-trend-view-plot-${signalId}`,
    }),
    []
  );

  const chartData = useMemo<ChartData[]>(() => {
    const data: ChartData[] = [];

    for (let i = 0; i < signalPlotAxis.x.length; i++) {
      if (hasReferenceSignal) {
        data.push({
          date: parseISO(signalPlotAxis.x[i]).getTime() + tzOffset,
          value: signalPlotAxis.y[i],
          refDate:
            parseISO(referenceSignalPlotAxis.x[i]).getTime() +
            tzReferenceOffset,
          refValue: referenceSignalPlotAxis.y[i],
        });
      } else {
        data.push({
          date: parseISO(signalPlotAxis.x[i]).getTime() + tzOffset,
          value: signalPlotAxis.y[i],
        });
      }
    }

    // Set default value for range
    if (data.length > 0) {
      rangeRef.current = getRangeFormat(
        data[0].date,
        data[data.length - 1].date
      );
    }

    return data;
  }, [
    signalPlotAxis,
    referenceSignalPlotAxis,
    selectedPeriod?.key,
    tzOffset,
    tzReferenceOffset,
  ]);

  const { handleZoomChange } = useTrendViewZoomInData({
    signal,
    selectedPeriod,
  });

  const onStartEndChanged = (_: unknown, target: am5.Scrollbar | undefined) => {
    if (!ref.current || !target || chartData.length === 0) return;

    const start = target.get("start") ?? 0;
    const end = target.get("end") ?? 1;

    const xAxis = getDateAxis(entityIds.xAxis);

    const times: ConnectedTimeRange["range"] = {
      startIndex: new Date(xAxis.positionToDate(start)).getTime(),
      endIndex: new Date(xAxis.positionToDate(end)).getTime(),
    };

    const indexes = getRangeIndexes({
      data: chartData,
      start: times.startIndex,
      end: times.endIndex,
    });

    const currentRange: ConnectedTimeRange["range"] = {
      startIndex: chartData[indexes.startIndex].date,
      endIndex: chartData[indexes.endIndex].date,
    };

    rangeRef.current = getRangeFormat(
      currentRange.startIndex,
      currentRange.endIndex
    );

    // === used to handle zoom interval below than one month ===
    if (!disableReloadOnZoom) {
      handleZoomChange(currentRange);
    }

    setSelectedRange(indexes, currentRange);
  };

  const handleStartEndChanged = debounce(onStartEndChanged, DEFAULT_DEBOUNCE);

  useEffect(() => {
    if (!ref?.current) return;

    // === Footer Label ===

    let breadcrumbRoot: am5.Root | undefined = undefined;
    if (corporation && company && project && machine) {
      breadcrumbRoot = am5.Root.new(`breadcrumb-container-${signalId}`);
      const breadcrumbContainer = document.getElementById(
        `breadcrumb-container-${signalId}`
      );
      const htmlBreadcrumb = getHtmlBreadcrumb({
        corporation,
        company,
        project,
        machine,
        signal,
      });
      const breadcrumb = breadcrumbRoot.container.children.push(
        am5.Label.new(breadcrumbRoot, {
          html: htmlBreadcrumb,
          width: am5.percent(100),
          fill: am5.color("#878583"),
        })
      );

      breadcrumb.events.on("boundschanged", () => {
        if (breadcrumbContainer) {
          breadcrumbContainer.style.height = breadcrumb.height() + "px";
        }
      });

      breadcrumbRef.current = breadcrumb;
    }

    // Cleans up all resources.
    return () => {
      breadcrumbRoot?.dispose();
      breadcrumbRef.current = null;
    };
  }, [corporation, company, project, machine]);

  useEffect(() => {
    if (!ref?.current) return;

    // === X Axis ===

    const xAxis = ref.current.chart.xAxes.push(
      am5xy.DateAxis.new(ref.current.root, {
        id: entityIds.xAxis,
        baseInterval: { timeUnit: "second", count: 1 },
        renderer: am5xy.AxisRendererX.new(ref.current.root, {}),
        maxDeviation: 0,
        marginTop: 16,
      })
    );

    xAxis.get("renderer").labels.template.set("fontSize", 10);

    // === Y Axis ===

    const yAxis = ref.current.chart.yAxes.push(
      am5xy.ValueAxis.new(ref.current.root, {
        id: entityIds.yAxis,
        renderer: am5xy.AxisRendererY.new(ref.current.root, {
          pan: "zoom",
          stroke: am5.color(chartSymbols.colors[0]),
          strokeOpacity: 1,
          opacity: 1,
        }),
        maxDeviation: 1,
        marginRight: 0,
      })
    );

    yAxis.events.on("wheel", (ev) => yAxisWheel(ev, yAxis));

    yAxis
      .get("renderer")
      .labels.template.set("fill", am5.color(chartSymbols.colors[0]));
    yAxis.get("renderer").labels.template.set("fontSize", 10);

    // === Reference Y Axis ===

    const refYAxis = ref.current.chart.yAxes.push(
      am5xy.ValueAxis.new(ref.current.root, {
        id: entityIds.refYAxis,
        visible: hasReferenceSignal,
        renderer: am5xy.AxisRendererY.new(ref.current.root, {
          pan: "zoom",
          stroke: am5.color("#C7E0F4"),
          strokeOpacity: 1,
          opacity: 1,
        }),
        maxDeviation: 1,
        marginRight: 0,
      })
    );

    refYAxis.events.on("wheel", (ev) => yAxisWheel(ev, refYAxis));

    refYAxis.get("renderer").labels.template.set("fill", am5.color("#C7E0F4"));
    refYAxis.get("renderer").labels.template.set("fontSize", 10);

    refYAxis.set("syncWithAxis", yAxis);

    // === Creating a container for the Y Axis (used as wrapper for label and icon, prevent pushing items directly to Y Axis) ===

    const yAxisContainer = am5.Container.new(ref.current.root, {
      ariaLabel: "yAxisLabelContainer",
      layout: ref.current.root.verticalLayout,
      height: am5.percent(100),
      centerX: am5.p100,
      marginRight: hasReferenceSignal ? 0 : 16,
    });

    // === Adding the container to the Y Axis ===

    yAxis.children.unshift(yAxisContainer);

    // === Adding the label to the container ===

    yAxisContainer.children.unshift(
      am5.Label.new(ref.current.root, {
        ariaLabel: "yAxisLabel",
        rotation: -90,
        y: am5.p50,
        centerX: am5.p50,
        width: am5.percent(100),
        text: `(${signal.unit})`,
        fill: am5.color(chartSymbols.colors[0]),
        fontSize: 10,
        marginRight: 0,
        marginBottom: 0,
      })
    );

    // === Adding the icon to the container ===

    yAxisContainer.children.unshift(
      am5.Picture.new(ref.current.root, {
        ariaLabel: "y-axis-icon",
        width: 16,
        height: 16,
        x: 5,
        src: `${chartSymbols.svgs[0]}`,
        // visible: hasReferenceSignal,
      })
    );

    // === Creating a container for the Reference Y Axis (used as wrapper for label and icon, prevent pushing items directly to Y Axis) ===

    const RefYAxisContainer = am5.Container.new(ref.current.root, {
      id: entityIds.refYAxisContainer,
      ariaLabel: "yAxisLabelContainer",
      layout: ref.current.root.verticalLayout,
      height: am5.percent(100),
      centerX: am5.p100,
      visible: hasReferenceSignal,
    });

    // === Adding the reference container to the Reference Y Axis ===

    refYAxis.children.unshift(RefYAxisContainer);

    // === Adding the label to the reference container ===

    RefYAxisContainer.children.unshift(
      am5.Label.new(ref.current.root, {
        ariaLabel: "yAxisLabel",
        ariaValueText: `${referenceSignal?.unit}`,
        rotation: -90,
        y: am5.p50,
        centerX: am5.p50,
        text: `(${t("Reference")}) (${referenceSignal?.unit})`,
        fill: am5.color("#C7E0F4"),
        fontSize: 10,
      })
    );

    // === Adding the icon to the reference container ===

    RefYAxisContainer.children.unshift(
      am5.Rectangle.new(ref.current.root, {
        ariaLabel: "y-axis-reference-icon",
        width: 12,
        height: 12,
        x: 5,
        y: 2,
        fill: am5.color("#C7E0F4"),
      })
    );

    // === Series ===

    const series = ref.current.chart.series.push(
      am5xy.LineSeries.new(ref.current.root, {
        id: entityIds.series,
        name: signalFormatter(signal),
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        stroke: am5.color("#2C529F"),
        fill: am5.color("#2C529F"),
      })
    );

    seriesRef.current = series;

    const refSeries = ref.current.chart.series.push(
      am5xy.LineSeries.new(ref.current.root, {
        id: entityIds.refSeries,
        visible: hasReferenceSignal,
        name: hasReferenceSignal
          ? `(${t("Reference")}) ${signalFormatter(
              referenceSignal as ResponseSimplifiedSignal
            )}`
          : "",
        xAxis,
        yAxis: refYAxis,
        valueYField: "refValue",
        valueXField: "refDate",
        stroke: am5.color("#C7E0F4"),
      })
    );

    refSeries.toBack();

    seriesRefSignalRef.current = refSeries;

    // === Scrollbar ===

    ref.current.chart.get("scrollbarX")?.on("start", handleStartEndChanged);

    ref.current.chart.get("scrollbarX")?.on("end", handleStartEndChanged);

    // Pre-zoom
    series.events.once("datavalidated", () => {
      if (connectedTimeRange) {
        getLabel(entityIds.outOfSync).set(
          "visible",
          isOutOfSync(chartData, connectedTimeRange.range) &&
            connectedTimeRange.signalId !== signalId
        );

        zoomToDates(xAxis, chartData, connectedTimeRange.range);
      }
    });

    // === watermarks ===

    watermarkLogos.forEach((logoData) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const logo = am5.Picture.new(ref.current.root, {
        src: DalogLogo,
        height: logoData.height,
        opacity: logoData.opacity,
        x: logoData.x,
        y: logoData.y,
        centerX: logoData.centerX,
        centerY: logoData.centerY,
      });

      ref.current?.chart.plotContainer.children.push(logo);
    });

    // === Out of sync box ===

    ref.current.chart.plotContainer.children.push(
      am5.Label.new(ref.current.root, {
        id: entityIds.outOfSync,
        html: `<div class="out-of-sync">${t("Chart out of sync. Check signal start-end date.")}</div>`,
        centerX: am5.p50,
        centerY: am5.p100,
        x: am5.p50,
        y: am5.percent(95),
        visible: false,
      })
    );

    // === No average trend data ===

    ref.current.chart.plotContainer.children.push(
      am5.Label.new(ref.current.root, {
        id: entityIds.noAverageTrend,
        html: `<div class="out-of-sync">${t("No averaged data available.")}</div>`,
        centerX: am5.p50,
        centerY: am5.p100,
        x: am5.p50,
        y: am5.percent(95),
        visible: false,
      })
    );

    createChartExport({
      root: ref.current.root,
      chart: ref.current.chart,
      signal,
      selector: exportSelector,
      corporation: corporation?.name,
      company: company?.name,
      project: project?.name,
      machine: machine?.name,
    });

    return () => {
      handleStartEndChanged.cancel();
      series.data.clear();
      series.dispose();
      refSeries.data.clear();
      refSeries.dispose();
      rangeRef.current = "";
      seriesRef.current = null;
      seriesRefSignalRef.current = null;
      ref.current = null;
    };
  }, []);

  // === Range addition logic ===

  const removeAllRanges = () => {
    const yAxis = ref.current?.chart.yAxes.getIndex(0);
    yAxis?.axisRanges.clear();
  };

  const removeYAxisRange = (label: string) => {
    const yAxis = ref.current?.chart.yAxes.getIndex(0);

    const ranges: DataItem<IValueAxisDataItem>[] = [];

    yAxis?.axisRanges.each((range) => {
      const text = range.get("label")?.get("text");

      if (text === label) {
        ranges.push(range);
      }
    });

    ranges.forEach((range) => {
      yAxis?.axisRanges.removeValue(range);
    });
  };

  const createRange = (
    value: any,
    endValue: any,
    label: any,
    color: any,
    dashed = false
  ) => {
    if (!ref.current) return;
    const yAxis = ref.current?.chart.yAxes.getIndex(0);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const rangeDataItem = yAxis?.makeDataItem({ value, affectsMinMax: true });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const range = yAxis?.createAxisRange(rangeDataItem);

    if (endValue) {
      range?.get("axisFill")?.setAll({
        fill: color,
        fillOpacity: 0.2,
        visible: true,
      });
    } else {
      range?.get("grid")?.setAll({
        stroke: color,
        strokeOpacity: 1,
        strokeWidth: 4,
        location: 1,
        tooltipText: capitalize(label.split(/(?=[A-Z])/)[0]),
        tooltipPosition: "pointer",
      });

      if (dashed) {
        range?.get("grid")?.set("strokeDasharray", [5, 3]);
      }
    }

    if (label) {
      range?.get("label")?.setAll({
        text: label,
        location: 1,
        fontSize: 12,
        inside: true,
        centerX: am5.p0,
        centerY: am5.p100,
        visible: false,
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedSignalsAlerts)) {
      const alertsLevels = Object.entries(getSignalAlertById(signalId)).filter(
        ([key, value]) =>
          !!value && (key.includes("Low") || key.includes("High"))
      );

      const alertsArray = alertsLevels.map(([alertKey, value]) => ({
        value,
        label: alertKey,
        color: Object.entries(conditionColor).filter(([key, _]) =>
          alertKey?.toString().includes(key.toLowerCase())
        )[0][1],
      }));

      removeAllRanges();

      alertsArray.forEach((alert) => {
        if (signalId && alertsOptions[signalId]?.includes(alert.label)) {
          createRange(
            alert.value,
            undefined,
            alert.label,
            am5.color(alert.color),
            false
          );
        } else {
          removeYAxisRange(alert.label);
        }
      });
    }
  }, [alertsOptions, signalId, selectedSignalsAlerts.length]);

  useEffect(() => {
    if (!ref.current || !seriesRef?.current) {
      return;
    }

    seriesRef.current.data.setAll(chartData);
    seriesRef.current.appear(500, 0);

    if (seriesRefSignalRef.current) {
      seriesRefSignalRef.current.data.setAll(chartData);
      seriesRefSignalRef.current.appear(500, 0);
    }

    createGroupedTooltip({
      ref: ref.current,
      type: "date",
      hasReferenceSignal,
    });
  }, [chartData]);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    getLabel(entityIds.outOfSync).set(
      "visible",
      isOutOfSync(chartData, connectedTimeRange?.range)
    );

    if (!connectedTimeRange || connectedTimeRange.signalId === signalId) {
      return;
    }

    zoomToDates(
      getDateAxis(entityIds.xAxis),
      chartData,
      connectedTimeRange.range
    );
  }, [connectedTimeRange]);

  useEffect(() => {
    if (!ref?.current) return;

    const legend = ref.current.chart.bottomAxesContainer.children.push(
      am5.Legend.new(ref.current.root, {
        centerX: am5.percent(0),
        x: 0,
        paddingTop: 15,
        paddingBottom: 5,
        layout: ref.current.root.gridLayout,
        id: entityIds.legend,
        visible: hasReferenceSignal,
        exportable: true,
      })
    );

    legend.events.on("datavalidated", () => {
      const markers = legend.markers.values;

      const createMarkerIcon = ({
        iconType,
        iconOptions,
      }: {
        iconType: any;
        iconOptions: any;
      }) => {
        const markerIcon = iconType.new(ref.current?.root, iconOptions);
        return [markerIcon];
      };

      const markerOneIcon = createMarkerIcon({
        iconType: am5.Picture,
        iconOptions: {
          width: 16,
          height: 16,
          y: 1,
          src: `${chartSymbols.svgs[0]}`,
        },
      });

      const markerTwoIcon = createMarkerIcon({
        iconType: am5.Rectangle,
        iconOptions: {
          ariaLabel: "y-axis-icon",
          width: 12,
          height: 12,
          x: 2,
          y: 3,
          fill: am5.color("#C7E0F4"),
        },
      });

      markers[0].children.setAll(markerOneIcon);

      if (hasReferenceSignal) {
        markers[1].children.setAll(markerTwoIcon);
      }
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    legend.markers.template.setStateOnChildren = true;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    legend.markers.template.setStateOnChildrenEvents = true;
    legend.markers.template.states.create("disabled", { opacity: 0.5 });
    legend.labels.template.setAll({
      fontSize: 12,
    });

    legend.markerRectangles.template.setAll({
      visible: false,
      width: 0,
      height: 0,
    });

    legend.data.setAll(ref.current.chart.series.values);
    legendRef.current = legend;

    return () => {
      legend.dispose();
      legendRef.current = null;
    };
  }, [hasReferenceSignal]);

  useEffect(() => {
    if (!ref?.current) return;

    // === Remove shape icon from yAxis if no reference signal is selected

    // getValueAxis(entityIds.yAxis).children?.each((item) => {
    //   if (item instanceof am5.Container && item.get('ariaLabel') === 'yAxisLabelContainer') {
    //     item.children.each((child) => {
    //       if (child instanceof am5.Picture) {
    //         child.set('visible', hasReferenceSignal);
    //       }
    //     });
    //   }
    // });

    // === Set Reference Y Axis label text

    getValueAxis(entityIds.refYAxisContainer).children?.each((item) => {
      if (item instanceof am5.Label && item.get("ariaLabel") === "yAxisLabel") {
        item.set("text", `(${t("Reference")}) (${referenceSignal?.unit})`);
      }
    });

    getValueAxis(entityIds.refYAxis).set("visible", hasReferenceSignal);
    getValueAxis(entityIds.refYAxisContainer).set(
      "visible",
      hasReferenceSignal
    );
    getSeries(entityIds.refSeries).set("visible", hasReferenceSignal);
    // getLegend(entityIds.legend).set('visible', hasReferenceSignal);
    getLegend(entityIds.legend).set("visible", true);
    getSeries(entityIds.refSeries).set(
      "name",
      hasReferenceSignal
        ? `(${t("Reference")}) ${signalFormatter(
            referenceSignal as ResponseSimplifiedSignal
          )}`
        : ""
    );
  }, [hasReferenceSignal]);

  // set chart disabled/enabled based on loading status
  useEffect(() => {
    if (ref.current?.chart) {
      setChartDisabled(ref.current.chart, loadingCharts);
    }
  }, [loadingCharts]);

  useEffect(() => {
    if (!ref?.current) return;

    getLabel(entityIds.noAverageTrend)?.set(
      "visible",
      isAverageTrend && !signal.p12h
    );
  }, [isAverageTrend, signal]);

  useEffect(() => {
    if (!ref?.current || !signal?.p12h) return;

    // === Update series name ===

    const seriesName = signalFormatter(isAverageTrend ? signal?.p12h : signal);

    getSeries(entityIds.series).set("name", seriesName);

    // === Update breadcrumb ===

    if (breadcrumbRef?.current) {
      const htmlBreadcrumb = getHtmlBreadcrumb({
        corporation,
        company,
        project,
        machine,
        signal: isAverageTrend && signal?.p12h ? signal?.p12h : signal,
      });

      breadcrumbRef.current.set("html", htmlBreadcrumb);
    }
  }, [isAverageTrend, signal, hasReferenceSignal]);

  const containerWrapperHeight = hasReferenceSignal
    ? "calc(100% - 93px - 55px - 53px)" // 93px = header + timestamp, 55px = breadcrumb, 53px = reference signal legend
    : "calc(100% - 93px - 55px)"; // 93px = header + timestamp, 55px = breadcrumb

  return (
    <div className="chart-container" style={{ height: containerWrapperHeight }}>
      <p className="range">{rangeRef.current}</p>
      <XYChart ref={ref} exportSelector={exportSelector} styles={chartStyles} />
      <div id={`breadcrumb-container-${signalId}`} />
    </div>
  );
};

export default TrendViewPlot;
