import {
  DefaultButton,
  DialogFooter,
  DialogType,
  IDialogProps,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";

import BaseDialog, { DialogSize } from "../common/Dialog";
import { useTranslation } from "react-i18next";

/**
 * The Confirmation dialog props.
 */
type ConfirmDialogProps = IDialogProps & {
  title: string;
  isLoading: boolean;
  render: JSX.Element;
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
  message?: string;
};

/**
 * The generic ConfirmDialog
 * @param title The confirm dialog title.
 * @param render The React component with the confirmation message.
 * @param isLoading Value determining whether the confirmation dialog is on "is Loading" state.
 * @param onConfirm Function called when the confirmation has been accepted.
 * @param onCancel Function called when the confirmation has been canceled.
 * @param onClose Function called when the confirmation dialog is closing.
 * @returns The ConfirmDialog component.
 */
export const ConfirmDialog = ({
  title,
  render,
  isLoading,
  onConfirm,
  onCancel,
  onClose,
  ...rest
}: ConfirmDialogProps) => {
  const { t } = useTranslation();
  const handleClose = () => {
    onClose?.();
  };

  const handleCancel = () => {
    onCancel?.();
    handleClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      hidden={false}
      dialogContentProps={{
        title: title,
        type: DialogType.close,
        onDismiss: handleClose,
      }}
      size={DialogSize.M}
    >
      {render}
      <DialogFooter>
        <PrimaryButton
          text={t("Yes")}
          onClick={onConfirm}
          disabled={isLoading}
          onRenderIcon={() =>
            isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
        />
        <DefaultButton
          text={t("No")}
          onClick={handleCancel}
          disabled={isLoading}
        />
      </DialogFooter>
    </BaseDialog>
  );
};
