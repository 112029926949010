import { createContext, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { Status } from "../../schema/status";
import { WirelessGateway, WirelessGatewayResponse } from "./models";
import {
  listAsyncGateways,
  selectWirelessGateways,
  selectWirelessGatewaysStatus,
} from "./reducer";
import {
  listAsyncProjs,
  selectProjects,
  selectProjectsStatus,
} from "../Projects/reducer";
import BaseCommandBar, {
  CommandBarItemProps,
  CommandBarItemType,
  computeCommandBarItems,
} from "../common/CommandBar";
import { notification } from "../common/Notification";
import Table, { Column, useTableFilters } from "../common/Table";

import {
  commandBarStyles,
  iconStyle,
  linkStyle,
  pageStyle,
  titleStyle,
} from "../../schema/Constants";
import {
  TooltipHost,
  Icon,
  Text,
  Stack,
  TextField,
  IconButton,
  IIconProps,
  IColumn,
  IObjectWithKey,
  IDetailsRowStyles,
  IDetailsRowProps,
  DetailsRow,
  IRenderFunction,
} from "@fluentui/react";
import { listAsyncWirelessDetails } from "./WirelessGatewaysDetails/reducer";
import CopyToClipboard from "react-copy-to-clipboard";
import { DeleteConfirm } from "./DeleteConfirm";
import { AddDialog } from "./AddDialog";
import { EditDialog } from "./EditDialog";
import { EditBulkDialog } from "./EditBulkDialog";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";
import { useTranslation } from "react-i18next";

export type ItemsSelected = WirelessGatewayResponse & IObjectWithKey;

const copyIcon: IIconProps = { iconName: "Copy" };

export const wirelessContext = createContext([
  { id: "", serialNumber: "", projectId: "" },
]);

export const selWirelessContext = createContext<
  WirelessGatewayResponse | undefined
>(undefined);

type GetColumnsOpts = {
  t: any;
  hasActions: boolean;
  onEdit: (gateway: WirelessGatewayResponse) => void;
  onDelete: (gateway: WirelessGatewayResponse) => void;
};

const OnPass = ({
  item,
  column,
}: {
  item: WirelessGatewayResponse;
  column: IColumn;
}) => {
  const { t } = useTranslation();
  const pass = item[column.key];
  return (
    <Stack horizontal key={item.id + column.key}>
      <TextField
        type="password"
        canRevealPassword
        defaultValue={pass ? pass : ""}
        disabled={true}
      />
      <CopyToClipboard
        text={pass}
        onCopy={(text) =>
          !text &&
          notification.warning(t("You are trying to copy an empty string."))
        }
      >
        <IconButton iconProps={copyIcon} ariaLabel={"icon"} />
      </CopyToClipboard>
    </Stack>
  );
};

const getColumns = ({
  t,
  hasActions,
  onEdit,
  onDelete,
}: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "serialNumber",
      name: t("Serial Number"),
      fieldName: "serialNumber",
      minWidth: 150,
      isSortable: true,
      onRender: ({ id, serialNumber }: WirelessGatewayResponse) => (
        <Link to={id} style={linkStyle}>
          {serialNumber}
        </Link>
      ),
    },
    {
      key: "project",
      name: t("Project"),
      fieldName: "project",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "company",
      name: t("Company"),
      fieldName: "company",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "corporation",
      name: t("Corporation"),
      fieldName: "corporation",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "adminPassword",
      name: t("Admin Password"),
      fieldName: "adminPassword",
      minWidth: 150,
      isSortable: true,
      onRender: (item, _, column) => <OnPass item={item} column={column} />,
    },
    {
      key: "userPassword",
      name: t("User Password"),
      fieldName: "userPassword",
      minWidth: 150,
      isSortable: true,
      onRender: (item, _, column) => <OnPass item={item} column={column} />,
    },
    {
      key: "connectionString",
      name: t("Connection String"),
      fieldName: "connectionString",
      minWidth: 150,
      isSortable: true,
      onRender: (item, _, column) => <OnPass item={item} column={column} />,
    },
    {
      key: "ltePlanSize",
      name: t("Lte Plan Size"),
      fieldName: "ltePlanSize",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "publicKey",
      name: t("Public Key"),
      fieldName: "publicKey",
      minWidth: 150,
      isSortable: true,
      onRender: (item, _, column) => <OnPass item={item} column={column} />,
    },
    {
      key: "rsshPort",
      name: t("Rssh Port"),
      fieldName: "rsshPort",
      minWidth: 100,
      isSortable: true,
      onRender: (item, _, column) => <OnPass item={item} column={column} />,
    },
    {
      key: "wifiPasswordForAP",
      name: t("Wifi Password For AP"),
      fieldName: "wifiPasswordForAP",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "wifiSSID",
      name: t("Wifi SSID"),
      fieldName: "wifiSSID",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "iMEI",
      name: t("IMEI"),
      fieldName: "iMEI",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "wirepassSinkNodeAddress",
      name: t("Wirepass Sink Node Address"),
      fieldName: "wirepassSinkNodeAddress",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "wirepassChannel",
      name: t("Wirepass Channel"),
      fieldName: "wirepassChannel",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "ethernetMacAddress",
      name: t("Ethernet Mac Address"),
      fieldName: "ethernetMacAddress",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "firmware",
      name: t("Firmware"),
      fieldName: "firmware",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "active",
      name: t("Active"),
      fieldName: "active",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "installationLocation",
      name: t("Installation Location"),
      fieldName: "installationLocation",
      minWidth: 150,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (gateway: WirelessGatewayResponse) => (
        <div style={{ display: "flex" }}>
          <TooltipHost
            key={0}
            content={t("Edit")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Edit"
              onClick={() => onEdit(gateway)}
              style={iconStyle}
            />
          </TooltipHost>
          <TooltipHost
            key={1}
            content={t("Delete")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => onDelete(gateway)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    });
  }

  return columns;
};

export const WirelessGatewaysList: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const auth = useContext(authContext);
  const hasWritePermission = auth.wirelessSensorContributor;
  const [selectedItems, setSelectedItems] = useState<ItemsSelected[]>();
  const items = useAppSelector(selectWirelessGateways);
  const status = useAppSelector(selectWirelessGatewaysStatus);

  const dispatch = useAppDispatch();

  const projs = useAppSelector(selectProjects);
  const projectStatus = useAppSelector(selectProjectsStatus);
  // const { bigScreen } = useViewport();

  useEffect(() => {
    if (projectStatus === Status.void) dispatch(listAsyncProjs());
  }, [dispatch, projectStatus]);

  useEffect(() => {
    if (status === Status.void && projectStatus === Status.idle)
      dispatch(listAsyncGateways(projs));
  }, [dispatch, projectStatus, status, projs]);

  const [selected, setSelected] = useState<{
    data: WirelessGatewayResponse;
    context: "add" | "edit" | "delete" | "editB";
    selItems?: ItemsSelected[];
  }>();

  const { filters, handleSearch } = useTableFilters<WirelessGatewayResponse>({
    keys: ["serialNumber", "firmware", "project", "company", "corporation"],
  });

  const onAdd = () => {
    setSelected({ data: null, context: "add" });
    //setSelectedWireless(undefined);
  };

  const onEdit = async (gateway: WirelessGatewayResponse) => {
    await listAsyncWirelessDetails(gateway.id!);
    setSelected({ data: gateway, context: "edit" });
    //setSelectedWireless(gateway);
  };

  const editBulkOnclick = (): boolean | void => {
    setSelected({ data: undefined, selItems: selectedItems, context: "editB" });
  };

  const onDelete = (gateway: WirelessGatewayResponse) =>
    setSelected({ data: gateway, context: "delete" });

  const commandBarItems: CommandBarItemProps[] = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{t("Gateways")}</Text>,
    },
    ...(hasWritePermission
      ? [
          {
            key: "add",
            text: t("Add"),
            type: CommandBarItemType.Button,
            iconProps: { iconName: "Add" },
            onClick: onAdd,
          },
          {
            key: "edit",
            text: t("Bulk Edit"),
            type: CommandBarItemType.Button,
            disabled: !(selectedItems?.length > 1),

            iconProps: {
              iconName: "ColumnVerticalSectionEdit",
            },
            onClick: editBulkOnclick,
          },
        ]
      : []),
  ];

  const OnRenderRow: IRenderFunction<IDetailsRowProps> = (
    props?: IDetailsRowProps
  ): JSX.Element => {
    if (!props) {
      return <DetailsRow item={undefined} itemIndex={-1} />;
    }
    const customStyles: Partial<IDetailsRowStyles> = { root: {} };
    (customStyles.root as any).userSelect = "any";
    return <DetailsRow {...props} styles={customStyles} />;
  };

  return (
    <div style={pageStyle}>
      <BaseCommandBar
        items={computeCommandBarItems(commandBarItems)}
        onSearch={handleSearch}
        styles={commandBarStyles}
      />

      <Table
        persistOpts={{
          key: "table-gateways",
          version: 2,
        }}
        header={{
          title: t("Gateways"),
        }}
        items={items}
        columns={getColumns({
          t,
          hasActions: hasWritePermission,
          onEdit,
          onDelete,
        })}
        filters={filters}
        onSelectionChanged={(items: ItemsSelected[]) => setSelectedItems(items)}
        //hasSelection={false}
        isLoading={status === Status.loading}
        isError={status === Status.error}
        //perPage={bigScreen ? 20 : 10}
        onRenderRow={OnRenderRow}
      />

      <DeleteConfirm
        data={selected?.data}
        show={selected?.context === "delete"}
        onSuccess={(hasError) => {
          if (hasError) {
            notification.error(
              t(`Failed deleting {{serialNumber}} gateway`, {
                serialNumber: selected?.data?.serialNumber,
              })
            );
          } else {
            dispatch(listAsyncGateways(projs));
            notification.success(
              t(`{{serialNumber}} gateway deleted successfully`, {
                serialNumber: selected?.data?.serialNumber,
              })
            );
          }

          setSelected(null);
        }}
        onClose={() => setSelected(null)}
      />
      {["add"].includes(selected?.context) && (
        <AddDialog
          serialNumbers={items.map((gate) => gate.serialNumber)}
          show={["add"].includes(selected?.context)}
          onSuccess={(hasError, name: string) => {
            if (hasError) {
              const message = t(`Failed add {{name}}`, { name });

              notification.error(message);
            } else {
              dispatch(listAsyncGateways(projs));

              const message = t(`{{name}} added successfully`, { name });

              notification.success(message);
            }
          }}
          onClose={() => {
            setSelected(null);
          }}
        />
      )}

      {selected?.data && (
        <EditDialog
          data={selected?.data as WirelessGateway}
          serialNumbers={items.map((gate) => gate.serialNumber)}
          show={["edit"].includes(selected?.context)}
          onSuccess={(hasError, name: string) => {
            if (hasError) {
              const message = t(`Failed edit {{name}}`, { name });

              notification.error(message);
            } else {
              dispatch(listAsyncGateways(projs));

              const message = t(`{{name}} edited successfully`, { name });

              notification.success(message);
            }
          }}
          onClose={() => {
            setSelected(null);
          }}
        />
      )}
      {["editB"].includes(selected?.context) && (
        <EditBulkDialog
          gatewaysNotCommon={selected?.selItems}
          options={projs.map((pro) => ({
            key: pro.id!,
            text: `${pro.name} (${pro.company.name})`,
          }))}
          data={selected?.selItems.at(0)}
          show={["editB"].includes(selected?.context)}
          onSuccess={(hasError) => {
            if (hasError) {
              const message = t(`Failed updating`);
              notification.error(message);
            } else {
              dispatch(listAsyncGateways(projs));

              const message = t(`Updated  successfully`);

              notification.success(message);
            }
          }}
          onClose={() => {
            setSelected(null);
          }}
        />
      )}
    </div>
  );
};
