import { t } from "i18next";

export const operationView = [
  { key: "Yearly", text: t("Yearly") },
  { key: "Monthly", text: t("Monthly") },
];

export const DATE_FORMAT_DAYJS = "YYYY-MM-DDTHH:mm:ss.SSS";

export const monthNames = [
  t("January"),
  t("February"),
  t("March"),
  t("April"),
  t("May"),
  t("June"),
  t("July"),
  t("August"),
  t("September"),
  t("October"),
  t("November"),
  t("December"),
];

export const shortMonthNames = [
  t("Jan"),
  t("Feb"),
  t("Mar"),
  t("Apr"),
  t("May"),
  t("Jun"),
  t("Jul"),
  t("Aug"),
  t("Sep"),
  t("Oct"),
  t("Nov"),
  t("Dec"),
];
