import en from "./en.json";
import es from "./es.json";
import de from "./de.json";
import fr from "./fr.json";
import pt from "./pt.json";

export const languages = {
  en,
  de,
  es,
  fr,
  pt,
};
